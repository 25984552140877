import React from 'react';
import styles from '../index.module.css';

const Sidebar = () => {
  return (
    <div className={styles.sidebar}>
      <h1>Limpa 0.0.0</h1>
    </div>
  );
};

export default Sidebar;
