
export default [
  12,  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  10,
  10,
  15,
  15,
  15,
  10,
  15,
  12,
  12,
  12,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  7,
  3,
  7,
  7,
  7,
  7,
  3,
  7,
  3,
  7,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  7,
  3,
  7,
  7,
  3,
  7,
  3,
  7,
  3,
  15,
  15,
  15,
  15,
  5,
  12,
  12,
  12,
  11,
  11,
  11,
  6,
  6,
  8,
  12,
  5,
  5,
  3,
  5,
  3,
  5,
  8,
  8,
  8,
  12,
  12,
  12,
  12,
  5,
  5,
  5,
  3,
  5,
  3,
  3,
  3,
  3,
  11,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  9,
  8,
  8,
  12,
  5,
  3,
  13,
  1,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  1,
  1,
  1,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  12,
  12,
  12,
  12,
  12,
  12,
  11,
  11,
  11,
  12,
  12,
  12,
  10,
  12,
  10,
  10,
  15,
  10,
  15,
  12,
  12,
  12,
  12,
  12,
  3,
  3,
  3,
  3,
  3,
  3,
  7,
  3,
  7,
  7,
  7,
  7,
  7,
  7,
  3,
  7,
  3,
  7,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  7,
  3,
  7,
  7,
  7,
  3,
  7,
  3,
  7,
  15,
  12,
  15,
  15,
  15,
  5,
  12,
  12,
  11,
  11,
  6,
  11,
  6,
  11,
  12,
  12,
  5,
  5,
  3,
  3,
  5,
  12,
  8,
  12,
  8,
  12,
  12,
  5,
  12,
  5,
  3,
  5,
  3,
  3,
  3,
  3,
  5,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  8,
  8,
  12,
  12,
  3,
  13,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  1,
  1,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  1,
  1,
  13,
  12,
  12,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  10,
  12,
  10,
  10,
  10,
  12,
  12,
  12,
  12,
  12,
  14,
  12,
  3,
  3,
  15,
  3,
  7,
  3,
  7,
  7,
  7,
  7,
  3,
  7,
  3,
  7,
  3,
  7,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  7,
  3,
  7,
  1,
  7,
  3,
  7,
  3,
  7,
  3,
  15,
  15,
  12,
  15,
  15,
  15,
  5,
  12,
  8,
  11,
  11,
  6,
  6,
  11,
  8,
  5,
  5,
  3,
  5,
  3,
  3,
  12,
  12,
  8,
  12,
  12,
  12,
  12,
  5,
  5,
  5,
  3,
  3,
  3,
  3,
  5,
  12,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  9,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  6,
  6,
  11,
  8,
  12,
  5,
  5,
  13,
  1,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  1,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  12,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  10,
  10,
  10,
  10,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  3,
  15,
  15,
  7,
  3,
  7,
  7,
  7,
  7,
  3,
  7,
  3,
  7,
  3,
  3,
  3,
  1,
  7,
  1,
  1,
  1,
  1,
  1,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  7,
  3,
  7,
  7,
  7,
  7,
  3,
  15,
  3,
  7,
  3,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  12,
  11,
  11,
  11,
  6,
  11,
  11,
  12,
  5,
  5,
  3,
  3,
  3,
  12,
  8,
  12,
  12,
  12,
  12,
  5,
  12,
  5,
  3,
  5,
  3,
  3,
  3,
  3,
  11,
  6,
  6,
  0,
  0,
  0,
  6,
  9,
  2,
  2,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  6,
  9,
  11,
  12,
  12,
  5,
  3,
  13,
  1,
  1,
  1,
  1,
  13,
  1,
  13,
  1,
  1,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  13,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  12,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  12,
  10,
  10,
  12,
  12,
  12,
  12,
  12,
  12,
  14,
  8,
  15,
  15,
  3,
  3,
  7,
  3,
  7,
  7,
  3,
  7,
  3,
  7,
  3,
  3,
  3,
  7,
  7,
  1,
  1,
  1,
  1,
  1,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  7,
  1,
  7,
  7,
  7,
  3,
  7,
  7,
  7,
  15,
  12,
  15,
  15,
  15,
  15,
  5,
  12,
  11,
  11,
  6,
  6,
  6,
  11,
  12,
  12,
  3,
  5,
  3,
  3,
  5,
  12,
  8,
  12,
  12,
  12,
  12,
  5,
  3,
  5,
  3,
  3,
  3,
  3,
  5,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  2,
  2,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  0,
  0,
  0,
  6,
  6,
  8,
  12,
  5,
  12,
  3,
  13,
  13,
  1,
  1,
  1,
  1,
  1,
  13,
  1,
  1,
  13,
  1,
  5,
  5,
  13,
  1,
  13,
  1,
  13,
  13,
  1,
  13,
  13,
  13,
  13,
  1,
  13,
  1,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  10,
  12,
  10,
  12,
  12,
  12,
  12,
  12,
  14,
  12,
  8,
  15,
  3,
  3,
  7,
  3,
  7,
  7,
  3,
  7,
  3,
  3,
  3,
  3,
  3,
  7,
  7,
  1,
  1,
  1,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  7,
  3,
  7,
  1,
  7,
  1,
  7,
  3,
  15,
  3,
  7,
  3,
  15,
  15,
  12,
  15,
  15,
  15,
  15,
  12,
  8,
  11,
  11,
  6,
  11,
  11,
  12,
  5,
  5,
  3,
  5,
  3,
  12,
  8,
  12,
  12,
  12,
  12,
  5,
  5,
  5,
  3,
  3,
  3,
  3,
  5,
  5,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  2,
  2,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  12,
  5,
  5,
  13,
  1,
  13,
  1,
  1,
  13,
  1,
  1,
  1,
  13,
  1,
  5,
  12,
  12,
  5,
  1,
  13,
  13,
  13,
  13,
  1,
  13,
  13,
  13,
  13,
  13,
  1,
  13,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  10,
  12,
  10,
  10,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  8,
  3,
  3,
  7,
  3,
  7,
  7,
  15,
  15,
  3,
  3,
  3,
  3,
  3,
  7,
  7,
  1,
  1,
  1,
  7,
  1,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  7,
  7,
  1,
  7,
  7,
  7,
  3,
  7,
  7,
  7,
  3,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  12,
  11,
  6,
  6,
  6,
  11,
  12,
  12,
  5,
  5,
  3,
  3,
  5,
  12,
  8,
  12,
  12,
  5,
  12,
  5,
  3,
  3,
  3,
  3,
  3,
  3,
  12,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  2,
  2,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  6,
  9,
  8,
  12,
  12,
  5,
  3,
  13,
  1,
  1,
  1,
  1,
  13,
  1,
  13,
  1,
  13,
  12,
  12,
  12,
  12,
  13,
  1,
  1,
  13,
  13,
  13,
  13,
  13,
  1,
  13,
  13,
  13,
  1,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  10,
  12,
  10,
  10,
  15,
  12,
  12,
  12,
  12,
  12,
  14,
  3,
  11,
  3,
  7,
  3,
  7,
  7,
  13,
  1,
  13,
  15,
  3,
  3,
  3,
  7,
  7,
  1,
  7,
  1,
  7,
  7,
  7,
  7,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  7,
  1,
  7,
  1,
  7,
  3,
  15,
  3,
  7,
  7,
  7,
  15,
  12,
  15,
  12,
  15,
  15,
  5,
  12,
  11,
  11,
  6,
  11,
  6,
  8,
  12,
  5,
  3,
  5,
  3,
  3,
  8,
  12,
  12,
  12,
  12,
  5,
  5,
  5,
  3,
  13,
  3,
  3,
  5,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  9,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  0,
  0,
  0,
  6,
  9,
  8,
  12,
  5,
  12,
  13,
  1,
  13,
  1,
  1,
  1,
  1,
  13,
  1,
  13,
  13,
  12,
  12,
  12,
  12,
  5,
  1,
  13,
  13,
  1,
  13,
  3,
  13,
  13,
  1,
  13,
  13,
  13,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  10,
  10,
  15,
  15,
  12,
  12,
  12,
  12,
  12,
  15,
  12,
  11,
  7,
  3,
  7,
  7,
  13,
  1,
  1,
  1,
  13,
  12,
  3,
  7,
  7,
  1,
  7,
  1,
  7,
  7,
  15,
  15,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  7,
  1,
  1,
  1,
  7,
  7,
  7,
  3,
  3,
  3,
  7,
  3,
  3,
  15,
  12,
  12,
  15,
  15,
  15,
  12,
  8,
  11,
  11,
  6,
  11,
  11,
  12,
  12,
  5,
  3,
  3,
  3,
  12,
  8,
  12,
  12,
  5,
  12,
  5,
  3,
  3,
  3,
  3,
  5,
  5,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  6,
  6,
  11,
  8,
  12,
  5,
  3,
  13,
  1,
  1,
  1,
  1,
  13,
  1,
  13,
  13,
  13,
  12,
  12,
  12,
  12,
  12,
  13,
  1,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  1,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  10,
  10,
  15,
  15,
  3,
  12,
  12,
  12,
  12,
  14,
  3,
  11,
  11,
  3,
  7,
  3,
  7,
  1,
  1,
  1,
  1,
  1,
  15,
  7,
  1,
  7,
  7,
  7,
  7,
  15,
  15,
  15,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  7,
  3,
  1,
  7,
  1,
  7,
  1,
  7,
  7,
  3,
  3,
  7,
  7,
  7,
  3,
  15,
  15,
  12,
  12,
  15,
  7,
  5,
  12,
  11,
  6,
  11,
  6,
  11,
  12,
  5,
  5,
  5,
  3,
  3,
  5,
  12,
  8,
  12,
  12,
  5,
  3,
  3,
  13,
  3,
  3,
  3,
  12,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  6,
  9,
  11,
  8,
  12,
  12,
  13,
  13,
  13,
  1,
  1,
  13,
  1,
  13,
  13,
  13,
  3,
  12,
  12,
  12,
  12,
  12,
  5,
  1,
  13,
  13,
  13,
  3,
  13,
  13,
  13,
  13,
  13,
  13,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  10,
  10,
  3,
  3,
  12,
  12,
  12,
  12,
  15,
  12,
  11,
  3,
  7,
  3,
  7,
  13,
  1,
  1,
  1,
  1,
  1,
  12,
  7,
  1,
  7,
  7,
  7,
  15,
  15,
  10,
  15,
  7,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  7,
  3,
  7,
  7,
  1,
  1,
  1,
  7,
  1,
  7,
  3,
  3,
  3,
  1,
  7,
  3,
  15,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  8,
  11,
  6,
  11,
  11,
  12,
  12,
  5,
  3,
  3,
  3,
  12,
  8,
  12,
  12,
  5,
  5,
  5,
  3,
  3,
  3,
  3,
  5,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  9,
  6,
  9,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  9,
  0,
  0,
  6,
  6,
  9,
  11,
  12,
  5,
  3,
  13,
  1,
  1,
  1,
  1,
  13,
  13,
  13,
  3,
  13,
  12,
  12,
  12,
  12,
  12,
  12,
  1,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  12,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  10,
  10,
  15,
  3,
  15,
  15,
  12,
  12,
  14,
  12,
  11,
  8,
  7,
  3,
  7,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  15,
  7,
  7,
  15,
  7,
  15,
  15,
  10,
  15,
  7,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  7,
  3,
  3,
  7,
  1,
  1,
  1,
  7,
  1,
  7,
  7,
  3,
  3,
  3,
  7,
  7,
  7,
  15,
  15,
  12,
  12,
  12,
  7,
  15,
  12,
  12,
  11,
  11,
  6,
  11,
  8,
  12,
  12,
  5,
  3,
  3,
  5,
  11,
  8,
  12,
  12,
  5,
  3,
  3,
  13,
  3,
  3,
  3,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  6,
  9,
  9,
  11,
  8,
  12,
  3,
  13,
  13,
  1,
  1,
  13,
  1,
  13,
  3,
  13,
  3,
  12,
  12,
  12,
  12,
  12,
  12,
  1,
  13,
  3,
  13,
  13,
  13,
  3,
  13,
  13,
  1,
  13,
  12,
  12,
  12,
  12,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  12,
  10,
  10,
  15,
  15,
  15,
  12,
  12,
  12,
  8,
  8,
  3,
  3,
  7,
  7,
  3,
  1,
  1,
  1,
  1,
  1,
  1,
  12,
  3,
  15,
  7,
  15,
  10,
  10,
  15,
  7,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  7,
  3,
  3,
  7,
  1,
  1,
  1,
  1,
  1,
  7,
  1,
  7,
  3,
  3,
  3,
  1,
  7,
  3,
  3,
  12,
  15,
  12,
  12,
  15,
  7,
  5,
  12,
  11,
  11,
  11,
  11,
  12,
  14,
  5,
  3,
  5,
  3,
  5,
  8,
  12,
  12,
  5,
  3,
  3,
  13,
  3,
  3,
  3,
  5,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  0,
  0,
  0,
  0,
  6,
  0,
  9,
  6,
  0,
  6,
  6,
  11,
  9,
  11,
  12,
  3,
  5,
  13,
  13,
  1,
  1,
  13,
  13,
  13,
  3,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  1,
  13,
  13,
  13,
  13,
  13,
  13,
  3,
  13,
  13,
  13,
  12,
  12,
  12,
  12,
  12,
  11,
  11,
  12,
  12,
  12,
  12,
  12,
  10,
  12,
  15,
  10,
  15,
  10,
  15,
  12,
  12,
  12,
  3,
  3,
  7,
  3,
  3,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  15,
  12,
  3,
  15,
  10,
  10,
  15,
  15,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  7,
  3,
  3,
  7,
  7,
  7,
  1,
  1,
  1,
  7,
  1,
  7,
  7,
  3,
  15,
  15,
  7,
  7,
  7,
  3,
  15,
  12,
  15,
  12,
  7,
  15,
  15,
  12,
  8,
  11,
  6,
  11,
  8,
  12,
  12,
  5,
  3,
  3,
  5,
  11,
  8,
  12,
  12,
  5,
  3,
  13,
  5,
  3,
  3,
  12,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  9,
  6,
  6,
  9,
  9,
  11,
  11,
  8,
  5,
  5,
  3,
  1,
  1,
  13,
  13,
  13,
  3,
  13,
  12,
  12,
  12,
  12,
  12,
  12,
  5,
  13,
  3,
  13,
  3,
  13,
  3,
  13,
  13,
  13,
  13,
  13,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  10,
  10,
  15,
  10,
  15,
  10,
  15,
  15,
  15,
  3,
  3,
  3,
  15,
  3,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  12,
  12,
  10,
  10,
  10,
  15,
  15,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  3,
  3,
  3,
  7,
  3,
  7,
  1,
  7,
  1,
  7,
  1,
  7,
  1,
  7,
  15,
  15,
  14,
  1,
  7,
  7,
  7,
  15,
  15,
  12,
  12,
  15,
  7,
  15,
  5,
  12,
  8,
  11,
  11,
  12,
  14,
  12,
  5,
  5,
  3,
  5,
  8,
  12,
  12,
  5,
  3,
  3,
  13,
  5,
  3,
  3,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  9,
  6,
  6,
  0,
  6,
  0,
  6,
  0,
  0,
  9,
  2,
  11,
  9,
  9,
  11,
  9,
  9,
  12,
  3,
  12,
  3,
  1,
  13,
  13,
  13,
  3,
  13,
  5,
  12,
  12,
  12,
  12,
  12,
  5,
  13,
  3,
  13,
  3,
  13,
  3,
  13,
  3,
  13,
  13,
  13,
  1,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  10,
  10,
  10,
  10,
  15,
  10,
  15,
  3,
  3,
  3,
  15,
  3,
  15,
  13,
  1,
  1,
  1,
  1,
  1,
  15,
  12,
  10,
  10,
  10,
  15,
  15,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  3,
  3,
  3,
  7,
  3,
  7,
  1,
  7,
  1,
  7,
  1,
  1,
  1,
  7,
  7,
  3,
  12,
  15,
  7,
  1,
  7,
  3,
  15,
  12,
  15,
  12,
  15,
  15,
  7,
  15,
  12,
  12,
  8,
  11,
  8,
  12,
  14,
  12,
  3,
  3,
  5,
  11,
  8,
  12,
  5,
  5,
  13,
  3,
  5,
  3,
  3,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  9,
  9,
  11,
  11,
  9,
  11,
  9,
  8,
  5,
  5,
  5,
  13,
  1,
  13,
  3,
  13,
  3,
  12,
  12,
  12,
  12,
  5,
  3,
  3,
  3,
  3,
  3,
  13,
  3,
  13,
  3,
  13,
  3,
  13,
  13,
  13,
  12,
  12,
  12,
  4,
  4,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  10,
  15,
  10,
  12,
  10,
  10,
  15,
  3,
  3,
  3,
  15,
  3,
  15,
  3,
  1,
  1,
  1,
  1,
  1,
  1,
  12,
  12,
  11,
  8,
  8,
  10,
  3,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  3,
  15,
  7,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  7,
  1,
  1,
  1,
  7,
  15,
  15,
  14,
  1,
  7,
  7,
  7,
  15,
  15,
  12,
  12,
  12,
  7,
  15,
  15,
  5,
  12,
  12,
  8,
  12,
  12,
  12,
  5,
  5,
  3,
  12,
  11,
  12,
  12,
  5,
  3,
  13,
  5,
  3,
  3,
  15,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  9,
  2,
  11,
  9,
  11,
  11,
  9,
  9,
  11,
  3,
  12,
  5,
  13,
  13,
  13,
  13,
  3,
  5,
  12,
  12,
  12,
  5,
  3,
  3,
  3,
  3,
  13,
  3,
  3,
  13,
  3,
  13,
  3,
  13,
  13,
  13,
  1,
  12,
  12,
  4,
  4,
  4,
  4,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  10,
  12,
  10,
  12,
  15,
  10,
  15,
  3,
  15,
  3,
  15,
  3,
  3,
  13,
  1,
  1,
  1,
  1,
  13,
  12,
  12,
  10,
  10,
  10,
  8,
  12,
  3,
  1,
  1,
  7,
  7,
  3,
  3,
  12,
  3,
  3,
  7,
  7,
  7,
  1,
  1,
  7,
  7,
  7,
  1,
  1,
  7,
  7,
  3,
  12,
  14,
  7,
  1,
  7,
  7,
  3,
  15,
  15,
  12,
  15,
  15,
  7,
  15,
  15,
  12,
  12,
  12,
  8,
  12,
  14,
  12,
  5,
  3,
  5,
  11,
  8,
  12,
  5,
  3,
  13,
  5,
  3,
  13,
  12,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  9,
  6,
  0,
  0,
  0,
  6,
  0,
  6,
  9,
  9,
  9,
  11,
  11,
  11,
  9,
  9,
  8,
  5,
  4,
  12,
  5,
  1,
  13,
  3,
  13,
  12,
  12,
  12,
  12,
  3,
  5,
  3,
  3,
  3,
  3,
  13,
  3,
  3,
  3,
  3,
  13,
  3,
  13,
  13,
  13,
  12,
  12,
  4,
  4,
  4,
  4,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  10,
  15,
  10,
  10,
  15,
  3,
  15,
  3,
  15,
  3,
  3,
  7,
  1,
  1,
  1,
  1,
  1,
  15,
  12,
  10,
  10,
  15,
  3,
  3,
  3,
  12,
  3,
  7,
  7,
  3,
  3,
  12,
  15,
  3,
  7,
  7,
  7,
  1,
  1,
  7,
  7,
  3,
  7,
  1,
  7,
  1,
  7,
  15,
  15,
  6,
  1,
  7,
  1,
  7,
  7,
  15,
  12,
  12,
  12,
  7,
  15,
  7,
  15,
  5,
  12,
  8,
  12,
  12,
  12,
  14,
  12,
  3,
  12,
  11,
  12,
  12,
  5,
  13,
  13,
  5,
  13,
  3,
  11,
  0,
  0,
  0,
  0,
  0,
  11,
  3,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  9,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  9,
  2,
  9,
  9,
  11,
  14,
  11,
  9,
  11,
  5,
  12,
  12,
  5,
  13,
  13,
  13,
  5,
  12,
  12,
  12,
  5,
  5,
  3,
  5,
  3,
  3,
  3,
  3,
  3,
  3,
  13,
  3,
  3,
  13,
  3,
  13,
  13,
  12,
  12,
  4,
  4,
  4,
  4,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  10,
  12,
  10,
  12,
  15,
  10,
  15,
  3,
  15,
  7,
  3,
  7,
  7,
  13,
  1,
  1,
  1,
  13,
  12,
  12,
  15,
  15,
  3,
  7,
  1,
  1,
  3,
  12,
  3,
  3,
  3,
  12,
  15,
  3,
  7,
  7,
  7,
  7,
  1,
  7,
  7,
  3,
  7,
  1,
  1,
  1,
  7,
  7,
  3,
  12,
  14,
  7,
  1,
  7,
  7,
  3,
  15,
  15,
  12,
  15,
  7,
  7,
  15,
  15,
  15,
  5,
  12,
  8,
  12,
  14,
  12,
  5,
  3,
  12,
  11,
  8,
  12,
  5,
  13,
  13,
  5,
  13,
  13,
  12,
  0,
  0,
  0,
  0,
  0,
  14,
  1,
  14,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  9,
  0,
  6,
  0,
  6,
  9,
  2,
  9,
  11,
  11,
  11,
  9,
  9,
  11,
  8,
  11,
  12,
  12,
  13,
  13,
  3,
  12,
  12,
  12,
  12,
  5,
  5,
  5,
  3,
  5,
  3,
  3,
  13,
  3,
  3,
  3,
  3,
  3,
  3,
  13,
  13,
  3,
  12,
  12,
  4,
  4,
  4,
  4,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  10,
  15,
  10,
  10,
  15,
  15,
  15,
  7,
  15,
  7,
  7,
  3,
  15,
  13,
  1,
  13,
  15,
  12,
  10,
  15,
  7,
  7,
  1,
  1,
  1,
  7,
  6,
  12,
  3,
  12,
  6,
  11,
  15,
  7,
  7,
  7,
  1,
  7,
  7,
  7,
  3,
  1,
  1,
  1,
  7,
  1,
  7,
  15,
  12,
  6,
  1,
  7,
  1,
  7,
  7,
  15,
  12,
  12,
  12,
  1,
  15,
  7,
  15,
  15,
  15,
  12,
  12,
  8,
  12,
  14,
  12,
  3,
  12,
  11,
  12,
  12,
  5,
  13,
  3,
  5,
  13,
  3,
  11,
  0,
  0,
  0,
  0,
  0,
  14,
  1,
  14,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  9,
  6,
  0,
  6,
  0,
  9,
  0,
  0,
  9,
  2,
  9,
  9,
  11,
  14,
  11,
  9,
  9,
  5,
  12,
  14,
  5,
  13,
  13,
  13,
  12,
  12,
  12,
  5,
  5,
  3,
  5,
  5,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  5,
  3,
  13,
  3,
  13,
  13,
  12,
  12,
  12,
  4,
  4,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  10,
  10,
  15,
  10,
  15,
  7,
  15,
  7,
  7,
  3,
  15,
  15,
  15,
  15,
  15,
  12,
  12,
  15,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  3,
  6,
  12,
  6,
  6,
  3,
  12,
  15,
  1,
  7,
  7,
  7,
  7,
  3,
  7,
  1,
  1,
  7,
  1,
  7,
  7,
  3,
  15,
  14,
  1,
  1,
  7,
  1,
  7,
  15,
  15,
  12,
  11,
  1,
  7,
  15,
  15,
  15,
  15,
  5,
  8,
  12,
  14,
  14,
  12,
  5,
  8,
  11,
  8,
  12,
  5,
  13,
  5,
  5,
  13,
  13,
  12,
  0,
  0,
  0,
  0,
  0,
  11,
  3,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  14,
  0,
  0,
  6,
  6,
  6,
  6,
  0,
  9,
  0,
  6,
  9,
  2,
  9,
  11,
  11,
  14,
  9,
  9,
  11,
  8,
  11,
  12,
  12,
  13,
  13,
  3,
  12,
  12,
  12,
  5,
  5,
  5,
  5,
  3,
  5,
  3,
  3,
  3,
  3,
  5,
  3,
  3,
  3,
  3,
  13,
  3,
  13,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  12,
  11,
  12,
  12,
  15,
  10,
  15,
  7,
  15,
  7,
  7,
  7,
  15,
  15,
  15,
  15,
  10,
  12,
  12,
  15,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  7,
  3,
  6,
  6,
  12,
  11,
  3,
  3,
  12,
  15,
  1,
  7,
  7,
  3,
  3,
  7,
  1,
  1,
  1,
  7,
  7,
  7,
  3,
  12,
  6,
  1,
  1,
  1,
  1,
  7,
  15,
  12,
  11,
  9,
  7,
  7,
  7,
  15,
  15,
  3,
  15,
  12,
  8,
  12,
  14,
  14,
  5,
  12,
  11,
  12,
  12,
  5,
  13,
  12,
  5,
  13,
  3,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  14,
  0,
  0,
  6,
  6,
  0,
  9,
  0,
  6,
  0,
  0,
  9,
  2,
  9,
  9,
  11,
  11,
  9,
  9,
  9,
  5,
  12,
  14,
  12,
  5,
  13,
  13,
  12,
  12,
  5,
  5,
  3,
  5,
  5,
  5,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  5,
  3,
  3,
  3,
  13,
  13,
  12,
  15,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  11,
  11,
  10,
  10,
  15,
  7,
  15,
  7,
  7,
  7,
  3,
  15,
  15,
  15,
  10,
  15,
  15,
  15,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  7,
  3,
  12,
  6,
  3,
  3,
  12,
  15,
  7,
  3,
  12,
  15,
  7,
  3,
  15,
  3,
  3,
  7,
  7,
  7,
  7,
  7,
  3,
  15,
  12,
  14,
  1,
  1,
  1,
  1,
  7,
  15,
  15,
  12,
  11,
  15,
  7,
  15,
  15,
  15,
  15,
  3,
  12,
  12,
  12,
  14,
  14,
  5,
  8,
  11,
  8,
  12,
  5,
  13,
  8,
  5,
  13,
  3,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  6,
  6,
  6,
  6,
  0,
  9,
  0,
  6,
  9,
  2,
  9,
  11,
  11,
  14,
  11,
  9,
  11,
  13,
  11,
  14,
  14,
  12,
  13,
  3,
  12,
  12,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  3,
  5,
  3,
  5,
  3,
  5,
  3,
  5,
  3,
  3,
  3,
  13,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  12,
  11,
  10,
  15,
  10,
  15,
  7,
  7,
  7,
  7,
  15,
  15,
  15,
  10,
  15,
  15,
  15,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  7,
  3,
  12,
  6,
  3,
  7,
  3,
  3,
  12,
  1,
  7,
  3,
  12,
  15,
  3,
  15,
  15,
  3,
  3,
  3,
  3,
  3,
  3,
  15,
  12,
  12,
  6,
  1,
  1,
  1,
  7,
  7,
  15,
  12,
  11,
  9,
  15,
  15,
  7,
  15,
  15,
  3,
  15,
  12,
  8,
  12,
  10,
  14,
  5,
  14,
  11,
  12,
  12,
  5,
  13,
  11,
  12,
  13,
  5,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  9,
  0,
  6,
  0,
  0,
  9,
  9,
  11,
  9,
  11,
  14,
  9,
  9,
  9,
  5,
  11,
  14,
  12,
  14,
  13,
  13,
  12,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  3,
  3,
  3,
  5,
  3,
  5,
  3,
  5,
  3,
  5,
  3,
  13,
  3,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  12,
  15,
  15,
  15,
  15,
  15,
  5,
  11,
  11,
  15,
  15,
  7,
  15,
  7,
  7,
  3,
  15,
  15,
  10,
  15,
  15,
  15,
  3,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  3,
  12,
  6,
  12,
  7,
  3,
  7,
  3,
  15,
  3,
  1,
  7,
  3,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  12,
  4,
  6,
  1,
  1,
  1,
  1,
  7,
  15,
  15,
  12,
  11,
  12,
  7,
  15,
  15,
  3,
  15,
  3,
  15,
  12,
  12,
  14,
  14,
  12,
  8,
  11,
  8,
  12,
  5,
  3,
  6,
  12,
  3,
  5,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  9,
  6,
  0,
  0,
  0,
  6,
  9,
  2,
  9,
  11,
  11,
  13,
  9,
  2,
  9,
  13,
  11,
  12,
  14,
  12,
  13,
  13,
  5,
  5,
  5,
  5,
  5,
  5,
  3,
  5,
  5,
  3,
  5,
  3,
  5,
  3,
  5,
  3,
  5,
  3,
  5,
  3,
  13,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  5,
  6,
  11,
  15,
  7,
  15,
  7,
  7,
  7,
  15,
  15,
  10,
  15,
  10,
  15,
  15,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  3,
  12,
  6,
  6,
  3,
  3,
  7,
  3,
  7,
  3,
  15,
  7,
  3,
  3,
  11,
  4,
  4,
  4,
  4,
  4,
  4,
  4,
  12,
  15,
  12,
  12,
  4,
  0,
  1,
  1,
  1,
  7,
  7,
  15,
  12,
  11,
  9,
  12,
  15,
  7,
  15,
  15,
  3,
  15,
  12,
  8,
  12,
  10,
  14,
  12,
  14,
  11,
  12,
  8,
  5,
  13,
  6,
  11,
  12,
  5,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  9,
  0,
  6,
  0,
  0,
  9,
  9,
  11,
  9,
  11,
  1,
  9,
  9,
  9,
  1,
  11,
  14,
  12,
  14,
  3,
  13,
  3,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  3,
  5,
  3,
  5,
  5,
  5,
  5,
  5,
  3,
  5,
  3,
  12,
  12,
  12,
  12,
  15,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  7,
  11,
  6,
  15,
  15,
  7,
  7,
  7,
  7,
  15,
  10,
  15,
  10,
  15,
  15,
  15,
  7,
  1,
  1,
  1,
  7,
  7,
  3,
  12,
  6,
  6,
  12,
  3,
  7,
  3,
  7,
  3,
  3,
  12,
  3,
  3,
  12,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  4,
  12,
  12,
  4,
  6,
  6,
  1,
  1,
  1,
  1,
  7,
  12,
  15,
  11,
  9,
  11,
  7,
  15,
  15,
  3,
  13,
  3,
  15,
  12,
  12,
  10,
  14,
  12,
  8,
  11,
  8,
  12,
  12,
  3,
  6,
  11,
  12,
  5,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  9,
  8,
  6,
  6,
  0,
  6,
  9,
  11,
  9,
  11,
  11,
  1,
  9,
  2,
  9,
  13,
  11,
  12,
  12,
  12,
  3,
  13,
  3,
  8,
  8,
  2,
  2,
  2,
  2,
  8,
  12,
  12,
  5,
  3,
  5,
  5,
  5,
  5,
  3,
  5,
  5,
  3,
  13,
  12,
  12,
  12,
  15,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  7,
  6,
  11,
  15,
  7,
  15,
  7,
  7,
  3,
  15,
  15,
  10,
  15,
  15,
  3,
  7,
  7,
  1,
  1,
  7,
  7,
  3,
  12,
  6,
  6,
  12,
  3,
  7,
  3,
  7,
  7,
  7,
  3,
  15,
  3,
  12,
  4,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  4,
  4,
  6,
  6,
  0,
  1,
  1,
  1,
  7,
  7,
  15,
  12,
  11,
  9,
  11,
  15,
  7,
  15,
  15,
  3,
  15,
  3,
  12,
  12,
  5,
  14,
  12,
  14,
  11,
  11,
  8,
  12,
  3,
  12,
  6,
  11,
  5,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  9,
  9,
  6,
  6,
  6,
  6,
  6,
  9,
  11,
  11,
  14,
  1,
  9,
  9,
  9,
  1,
  11,
  12,
  12,
  12,
  8,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  12,
  5,
  5,
  5,
  5,
  3,
  5,
  5,
  3,
  5,
  3,
  12,
  12,
  12,
  12,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  11,
  6,
  15,
  15,
  7,
  7,
  7,
  15,
  15,
  10,
  15,
  10,
  15,
  15,
  7,
  1,
  1,
  1,
  7,
  3,
  12,
  6,
  6,
  12,
  3,
  7,
  3,
  7,
  1,
  7,
  3,
  3,
  12,
  12,
  4,
  6,
  6,
  12,
  11,
  6,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  7,
  1,
  1,
  1,
  7,
  12,
  12,
  11,
  6,
  11,
  12,
  15,
  15,
  3,
  13,
  3,
  15,
  12,
  12,
  3,
  14,
  8,
  12,
  11,
  11,
  12,
  8,
  5,
  5,
  8,
  6,
  5,
  14,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  9,
  2,
  9,
  2,
  2,
  2,
  2,
  2,
  11,
  11,
  11,
  11,
  5,
  13,
  9,
  2,
  9,
  1,
  11,
  14,
  12,
  4,
  2,
  2,
  2,
  12,
  5,
  12,
  5,
  12,
  2,
  2,
  2,
  2,
  2,
  15,
  5,
  12,
  5,
  5,
  5,
  5,
  5,
  3,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  11,
  11,
  15,
  7,
  15,
  7,
  7,
  15,
  15,
  15,
  10,
  15,
  15,
  3,
  7,
  1,
  1,
  7,
  3,
  12,
  6,
  6,
  12,
  3,
  7,
  3,
  7,
  1,
  1,
  1,
  7,
  3,
  12,
  4,
  6,
  6,
  12,
  13,
  12,
  11,
  6,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  0,
  12,
  1,
  1,
  7,
  7,
  15,
  12,
  6,
  0,
  9,
  12,
  15,
  15,
  3,
  3,
  13,
  3,
  12,
  8,
  5,
  14,
  12,
  11,
  14,
  11,
  11,
  12,
  12,
  5,
  12,
  8,
  12,
  5,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  9,
  2,
  9,
  2,
  2,
  1,
  8,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  9,
  9,
  9,
  9,
  5,
  11,
  2,
  2,
  2,
  2,
  5,
  13,
  5,
  12,
  5,
  12,
  5,
  8,
  2,
  2,
  2,
  2,
  2,
  13,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  3,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  11,
  6,
  15,
  15,
  7,
  7,
  3,
  15,
  15,
  10,
  15,
  15,
  3,
  7,
  7,
  1,
  1,
  7,
  12,
  6,
  6,
  12,
  3,
  12,
  3,
  7,
  1,
  1,
  1,
  1,
  3,
  3,
  11,
  6,
  4,
  6,
  12,
  5,
  12,
  11,
  6,
  0,
  6,
  0,
  0,
  6,
  6,
  6,
  0,
  0,
  6,
  12,
  7,
  7,
  7,
  12,
  12,
  6,
  6,
  9,
  12,
  12,
  15,
  3,
  13,
  3,
  3,
  15,
  12,
  12,
  8,
  11,
  14,
  5,
  14,
  11,
  11,
  12,
  5,
  5,
  12,
  11,
  5,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  9,
  9,
  2,
  9,
  2,
  9,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  8,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  8,
  2,
  2,
  12,
  3,
  3,
  5,
  5,
  12,
  5,
  12,
  5,
  8,
  2,
  2,
  10,
  2,
  12,
  1,
  5,
  5,
  12,
  5,
  5,
  3,
  5,
  12,
  15,
  12,
  12,
  12,
  12,
  15,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  6,
  6,
  10,
  15,
  15,
  7,
  15,
  15,
  10,
  15,
  10,
  15,
  15,
  7,
  1,
  1,
  7,
  3,
  6,
  6,
  12,
  3,
  12,
  3,
  7,
  1,
  1,
  1,
  1,
  7,
  3,
  12,
  11,
  4,
  6,
  6,
  11,
  12,
  11,
  11,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  12,
  15,
  7,
  15,
  12,
  11,
  6,
  0,
  6,
  9,
  12,
  15,
  15,
  1,
  13,
  3,
  12,
  12,
  12,
  11,
  8,
  14,
  15,
  14,
  14,
  11,
  11,
  12,
  5,
  5,
  12,
  5,
  5,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  9,
  9,
  9,
  2,
  9,
  2,
  9,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  8,
  10,
  8,
  10,
  1,
  12,
  8,
  2,
  2,
  12,
  11,
  5,
  13,
  5,
  12,
  5,
  12,
  5,
  12,
  5,
  2,
  2,
  1,
  2,
  9,
  1,
  12,
  5,
  5,
  5,
  5,
  5,
  3,
  12,
  12,
  12,
  12,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  6,
  6,
  15,
  15,
  7,
  7,
  15,
  15,
  15,
  10,
  15,
  15,
  3,
  7,
  1,
  1,
  7,
  12,
  6,
  12,
  3,
  12,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  3,
  3,
  11,
  4,
  6,
  4,
  6,
  6,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  12,
  15,
  15,
  12,
  6,
  0,
  0,
  9,
  9,
  11,
  12,
  15,
  1,
  13,
  3,
  15,
  12,
  5,
  5,
  14,
  5,
  3,
  15,
  14,
  14,
  11,
  11,
  12,
  5,
  5,
  12,
  5,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  9,
  9,
  9,
  9,
  9,
  2,
  9,
  2,
  9,
  2,
  9,
  2,
  2,
  2,
  2,
  2,
  2,
  8,
  8,
  8,
  8,
  8,
  2,
  2,
  2,
  11,
  12,
  3,
  13,
  3,
  5,
  12,
  12,
  12,
  5,
  5,
  8,
  2,
  10,
  9,
  9,
  13,
  5,
  12,
  5,
  5,
  3,
  5,
  5,
  12,
  15,
  12,
  12,
  12,
  12,
  15,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  7,
  6,
  6,
  10,
  15,
  15,
  7,
  15,
  15,
  10,
  15,
  15,
  3,
  7,
  7,
  1,
  7,
  7,
  6,
  12,
  3,
  12,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  3,
  12,
  4,
  4,
  4,
  6,
  6,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  12,
  12,
  6,
  6,
  0,
  0,
  6,
  9,
  9,
  9,
  12,
  13,
  1,
  3,
  12,
  5,
  3,
  3,
  15,
  3,
  15,
  3,
  15,
  14,
  14,
  11,
  11,
  12,
  5,
  5,
  5,
  5,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  9,
  6,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  11,
  14,
  11,
  11,
  5,
  13,
  5,
  12,
  12,
  12,
  12,
  12,
  5,
  5,
  2,
  9,
  9,
  9,
  5,
  12,
  5,
  5,
  5,
  5,
  5,
  3,
  12,
  15,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  5,
  6,
  11,
  15,
  15,
  7,
  7,
  15,
  10,
  15,
  10,
  15,
  15,
  7,
  7,
  1,
  7,
  3,
  6,
  3,
  12,
  3,
  7,
  7,
  7,
  1,
  1,
  1,
  1,
  7,
  3,
  4,
  11,
  4,
  6,
  4,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  0,
  0,
  9,
  6,
  9,
  9,
  9,
  15,
  13,
  12,
  5,
  3,
  15,
  3,
  14,
  15,
  15,
  15,
  15,
  15,
  14,
  14,
  11,
  11,
  12,
  5,
  5,
  5,
  5,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  9,
  6,
  9,
  6,
  9,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  9,
  9,
  9,
  9,
  2,
  2,
  2,
  2,
  2,
  9,
  11,
  11,
  15,
  11,
  12,
  12,
  13,
  3,
  5,
  12,
  11,
  12,
  5,
  12,
  5,
  8,
  2,
  9,
  2,
  5,
  5,
  12,
  5,
  5,
  5,
  5,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  7,
  12,
  11,
  6,
  10,
  15,
  15,
  7,
  15,
  15,
  10,
  15,
  15,
  3,
  7,
  1,
  7,
  7,
  12,
  6,
  12,
  3,
  7,
  3,
  7,
  1,
  1,
  1,
  1,
  1,
  3,
  15,
  11,
  4,
  4,
  4,
  6,
  4,
  6,
  4,
  6,
  4,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  9,
  9,
  12,
  11,
  5,
  3,
  5,
  3,
  3,
  15,
  3,
  15,
  15,
  13,
  15,
  14,
  14,
  4,
  11,
  11,
  12,
  12,
  5,
  5,
  5,
  11,
  6,
  6,
  6,
  0,
  0,
  0,
  6,
  9,
  6,
  9,
  6,
  9,
  6,
  6,
  6,
  2,
  2,
  6,
  6,
  6,
  6,
  6,
  6,
  9,
  9,
  9,
  9,
  9,
  9,
  5,
  11,
  11,
  14,
  11,
  11,
  12,
  13,
  13,
  5,
  11,
  12,
  11,
  12,
  5,
  5,
  12,
  8,
  2,
  12,
  5,
  12,
  5,
  12,
  5,
  5,
  5,
  5,
  12,
  12,
  12,
  15,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  7,
  12,
  6,
  11,
  15,
  15,
  7,
  15,
  15,
  10,
  15,
  10,
  15,
  15,
  7,
  7,
  1,
  7,
  6,
  12,
  3,
  7,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  7,
  15,
  12,
  4,
  14,
  4,
  6,
  4,
  6,
  4,
  6,
  4,
  6,
  4,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  9,
  9,
  9,
  12,
  3,
  15,
  3,
  3,
  15,
  14,
  15,
  3,
  13,
  13,
  13,
  14,
  4,
  4,
  4,
  11,
  11,
  12,
  12,
  5,
  5,
  5,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  11,
  2,
  8,
  8,
  8,
  8,
  2,
  2,
  6,
  6,
  6,
  6,
  6,
  9,
  9,
  10,
  8,
  8,
  11,
  11,
  15,
  14,
  11,
  12,
  3,
  1,
  5,
  12,
  11,
  12,
  12,
  12,
  5,
  12,
  5,
  12,
  5,
  12,
  5,
  12,
  5,
  5,
  5,
  3,
  5,
  12,
  12,
  15,
  15,
  15,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  5,
  11,
  11,
  10,
  10,
  15,
  15,
  7,
  15,
  15,
  10,
  15,
  15,
  3,
  7,
  1,
  7,
  7,
  6,
  3,
  12,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  3,
  15,
  12,
  11,
  4,
  4,
  4,
  6,
  4,
  6,
  4,
  6,
  4,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  9,
  9,
  9,
  5,
  3,
  3,
  15,
  3,
  3,
  15,
  3,
  13,
  13,
  1,
  1,
  14,
  4,
  11,
  11,
  6,
  11,
  11,
  12,
  5,
  5,
  5,
  12,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  11,
  2,
  8,
  10,
  10,
  10,
  10,
  10,
  8,
  2,
  2,
  9,
  9,
  9,
  2,
  8,
  8,
  8,
  11,
  11,
  11,
  5,
  11,
  11,
  12,
  13,
  13,
  5,
  11,
  12,
  11,
  12,
  5,
  12,
  12,
  12,
  5,
  12,
  12,
  12,
  5,
  12,
  5,
  5,
  5,
  3,
  12,
  12,
  15,
  15,
  15,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  5,
  12,
  11,
  11,
  15,
  10,
  10,
  15,
  15,
  15,
  10,
  15,
  15,
  15,
  7,
  1,
  7,
  7,
  3,
  12,
  12,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  7,
  15,
  12,
  12,
  4,
  14,
  4,
  6,
  4,
  14,
  4,
  6,
  4,
  4,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  11,
  11,
  11,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  11,
  9,
  12,
  3,
  3,
  3,
  3,
  15,
  14,
  15,
  13,
  1,
  1,
  1,
  14,
  11,
  11,
  6,
  6,
  11,
  11,
  11,
  12,
  5,
  5,
  5,
  12,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  2,
  8,
  8,
  10,
  15,
  10,
  15,
  10,
  15,
  10,
  10,
  10,
  8,
  2,
  2,
  8,
  10,
  8,
  8,
  9,
  11,
  11,
  15,
  14,
  11,
  11,
  3,
  1,
  5,
  12,
  11,
  12,
  11,
  12,
  5,
  12,
  5,
  12,
  12,
  12,
  5,
  12,
  5,
  12,
  5,
  5,
  5,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  12,
  12,
  15,
  15,
  12,
  12,
  11,
  12,
  10,
  10,
  15,
  10,
  7,
  15,
  15,
  10,
  15,
  3,
  7,
  7,
  1,
  7,
  6,
  12,
  3,
  7,
  3,
  7,
  1,
  1,
  1,
  1,
  7,
  1,
  15,
  15,
  12,
  11,
  4,
  14,
  4,
  14,
  4,
  14,
  4,
  4,
  6,
  0,
  0,
  0,
  6,
  6,
  6,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  9,
  9,
  9,
  11,
  5,
  3,
  3,
  13,
  15,
  15,
  3,
  13,
  13,
  1,
  1,
  14,
  6,
  11,
  6,
  0,
  6,
  11,
  6,
  11,
  12,
  5,
  5,
  12,
  12,
  8,
  8,
  8,
  8,
  8,
  8,
  2,
  8,
  2,
  8,
  8,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  8,
  8,
  8,
  8,
  2,
  6,
  11,
  9,
  11,
  15,
  11,
  11,
  12,
  5,
  13,
  5,
  11,
  12,
  11,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  5,
  5,
  5,
  3,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  15,
  12,
  12,
  12,
  12,
  11,
  11,
  10,
  12,
  10,
  10,
  15,
  15,
  15,
  10,
  15,
  15,
  15,
  7,
  1,
  7,
  3,
  12,
  3,
  12,
  3,
  7,
  1,
  1,
  1,
  1,
  7,
  1,
  7,
  15,
  12,
  11,
  4,
  14,
  4,
  14,
  4,
  14,
  4,
  14,
  4,
  0,
  0,
  0,
  6,
  6,
  4,
  14,
  14,
  14,
  14,
  4,
  11,
  11,
  11,
  11,
  4,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  9,
  11,
  9,
  12,
  5,
  3,
  3,
  13,
  15,
  5,
  15,
  13,
  1,
  1,
  1,
  14,
  11,
  6,
  6,
  0,
  0,
  6,
  11,
  6,
  11,
  12,
  5,
  5,
  12,
  12,
  8,
  12,
  8,
  8,
  8,
  8,
  2,
  8,
  2,
  8,
  2,
  8,
  2,
  2,
  2,
  2,
  2,
  8,
  10,
  10,
  10,
  8,
  8,
  9,
  9,
  9,
  11,
  11,
  7,
  14,
  11,
  11,
  3,
  1,
  5,
  12,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  5,
  12,
  12,
  5,
  12,
  12,
  12,
  5,
  5,
  5,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  15,
  12,
  12,
  12,
  11,
  11,
  12,
  12,
  10,
  10,
  10,
  10,
  15,
  10,
  15,
  10,
  15,
  3,
  7,
  7,
  1,
  7,
  6,
  12,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  7,
  3,
  3,
  15,
  4,
  14,
  4,
  14,
  4,
  14,
  4,
  14,
  4,
  6,
  0,
  0,
  6,
  6,
  4,
  14,
  3,
  1,
  3,
  14,
  14,
  14,
  4,
  11,
  11,
  11,
  4,
  11,
  6,
  0,
  0,
  0,
  0,
  9,
  9,
  11,
  11,
  5,
  15,
  5,
  1,
  15,
  15,
  3,
  13,
  13,
  1,
  1,
  12,
  6,
  11,
  6,
  0,
  0,
  0,
  6,
  6,
  6,
  11,
  5,
  5,
  12,
  12,
  12,
  8,
  12,
  8,
  12,
  8,
  8,
  2,
  8,
  2,
  8,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  8,
  8,
  2,
  2,
  9,
  6,
  11,
  9,
  11,
  1,
  14,
  11,
  12,
  5,
  1,
  5,
  11,
  11,
  11,
  12,
  11,
  12,
  12,
  12,
  12,
  5,
  12,
  12,
  12,
  12,
  5,
  12,
  5,
  5,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  12,
  15,
  12,
  12,
  12,
  12,
  12,
  10,
  12,
  10,
  15,
  10,
  10,
  15,
  10,
  15,
  15,
  15,
  7,
  1,
  7,
  3,
  12,
  3,
  12,
  7,
  7,
  1,
  1,
  1,
  1,
  7,
  1,
  3,
  15,
  12,
  11,
  4,
  14,
  4,
  14,
  4,
  14,
  4,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  11,
  14,
  14,
  14,
  4,
  11,
  11,
  4,
  4,
  11,
  6,
  0,
  0,
  6,
  9,
  11,
  9,
  12,
  5,
  3,
  3,
  1,
  15,
  5,
  15,
  13,
  1,
  13,
  1,
  14,
  6,
  6,
  11,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  5,
  5,
  12,
  12,
  12,
  8,
  12,
  8,
  12,
  8,
  8,
  2,
  8,
  2,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  2,
  2,
  2,
  2,
  9,
  6,
  6,
  9,
  11,
  11,
  7,
  14,
  11,
  11,
  3,
  1,
  5,
  12,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  5,
  12,
  5,
  12,
  12,
  12,
  12,
  5,
  5,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  10,
  12,
  10,
  12,
  10,
  10,
  15,
  10,
  15,
  10,
  15,
  3,
  7,
  7,
  7,
  12,
  6,
  12,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  7,
  3,
  15,
  12,
  4,
  14,
  14,
  14,
  14,
  14,
  4,
  4,
  0,
  0,
  0,
  0,
  6,
  11,
  11,
  11,
  11,
  11,
  11,
  6,
  0,
  6,
  11,
  11,
  11,
  11,
  11,
  4,
  4,
  11,
  11,
  6,
  9,
  11,
  9,
  11,
  11,
  15,
  15,
  5,
  1,
  15,
  15,
  3,
  3,
  13,
  1,
  13,
  12,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  5,
  5,
  12,
  12,
  12,
  8,
  12,
  8,
  8,
  2,
  8,
  2,
  9,
  9,
  9,
  9,
  9,
  9,
  6,
  9,
  6,
  9,
  6,
  6,
  6,
  6,
  9,
  11,
  9,
  11,
  15,
  14,
  11,
  12,
  5,
  1,
  5,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  5,
  12,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  5,
  5,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  10,
  12,
  10,
  12,
  10,
  15,
  10,
  10,
  15,
  15,
  15,
  3,
  7,
  15,
  12,
  12,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  12,
  12,
  6,
  14,
  14,
  14,
  14,
  4,
  4,
  6,
  0,
  0,
  6,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  12,
  11,
  6,
  0,
  0,
  6,
  11,
  11,
  11,
  4,
  4,
  4,
  4,
  11,
  11,
  9,
  11,
  11,
  12,
  5,
  15,
  3,
  13,
  15,
  3,
  3,
  3,
  13,
  13,
  1,
  14,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  5,
  13,
  5,
  12,
  12,
  12,
  8,
  12,
  8,
  8,
  2,
  9,
  9,
  11,
  11,
  11,
  11,
  11,
  11,
  9,
  9,
  9,
  9,
  6,
  6,
  9,
  11,
  9,
  11,
  11,
  7,
  14,
  12,
  11,
  5,
  1,
  5,
  12,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  5,
  12,
  5,
  12,
  12,
  12,
  12,
  5,
  12,
  5,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  12,
  12,
  15,
  12,
  15,
  12,
  10,
  12,
  10,
  15,
  10,
  12,
  10,
  10,
  15,
  10,
  15,
  3,
  7,
  7,
  7,
  6,
  12,
  12,
  3,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  3,
  3,
  15,
  11,
  4,
  14,
  3,
  14,
  4,
  14,
  4,
  6,
  0,
  6,
  11,
  11,
  12,
  5,
  12,
  5,
  12,
  5,
  12,
  12,
  12,
  11,
  6,
  0,
  0,
  6,
  11,
  11,
  4,
  4,
  4,
  4,
  11,
  11,
  11,
  8,
  5,
  15,
  5,
  3,
  3,
  15,
  15,
  3,
  15,
  13,
  13,
  3,
  12,
  6,
  6,
  11,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  6,
  11,
  12,
  5,
  5,
  5,
  12,
  12,
  12,
  8,
  12,
  8,
  8,
  9,
  9,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  9,
  9,
  9,
  8,
  8,
  11,
  11,
  11,
  11,
  11,
  15,
  14,
  11,
  12,
  5,
  1,
  5,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  5,
  12,
  5,
  12,
  5,
  12,
  12,
  12,
  12,
  5,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  12,
  15,
  15,
  15,
  12,
  15,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  15,
  15,
  15,
  3,
  7,
  15,
  12,
  12,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  12,
  12,
  6,
  3,
  14,
  14,
  14,
  4,
  6,
  6,
  6,
  11,
  11,
  12,
  5,
  12,
  5,
  12,
  5,
  12,
  5,
  5,
  12,
  12,
  11,
  6,
  0,
  0,
  6,
  11,
  4,
  4,
  4,
  8,
  4,
  11,
  11,
  11,
  15,
  5,
  15,
  3,
  3,
  5,
  3,
  3,
  15,
  3,
  13,
  3,
  14,
  0,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  11,
  12,
  5,
  13,
  5,
  12,
  12,
  12,
  8,
  12,
  8,
  2,
  9,
  9,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  9,
  8,
  12,
  12,
  8,
  11,
  9,
  11,
  11,
  5,
  14,
  12,
  11,
  5,
  1,
  5,
  12,
  11,
  11,
  11,
  12,
  11,
  12,
  12,
  5,
  5,
  5,
  12,
  5,
  12,
  12,
  12,
  12,
  5,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  10,
  15,
  10,
  15,
  10,
  10,
  12,
  10,
  15,
  10,
  10,
  15,
  3,
  7,
  3,
  7,
  6,
  12,
  7,
  3,
  7,
  1,
  1,
  1,
  1,
  7,
  1,
  3,
  3,
  15,
  11,
  4,
  14,
  3,
  14,
  4,
  4,
  6,
  6,
  11,
  11,
  12,
  5,
  12,
  5,
  15,
  15,
  15,
  15,
  15,
  15,
  5,
  12,
  12,
  11,
  6,
  0,
  0,
  6,
  11,
  4,
  4,
  4,
  8,
  11,
  11,
  8,
  3,
  15,
  5,
  15,
  3,
  15,
  15,
  3,
  15,
  3,
  13,
  3,
  12,
  0,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  11,
  5,
  5,
  5,
  5,
  12,
  12,
  12,
  8,
  8,
  2,
  2,
  11,
  9,
  9,
  11,
  9,
  11,
  11,
  11,
  11,
  12,
  12,
  5,
  12,
  9,
  11,
  11,
  14,
  15,
  14,
  11,
  12,
  5,
  1,
  5,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  5,
  5,
  5,
  3,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  12,
  15,
  12,
  15,
  15,
  15,
  10,
  15,
  10,
  15,
  10,
  10,
  12,
  10,
  10,
  15,
  10,
  15,
  3,
  3,
  3,
  12,
  12,
  3,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  12,
  11,
  6,
  3,
  14,
  14,
  14,
  6,
  6,
  11,
  11,
  12,
  5,
  12,
  5,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  5,
  12,
  11,
  6,
  0,
  0,
  11,
  11,
  4,
  4,
  8,
  11,
  11,
  12,
  15,
  5,
  15,
  5,
  15,
  5,
  3,
  3,
  15,
  15,
  13,
  5,
  14,
  0,
  6,
  6,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  11,
  5,
  5,
  13,
  5,
  12,
  8,
  12,
  8,
  8,
  2,
  11,
  11,
  11,
  11,
  11,
  9,
  11,
  11,
  11,
  12,
  5,
  5,
  5,
  11,
  9,
  11,
  11,
  14,
  14,
  12,
  12,
  5,
  1,
  5,
  12,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  5,
  3,
  5,
  3,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  10,
  15,
  10,
  15,
  10,
  15,
  10,
  10,
  12,
  10,
  10,
  15,
  3,
  15,
  3,
  3,
  6,
  12,
  7,
  7,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  3,
  15,
  9,
  4,
  14,
  3,
  14,
  4,
  6,
  6,
  11,
  12,
  5,
  12,
  5,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  5,
  12,
  11,
  0,
  0,
  6,
  11,
  11,
  4,
  4,
  8,
  9,
  8,
  3,
  15,
  5,
  15,
  5,
  15,
  15,
  3,
  3,
  15,
  3,
  5,
  12,
  0,
  0,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  6,
  11,
  5,
  5,
  13,
  5,
  12,
  8,
  12,
  8,
  8,
  2,
  11,
  11,
  9,
  9,
  11,
  9,
  11,
  9,
  15,
  5,
  5,
  5,
  9,
  11,
  11,
  14,
  15,
  14,
  11,
  12,
  5,
  13,
  5,
  11,
  11,
  6,
  11,
  11,
  12,
  12,
  5,
  5,
  5,
  3,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  12,
  15,
  12,
  15,
  15,
  15,
  5,
  15,
  15,
  15,
  10,
  15,
  10,
  10,
  12,
  10,
  10,
  15,
  3,
  15,
  3,
  12,
  12,
  3,
  7,
  1,
  1,
  1,
  1,
  1,
  1,
  3,
  7,
  3,
  12,
  11,
  14,
  3,
  14,
  4,
  4,
  6,
  11,
  12,
  12,
  12,
  5,
  15,
  15,
  15,
  15,
  15,
  5,
  5,
  5,
  5,
  5,
  15,
  5,
  5,
  12,
  11,
  6,
  0,
  0,
  6,
  11,
  11,
  4,
  8,
  11,
  12,
  3,
  3,
  15,
  5,
  15,
  5,
  3,
  3,
  3,
  3,
  15,
  5,
  14,
  0,
  0,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  11,
  12,
  5,
  13,
  5,
  12,
  8,
  12,
  8,
  2,
  11,
  9,
  8,
  12,
  12,
  10,
  12,
  12,
  15,
  5,
  5,
  5,
  11,
  11,
  14,
  14,
  14,
  11,
  12,
  12,
  3,
  1,
  12,
  11,
  6,
  11,
  6,
  11,
  11,
  12,
  12,
  5,
  3,
  5,
  3,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  15,
  15,
  15,
  15,
  15,
  5,
  15,
  3,
  15,
  3,
  15,
  10,
  15,
  12,
  10,
  8,
  10,
  15,
  15,
  3,
  15,
  6,
  12,
  7,
  7,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  3,
  12,
  9,
  14,
  3,
  3,
  14,
  6,
  11,
  12,
  12,
  12,
  5,
  12,
  5,
  15,
  15,
  15,
  5,
  10,
  10,
  10,
  10,
  5,
  5,
  15,
  5,
  12,
  12,
  11,
  0,
  0,
  0,
  11,
  4,
  11,
  4,
  9,
  12,
  3,
  15,
  5,
  15,
  5,
  5,
  5,
  3,
  13,
  1,
  13,
  12,
  12,
  0,
  0,
  6,
  6,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  11,
  12,
  5,
  13,
  12,
  12,
  8,
  8,
  2,
  11,
  9,
  12,
  12,
  10,
  12,
  10,
  12,
  1,
  5,
  5,
  5,
  11,
  11,
  11,
  14,
  11,
  11,
  12,
  5,
  13,
  13,
  12,
  6,
  11,
  6,
  11,
  11,
  12,
  12,
  5,
  5,
  5,
  3,
  5,
  12,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  5,
  15,
  3,
  3,
  3,
  15,
  3,
  15,
  10,
  15,
  12,
  10,
  8,
  10,
  15,
  15,
  3,
  12,
  12,
  3,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  7,
  3,
  12,
  11,
  14,
  3,
  14,
  4,
  6,
  11,
  8,
  12,
  5,
  12,
  5,
  15,
  15,
  15,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  5,
  10,
  10,
  12,
  12,
  6,
  0,
  0,
  6,
  11,
  4,
  4,
  11,
  8,
  3,
  3,
  15,
  5,
  5,
  5,
  3,
  5,
  12,
  12,
  5,
  12,
  11,
  0,
  0,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  0,
  6,
  11,
  5,
  13,
  5,
  8,
  12,
  8,
  8,
  2,
  9,
  12,
  12,
  12,
  10,
  10,
  12,
  1,
  5,
  5,
  5,
  9,
  11,
  14,
  11,
  14,
  12,
  5,
  5,
  13,
  13,
  11,
  11,
  6,
  11,
  6,
  11,
  11,
  12,
  12,
  5,
  3,
  5,
  3,
  5,
  12,
  12,
  12,
  12,
  12,
  15,
  12,
  15,
  15,
  15,
  15,
  12,
  12,
  15,
  12,
  15,
  15,
  15,
  5,
  15,
  3,
  3,
  3,
  15,
  3,
  15,
  10,
  10,
  12,
  10,
  8,
  10,
  15,
  15,
  6,
  12,
  3,
  7,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  3,
  12,
  9,
  14,
  3,
  3,
  14,
  6,
  8,
  12,
  12,
  12,
  5,
  15,
  15,
  15,
  10,
  10,
  10,
  8,
  8,
  8,
  10,
  10,
  10,
  10,
  10,
  12,
  10,
  11,
  6,
  0,
  0,
  0,
  6,
  11,
  4,
  11,
  11,
  3,
  3,
  5,
  15,
  5,
  15,
  5,
  12,
  5,
  5,
  12,
  11,
  6,
  0,
  0,
  6,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  6,
  0,
  6,
  6,
  11,
  12,
  5,
  13,
  12,
  8,
  12,
  8,
  2,
  9,
  8,
  12,
  10,
  12,
  10,
  12,
  15,
  5,
  5,
  5,
  11,
  11,
  11,
  14,
  11,
  5,
  5,
  3,
  13,
  3,
  11,
  6,
  11,
  6,
  11,
  6,
  11,
  12,
  5,
  5,
  5,
  3,
  5,
  12,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  12,
  12,
  15,
  12,
  15,
  12,
  15,
  5,
  15,
  5,
  15,
  3,
  3,
  3,
  15,
  3,
  15,
  10,
  10,
  12,
  10,
  15,
  10,
  3,
  11,
  12,
  12,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  3,
  15,
  12,
  11,
  14,
  3,
  14,
  4,
  11,
  12,
  12,
  12,
  5,
  15,
  15,
  15,
  10,
  10,
  11,
  6,
  6,
  6,
  0,
  0,
  6,
  12,
  10,
  12,
  10,
  12,
  12,
  11,
  0,
  0,
  0,
  0,
  6,
  11,
  6,
  4,
  3,
  3,
  15,
  5,
  15,
  5,
  12,
  5,
  15,
  5,
  5,
  12,
  6,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  6,
  6,
  11,
  12,
  13,
  5,
  12,
  8,
  8,
  2,
  11,
  9,
  9,
  6,
  9,
  11,
  11,
  15,
  5,
  5,
  5,
  9,
  11,
  11,
  11,
  11,
  12,
  5,
  13,
  13,
  5,
  6,
  11,
  6,
  11,
  6,
  11,
  11,
  12,
  12,
  5,
  3,
  3,
  3,
  5,
  12,
  12,
  12,
  12,
  11,
  15,
  12,
  15,
  12,
  12,
  12,
  15,
  12,
  15,
  12,
  15,
  15,
  15,
  10,
  15,
  3,
  15,
  3,
  3,
  3,
  15,
  10,
  15,
  12,
  10,
  8,
  10,
  15,
  15,
  11,
  12,
  15,
  7,
  1,
  1,
  1,
  7,
  7,
  3,
  7,
  3,
  3,
  12,
  9,
  14,
  3,
  3,
  6,
  11,
  8,
  12,
  5,
  15,
  15,
  7,
  15,
  10,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  0,
  6,
  12,
  10,
  12,
  10,
  12,
  12,
  6,
  0,
  0,
  0,
  6,
  6,
  11,
  8,
  3,
  3,
  5,
  15,
  5,
  5,
  12,
  15,
  5,
  15,
  5,
  5,
  11,
  6,
  0,
  0,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  6,
  11,
  11,
  5,
  13,
  12,
  12,
  8,
  8,
  11,
  11,
  6,
  9,
  11,
  11,
  11,
  12,
  5,
  5,
  5,
  11,
  9,
  11,
  11,
  12,
  5,
  5,
  3,
  13,
  12,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  12,
  5,
  5,
  5,
  3,
  5,
  3,
  5,
  12,
  12,
  11,
  12,
  12,
  12,
  12,
  15,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  15,
  10,
  15,
  10,
  15,
  3,
  15,
  3,
  3,
  3,
  15,
  10,
  15,
  12,
  10,
  8,
  10,
  3,
  12,
  12,
  12,
  7,
  1,
  1,
  7,
  7,
  3,
  7,
  3,
  3,
  15,
  12,
  9,
  14,
  3,
  14,
  6,
  8,
  12,
  12,
  12,
  15,
  7,
  15,
  10,
  11,
  6,
  6,
  2,
  6,
  6,
  11,
  6,
  6,
  0,
  6,
  12,
  10,
  12,
  10,
  11,
  6,
  0,
  0,
  0,
  0,
  6,
  4,
  4,
  15,
  15,
  15,
  5,
  15,
  12,
  5,
  3,
  15,
  5,
  15,
  5,
  14,
  6,
  6,
  0,
  6,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  0,
  6,
  11,
  12,
  13,
  5,
  8,
  12,
  8,
  11,
  9,
  9,
  11,
  11,
  11,
  9,
  12,
  12,
  5,
  12,
  9,
  11,
  11,
  11,
  11,
  12,
  5,
  13,
  3,
  5,
  6,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  3,
  3,
  3,
  5,
  12,
  5,
  12,
  12,
  11,
  12,
  12,
  15,
  15,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  15,
  15,
  10,
  15,
  10,
  15,
  3,
  15,
  3,
  15,
  3,
  15,
  10,
  10,
  12,
  10,
  15,
  15,
  11,
  12,
  15,
  7,
  1,
  7,
  1,
  7,
  7,
  3,
  3,
  15,
  3,
  12,
  6,
  14,
  3,
  14,
  11,
  11,
  8,
  12,
  5,
  15,
  7,
  15,
  10,
  11,
  6,
  2,
  6,
  6,
  6,
  6,
  11,
  11,
  6,
  0,
  12,
  12,
  10,
  12,
  12,
  11,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  5,
  15,
  5,
  15,
  5,
  12,
  3,
  13,
  3,
  15,
  5,
  15,
  12,
  6,
  6,
  0,
  6,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  11,
  5,
  13,
  12,
  8,
  8,
  2,
  9,
  11,
  11,
  11,
  9,
  9,
  8,
  12,
  12,
  8,
  11,
  9,
  11,
  11,
  12,
  5,
  5,
  3,
  5,
  12,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  12,
  5,
  5,
  5,
  3,
  3,
  12,
  9,
  13,
  12,
  11,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  12,
  15,
  12,
  12,
  12,
  15,
  10,
  15,
  10,
  10,
  10,
  15,
  3,
  15,
  3,
  15,
  3,
  15,
  10,
  10,
  12,
  10,
  15,
  12,
  11,
  12,
  7,
  7,
  1,
  7,
  7,
  3,
  7,
  3,
  3,
  15,
  11,
  11,
  14,
  14,
  4,
  11,
  8,
  12,
  5,
  12,
  7,
  7,
  10,
  11,
  6,
  2,
  6,
  2,
  6,
  6,
  11,
  6,
  11,
  11,
  0,
  6,
  10,
  12,
  10,
  11,
  12,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  12,
  15,
  15,
  15,
  12,
  5,
  13,
  3,
  15,
  5,
  15,
  5,
  14,
  6,
  6,
  0,
  0,
  6,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  6,
  6,
  11,
  12,
  13,
  12,
  12,
  8,
  2,
  9,
  11,
  11,
  9,
  6,
  6,
  6,
  8,
  8,
  11,
  11,
  11,
  11,
  12,
  11,
  5,
  3,
  3,
  12,
  12,
  6,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  3,
  3,
  3,
  2,
  9,
  9,
  12,
  12,
  11,
  15,
  15,
  12,
  12,
  12,
  15,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  10,
  12,
  10,
  10,
  10,
  15,
  3,
  15,
  3,
  15,
  10,
  15,
  10,
  10,
  15,
  15,
  11,
  12,
  15,
  7,
  1,
  7,
  7,
  3,
  7,
  3,
  15,
  12,
  11,
  6,
  6,
  14,
  3,
  11,
  11,
  12,
  8,
  12,
  15,
  7,
  1,
  11,
  11,
  2,
  2,
  2,
  6,
  6,
  6,
  6,
  11,
  6,
  11,
  6,
  0,
  12,
  10,
  12,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  6,
  11,
  11,
  15,
  15,
  5,
  12,
  13,
  3,
  13,
  3,
  15,
  5,
  15,
  12,
  6,
  0,
  0,
  0,
  6,
  6,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  0,
  6,
  11,
  11,
  13,
  5,
  8,
  12,
  8,
  11,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  9,
  11,
  11,
  11,
  11,
  12,
  5,
  3,
  5,
  5,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  12,
  5,
  5,
  5,
  3,
  3,
  2,
  2,
  9,
  12,
  11,
  12,
  15,
  12,
  12,
  15,
  12,
  12,
  15,
  15,
  12,
  15,
  12,
  15,
  10,
  12,
  10,
  12,
  12,
  10,
  10,
  15,
  3,
  15,
  3,
  15,
  10,
  15,
  10,
  12,
  15,
  12,
  11,
  12,
  7,
  7,
  7,
  3,
  7,
  3,
  15,
  12,
  11,
  6,
  6,
  6,
  14,
  14,
  11,
  11,
  8,
  12,
  5,
  12,
  1,
  7,
  11,
  11,
  2,
  2,
  6,
  2,
  6,
  6,
  11,
  6,
  11,
  11,
  6,
  0,
  11,
  12,
  10,
  12,
  12,
  11,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  15,
  5,
  12,
  5,
  3,
  13,
  3,
  13,
  3,
  15,
  3,
  14,
  0,
  0,
  0,
  0,
  6,
  11,
  11,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  11,
  11,
  13,
  13,
  12,
  8,
  8,
  11,
  11,
  9,
  9,
  6,
  6,
  6,
  6,
  6,
  6,
  11,
  11,
  11,
  12,
  5,
  5,
  3,
  5,
  12,
  12,
  6,
  6,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  3,
  3,
  3,
  2,
  2,
  2,
  12,
  12,
  11,
  15,
  12,
  15,
  15,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  15,
  15,
  10,
  12,
  12,
  12,
  12,
  10,
  15,
  15,
  3,
  15,
  3,
  15,
  10,
  10,
  10,
  15,
  8,
  12,
  15,
  3,
  7,
  3,
  7,
  3,
  7,
  3,
  3,
  15,
  12,
  11,
  6,
  14,
  14,
  11,
  11,
  12,
  12,
  12,
  15,
  7,
  1,
  11,
  11,
  2,
  2,
  2,
  0,
  0,
  0,
  6,
  11,
  6,
  11,
  11,
  0,
  6,
  10,
  12,
  10,
  11,
  11,
  0,
  6,
  0,
  0,
  0,
  11,
  12,
  5,
  12,
  5,
  3,
  13,
  13,
  13,
  3,
  15,
  5,
  15,
  12,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  11,
  11,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  6,
  12,
  5,
  13,
  5,
  12,
  8,
  11,
  11,
  9,
  11,
  11,
  11,
  11,
  9,
  6,
  6,
  9,
  11,
  11,
  12,
  5,
  5,
  3,
  5,
  12,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  12,
  5,
  12,
  5,
  3,
  3,
  12,
  2,
  12,
  12,
  11,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  10,
  12,
  12,
  12,
  12,
  12,
  12,
  10,
  3,
  15,
  3,
  15,
  15,
  15,
  10,
  10,
  15,
  12,
  11,
  12,
  7,
  3,
  7,
  3,
  7,
  1,
  7,
  3,
  3,
  15,
  12,
  6,
  14,
  4,
  11,
  11,
  8,
  12,
  5,
  7,
  1,
  7,
  11,
  11,
  2,
  2,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  11,
  6,
  0,
  12,
  10,
  12,
  12,
  11,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  5,
  3,
  3,
  3,
  13,
  13,
  13,
  3,
  15,
  3,
  14,
  0,
  0,
  0,
  0,
  6,
  11,
  11,
  11,
  12,
  11,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  0,
  6,
  12,
  12,
  13,
  5,
  8,
  8,
  11,
  9,
  11,
  4,
  4,
  4,
  4,
  11,
  9,
  6,
  9,
  11,
  11,
  12,
  5,
  3,
  3,
  12,
  11,
  12,
  6,
  6,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  2,
  12,
  3,
  3,
  3,
  3,
  5,
  12,
  12,
  11,
  15,
  12,
  15,
  15,
  15,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  10,
  12,
  12,
  12,
  11,
  12,
  10,
  15,
  3,
  15,
  3,
  15,
  10,
  15,
  10,
  15,
  8,
  12,
  15,
  3,
  7,
  3,
  3,
  1,
  1,
  7,
  7,
  15,
  3,
  12,
  6,
  14,
  4,
  11,
  11,
  12,
  12,
  12,
  1,
  7,
  1,
  12,
  11,
  2,
  2,
  0,
  0,
  0,
  0,
  0,
  11,
  6,
  12,
  12,
  11,
  0,
  11,
  12,
  10,
  11,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  5,
  12,
  15,
  3,
  13,
  13,
  1,
  13,
  13,
  3,
  15,
  12,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  12,
  14,
  4,
  12,
  12,
  6,
  0,
  6,
  0,
  0,
  6,
  0,
  6,
  6,
  15,
  12,
  1,
  5,
  12,
  12,
  11,
  11,
  4,
  12,
  12,
  12,
  12,
  4,
  11,
  9,
  9,
  11,
  11,
  5,
  5,
  3,
  3,
  5,
  12,
  11,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  12,
  12,
  2,
  12,
  3,
  3,
  3,
  5,
  12,
  12,
  11,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  15,
  12,
  15,
  15,
  12,
  15,
  10,
  12,
  12,
  12,
  11,
  12,
  11,
  12,
  10,
  15,
  3,
  15,
  15,
  15,
  10,
  10,
  15,
  12,
  8,
  12,
  7,
  3,
  15,
  7,
  1,
  1,
  7,
  3,
  3,
  15,
  12,
  6,
  14,
  11,
  11,
  11,
  8,
  12,
  5,
  7,
  1,
  7,
  12,
  12,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  12,
  11,
  0,
  12,
  10,
  12,
  12,
  11,
  0,
  6,
  0,
  0,
  0,
  0,
  6,
  12,
  15,
  5,
  3,
  3,
  13,
  13,
  13,
  3,
  15,
  3,
  14,
  6,
  6,
  0,
  0,
  0,
  11,
  11,
  12,
  3,
  12,
  14,
  12,
  5,
  12,
  0,
  0,
  0,
  6,
  0,
  6,
  0,
  6,
  1,
  11,
  1,
  5,
  12,
  5,
  11,
  4,
  12,
  4,
  9,
  9,
  4,
  12,
  4,
  11,
  9,
  11,
  11,
  5,
  5,
  3,
  3,
  12,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  12,
  3,
  3,
  3,
  3,
  3,
  5,
  12,
  12,
  11,
  15,
  12,
  12,
  15,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  10,
  12,
  11,
  12,
  11,
  12,
  11,
  12,
  10,
  15,
  3,
  15,
  10,
  15,
  10,
  15,
  10,
  12,
  15,
  3,
  15,
  3,
  1,
  1,
  1,
  7,
  7,
  15,
  3,
  12,
  6,
  4,
  11,
  11,
  11,
  12,
  12,
  12,
  1,
  1,
  1,
  7,
  12,
  12,
  12,
  12,
  6,
  0,
  0,
  0,
  0,
  11,
  14,
  12,
  11,
  0,
  11,
  12,
  10,
  11,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  5,
  12,
  5,
  3,
  13,
  13,
  1,
  13,
  13,
  3,
  15,
  12,
  9,
  6,
  6,
  0,
  0,
  6,
  11,
  12,
  3,
  3,
  14,
  12,
  12,
  5,
  6,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  1,
  12,
  1,
  5,
  5,
  5,
  4,
  4,
  4,
  9,
  9,
  9,
  9,
  4,
  12,
  4,
  11,
  11,
  11,
  12,
  5,
  13,
  3,
  5,
  12,
  11,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  12,
  5,
  5,
  5,
  3,
  3,
  3,
  5,
  3,
  5,
  12,
  12,
  15,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  12,
  15,
  15,
  12,
  15,
  15,
  12,
  12,
  11,
  12,
  11,
  12,
  11,
  10,
  15,
  15,
  15,
  15,
  10,
  15,
  15,
  12,
  8,
  12,
  15,
  3,
  1,
  1,
  1,
  7,
  7,
  3,
  3,
  15,
  12,
  6,
  4,
  9,
  11,
  11,
  8,
  12,
  5,
  1,
  1,
  7,
  3,
  3,
  3,
  1,
  12,
  12,
  6,
  0,
  0,
  0,
  11,
  14,
  14,
  11,
  0,
  11,
  10,
  12,
  11,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  6,
  12,
  5,
  12,
  3,
  13,
  1,
  13,
  13,
  3,
  13,
  3,
  14,
  9,
  9,
  6,
  0,
  0,
  6,
  11,
  12,
  3,
  3,
  12,
  14,
  12,
  5,
  12,
  6,
  0,
  6,
  2,
  2,
  6,
  6,
  1,
  11,
  13,
  5,
  12,
  5,
  11,
  11,
  9,
  9,
  9,
  9,
  9,
  9,
  12,
  5,
  11,
  11,
  11,
  5,
  5,
  3,
  3,
  12,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  3,
  5,
  3,
  3,
  3,
  5,
  12,
  12,
  11,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  15,
  15,
  11,
  12,
  11,
  12,
  11,
  12,
  12,
  10,
  3,
  15,
  15,
  15,
  10,
  15,
  10,
  12,
  15,
  3,
  7,
  1,
  1,
  1,
  7,
  3,
  7,
  3,
  3,
  12,
  6,
  4,
  11,
  9,
  11,
  12,
  12,
  12,
  1,
  1,
  1,
  3,
  1,
  1,
  1,
  1,
  12,
  12,
  6,
  0,
  0,
  11,
  3,
  3,
  11,
  0,
  11,
  12,
  11,
  11,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  11,
  5,
  12,
  5,
  3,
  13,
  13,
  1,
  13,
  13,
  3,
  15,
  12,
  2,
  9,
  9,
  6,
  0,
  6,
  11,
  12,
  1,
  3,
  12,
  12,
  12,
  5,
  12,
  6,
  6,
  2,
  10,
  2,
  2,
  6,
  15,
  12,
  13,
  5,
  12,
  5,
  11,
  11,
  9,
  2,
  9,
  2,
  9,
  9,
  4,
  5,
  5,
  11,
  11,
  12,
  5,
  13,
  3,
  5,
  12,
  11,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  5,
  5,
  3,
  3,
  3,
  3,
  3,
  5,
  12,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  15,
  12,
  11,
  12,
  11,
  12,
  11,
  12,
  10,
  15,
  15,
  15,
  10,
  15,
  15,
  15,
  10,
  15,
  3,
  3,
  7,
  1,
  1,
  7,
  7,
  3,
  7,
  15,
  12,
  6,
  4,
  9,
  11,
  11,
  8,
  12,
  5,
  1,
  1,
  7,
  1,
  3,
  1,
  1,
  1,
  12,
  12,
  6,
  0,
  0,
  11,
  1,
  3,
  11,
  0,
  9,
  10,
  9,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  5,
  5,
  12,
  15,
  13,
  1,
  13,
  1,
  13,
  13,
  3,
  14,
  12,
  2,
  9,
  6,
  0,
  0,
  6,
  12,
  1,
  3,
  12,
  14,
  12,
  12,
  5,
  12,
  6,
  2,
  1,
  2,
  2,
  9,
  12,
  11,
  13,
  12,
  12,
  5,
  12,
  9,
  9,
  9,
  2,
  9,
  2,
  9,
  9,
  12,
  13,
  5,
  11,
  11,
  5,
  1,
  13,
  12,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  5,
  5,
  3,
  3,
  3,
  5,
  12,
  12,
  11,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  11,
  12,
  11,
  12,
  11,
  12,
  3,
  15,
  15,
  15,
  10,
  15,
  10,
  15,
  3,
  3,
  7,
  1,
  1,
  7,
  1,
  3,
  7,
  3,
  3,
  12,
  6,
  9,
  9,
  9,
  11,
  12,
  12,
  12,
  1,
  1,
  1,
  3,
  1,
  1,
  1,
  1,
  12,
  12,
  6,
  0,
  0,
  11,
  1,
  3,
  11,
  0,
  11,
  12,
  9,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  11,
  5,
  12,
  5,
  15,
  3,
  1,
  1,
  13,
  13,
  3,
  15,
  12,
  12,
  2,
  9,
  6,
  0,
  6,
  6,
  12,
  1,
  3,
  12,
  12,
  12,
  12,
  5,
  12,
  6,
  2,
  10,
  2,
  2,
  9,
  12,
  6,
  5,
  12,
  11,
  5,
  12,
  9,
  9,
  2,
  9,
  2,
  9,
  2,
  9,
  12,
  3,
  13,
  5,
  11,
  5,
  1,
  1,
  5,
  12,
  11,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  12,
  5,
  3,
  3,
  3,
  3,
  3,
  5,
  12,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  11,
  12,
  11,
  12,
  11,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  10,
  15,
  3,
  3,
  1,
  7,
  1,
  1,
  1,
  3,
  1,
  15,
  11,
  11,
  4,
  9,
  11,
  11,
  8,
  12,
  5,
  1,
  1,
  7,
  1,
  3,
  1,
  1,
  1,
  12,
  6,
  0,
  0,
  0,
  11,
  3,
  3,
  11,
  0,
  9,
  9,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  5,
  5,
  12,
  5,
  15,
  1,
  1,
  1,
  13,
  13,
  3,
  14,
  2,
  2,
  9,
  6,
  0,
  0,
  6,
  12,
  1,
  3,
  12,
  14,
  12,
  14,
  12,
  5,
  12,
  2,
  2,
  2,
  2,
  9,
  12,
  11,
  5,
  11,
  12,
  5,
  12,
  9,
  2,
  9,
  2,
  2,
  2,
  9,
  2,
  4,
  12,
  13,
  13,
  3,
  5,
  13,
  1,
  12,
  12,
  12,
  6,
  6,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  5,
  5,
  3,
  3,
  3,
  5,
  12,
  12,
  11,
  12,
  15,
  12,
  15,
  12,
  12,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  12,
  11,
  11,
  11,
  12,
  15,
  15,
  15,
  15,
  10,
  15,
  10,
  15,
  3,
  3,
  7,
  7,
  1,
  7,
  15,
  3,
  15,
  12,
  11,
  6,
  6,
  9,
  6,
  9,
  11,
  12,
  12,
  12,
  1,
  1,
  1,
  3,
  3,
  3,
  1,
  12,
  6,
  0,
  0,
  0,
  6,
  11,
  14,
  14,
  11,
  0,
  11,
  9,
  9,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  5,
  12,
  5,
  12,
  15,
  1,
  1,
  1,
  13,
  3,
  3,
  12,
  2,
  2,
  9,
  6,
  0,
  6,
  6,
  11,
  3,
  3,
  14,
  12,
  14,
  14,
  12,
  5,
  5,
  2,
  2,
  2,
  9,
  8,
  12,
  6,
  5,
  12,
  11,
  5,
  5,
  9,
  9,
  2,
  4,
  2,
  4,
  2,
  9,
  4,
  12,
  13,
  3,
  5,
  5,
  3,
  1,
  5,
  12,
  11,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  12,
  5,
  3,
  5,
  3,
  3,
  3,
  5,
  12,
  12,
  15,
  12,
  12,
  12,
  12,
  15,
  12,
  12,
  12,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  15,
  11,
  12,
  11,
  11,
  11,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  10,
  15,
  3,
  3,
  1,
  3,
  3,
  12,
  12,
  12,
  12,
  12,
  11,
  6,
  4,
  9,
  11,
  11,
  8,
  12,
  5,
  7,
  1,
  7,
  12,
  12,
  12,
  12,
  6,
  2,
  0,
  0,
  0,
  11,
  11,
  14,
  12,
  11,
  0,
  9,
  9,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  5,
  5,
  12,
  5,
  5,
  3,
  1,
  1,
  13,
  13,
  3,
  12,
  2,
  9,
  9,
  6,
  0,
  0,
  6,
  11,
  14,
  3,
  12,
  14,
  14,
  14,
  14,
  12,
  13,
  12,
  2,
  9,
  8,
  12,
  12,
  11,
  12,
  11,
  6,
  12,
  5,
  12,
  2,
  4,
  2,
  4,
  2,
  4,
  2,
  9,
  12,
  3,
  12,
  12,
  5,
  3,
  13,
  12,
  12,
  12,
  6,
  6,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  5,
  5,
  3,
  3,
  3,
  5,
  12,
  12,
  11,
  12,
  12,
  12,
  15,
  12,
  15,
  12,
  12,
  12,
  12,
  12,
  11,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  11,
  12,
  11,
  11,
  11,
  15,
  15,
  15,
  10,
  15,
  10,
  15,
  3,
  3,
  7,
  3,
  3,
  12,
  7,
  7,
  7,
  15,
  12,
  12,
  6,
  9,
  6,
  9,
  11,
  12,
  12,
  12,
  15,
  15,
  10,
  12,
  12,
  2,
  2,
  2,
  6,
  0,
  0,
  6,
  6,
  11,
  12,
  12,
  6,
  0,
  9,
  9,
  11,
  6,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  12,
  5,
  5,
  5,
  12,
  5,
  15,
  3,
  13,
  13,
  3,
  3,
  15,
  9,
  9,
  9,
  0,
  0,
  0,
  0,
  11,
  11,
  14,
  12,
  14,
  3,
  4,
  14,
  12,
  13,
  5,
  12,
  8,
  12,
  12,
  12,
  6,
  12,
  11,
  6,
  12,
  5,
  5,
  2,
  2,
  4,
  4,
  4,
  2,
  9,
  2,
  12,
  3,
  12,
  11,
  12,
  5,
  3,
  5,
  12,
  11,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  12,
  5,
  5,
  5,
  3,
  5,
  3,
  5,
  12,
  12,
  15,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  12,
  12,
  11,
  12,
  12,
  12,
  15,
  12,
  15,
  12,
  11,
  11,
  11,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  10,
  15,
  3,
  15,
  3,
  12,
  7,
  1,
  7,
  7,
  7,
  15,
  12,
  11,
  4,
  6,
  11,
  11,
  8,
  12,
  12,
  12,
  10,
  10,
  12,
  6,
  2,
  2,
  6,
  2,
  6,
  6,
  6,
  11,
  11,
  12,
  11,
  6,
  0,
  9,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  12,
  5,
  5,
  12,
  5,
  12,
  15,
  15,
  3,
  13,
  13,
  15,
  12,
  9,
  9,
  6,
  0,
  0,
  0,
  6,
  6,
  11,
  11,
  14,
  14,
  14,
  14,
  14,
  11,
  5,
  13,
  5,
  12,
  12,
  12,
  14,
  9,
  12,
  6,
  6,
  11,
  12,
  5,
  12,
  4,
  4,
  4,
  4,
  4,
  2,
  9,
  12,
  5,
  5,
  11,
  11,
  5,
  5,
  12,
  12,
  12,
  6,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  12,
  5,
  3,
  5,
  3,
  5,
  12,
  12,
  11,
  12,
  12,
  12,
  15,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  12,
  11,
  12,
  11,
  11,
  12,
  15,
  12,
  11,
  12,
  11,
  11,
  11,
  10,
  15,
  15,
  10,
  15,
  10,
  15,
  3,
  15,
  3,
  15,
  7,
  7,
  7,
  1,
  7,
  3,
  7,
  12,
  6,
  9,
  6,
  9,
  11,
  12,
  12,
  12,
  10,
  12,
  10,
  10,
  6,
  2,
  2,
  2,
  6,
  6,
  6,
  11,
  6,
  11,
  12,
  6,
  0,
  6,
  11,
  9,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  12,
  5,
  5,
  5,
  12,
  5,
  5,
  15,
  15,
  13,
  3,
  3,
  15,
  6,
  6,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  11,
  11,
  14,
  3,
  4,
  14,
  9,
  12,
  13,
  13,
  5,
  12,
  12,
  11,
  6,
  11,
  6,
  11,
  6,
  12,
  5,
  5,
  4,
  4,
  4,
  4,
  2,
  4,
  2,
  9,
  12,
  3,
  11,
  11,
  12,
  5,
  5,
  12,
  12,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  11,
  12,
  12,
  5,
  5,
  5,
  3,
  5,
  3,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  12,
  11,
  11,
  11,
  11,
  11,
  12,
  15,
  12,
  11,
  11,
  11,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  3,
  15,
  7,
  7,
  7,
  1,
  7,
  7,
  7,
  3,
  12,
  11,
  6,
  6,
  11,
  11,
  8,
  12,
  12,
  12,
  10,
  10,
  10,
  6,
  6,
  2,
  6,
  2,
  6,
  11,
  6,
  11,
  11,
  11,
  6,
  0,
  6,
  9,
  9,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  5,
  3,
  5,
  12,
  5,
  12,
  5,
  5,
  3,
  3,
  13,
  15,
  12,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  11,
  14,
  3,
  14,
  4,
  11,
  11,
  5,
  13,
  5,
  12,
  12,
  11,
  9,
  11,
  6,
  6,
  11,
  11,
  12,
  5,
  12,
  4,
  4,
  4,
  4,
  2,
  9,
  12,
  12,
  13,
  11,
  11,
  12,
  5,
  5,
  12,
  12,
  6,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  12,
  5,
  3,
  5,
  3,
  5,
  12,
  5,
  12,
  12,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  11,
  6,
  11,
  11,
  11,
  12,
  11,
  12,
  11,
  11,
  11,
  10,
  15,
  15,
  15,
  15,
  10,
  15,
  3,
  15,
  7,
  3,
  7,
  1,
  7,
  1,
  7,
  7,
  7,
  12,
  9,
  4,
  6,
  11,
  11,
  12,
  8,
  12,
  10,
  12,
  10,
  10,
  2,
  6,
  6,
  2,
  6,
  6,
  6,
  11,
  11,
  11,
  6,
  0,
  6,
  11,
  2,
  9,
  11,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  11,
  5,
  5,
  5,
  5,
  12,
  12,
  12,
  5,
  15,
  3,
  3,
  3,
  15,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  6,
  14,
  1,
  4,
  14,
  9,
  11,
  12,
  5,
  5,
  12,
  11,
  9,
  6,
  9,
  6,
  11,
  6,
  11,
  12,
  12,
  5,
  12,
  4,
  4,
  4,
  4,
  2,
  9,
  12,
  13,
  11,
  11,
  11,
  12,
  5,
  5,
  12,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  11,
  12,
  12,
  5,
  12,
  5,
  3,
  5,
  3,
  5,
  12,
  5,
  12,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  11,
  11,
  6,
  6,
  11,
  11,
  12,
  11,
  11,
  11,
  11,
  12,
  10,
  15,
  15,
  15,
  15,
  3,
  15,
  3,
  3,
  7,
  7,
  7,
  1,
  7,
  1,
  7,
  3,
  12,
  11,
  9,
  6,
  9,
  11,
  11,
  12,
  12,
  12,
  10,
  10,
  10,
  2,
  6,
  6,
  6,
  2,
  6,
  11,
  6,
  11,
  6,
  6,
  0,
  11,
  11,
  9,
  9,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  12,
  5,
  3,
  5,
  5,
  5,
  12,
  5,
  12,
  5,
  15,
  3,
  3,
  3,
  6,
  0,
  0,
  6,
  0,
  0,
  6,
  0,
  6,
  6,
  11,
  14,
  3,
  14,
  4,
  9,
  11,
  11,
  12,
  5,
  12,
  11,
  9,
  9,
  11,
  6,
  6,
  11,
  11,
  11,
  12,
  12,
  5,
  12,
  4,
  4,
  2,
  2,
  12,
  12,
  3,
  11,
  11,
  11,
  12,
  5,
  5,
  12,
  6,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  11,
  12,
  12,
  5,
  12,
  5,
  12,
  5,
  12,
  5,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  11,
  11,
  6,
  6,
  6,
  11,
  11,
  6,
  6,
  6,
  6,
  11,
  15,
  15,
  15,
  15,
  10,
  15,
  3,
  3,
  7,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  7,
  12,
  9,
  6,
  6,
  11,
  11,
  6,
  8,
  12,
  10,
  12,
  10,
  10,
  10,
  6,
  0,
  6,
  6,
  6,
  6,
  11,
  6,
  6,
  0,
  6,
  11,
  2,
  9,
  11,
  11,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  12,
  3,
  5,
  5,
  5,
  12,
  12,
  12,
  5,
  15,
  3,
  3,
  13,
  15,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  14,
  1,
  4,
  14,
  9,
  11,
  12,
  11,
  12,
  12,
  11,
  6,
  11,
  9,
  6,
  11,
  6,
  11,
  11,
  11,
  12,
  5,
  5,
  12,
  4,
  4,
  9,
  9,
  12,
  5,
  12,
  11,
  6,
  11,
  5,
  5,
  12,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  11,
  12,
  11,
  12,
  12,
  12,
  12,
  5,
  12,
  5,
  12,
  5,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  6,
  11,
  4,
  6,
  6,
  11,
  6,
  6,
  0,
  0,
  6,
  11,
  10,
  15,
  15,
  15,
  15,
  3,
  3,
  3,
  3,
  7,
  1,
  7,
  1,
  7,
  1,
  7,
  3,
  12,
  11,
  6,
  6,
  11,
  4,
  6,
  12,
  8,
  12,
  10,
  12,
  10,
  10,
  2,
  6,
  0,
  6,
  6,
  6,
  6,
  6,
  0,
  6,
  11,
  11,
  2,
  9,
  11,
  11,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  5,
  5,
  3,
  5,
  5,
  5,
  12,
  12,
  12,
  5,
  15,
  3,
  3,
  3,
  6,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  6,
  6,
  11,
  14,
  1,
  14,
  4,
  9,
  9,
  11,
  12,
  11,
  12,
  11,
  6,
  9,
  11,
  6,
  6,
  11,
  11,
  11,
  11,
  11,
  12,
  3,
  12,
  12,
  2,
  2,
  12,
  12,
  5,
  5,
  11,
  11,
  6,
  12,
  5,
  12,
  6,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  11,
  12,
  11,
  12,
  11,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  5,
  11,
  6,
  12,
  4,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  12,
  15,
  15,
  15,
  15,
  15,
  3,
  3,
  7,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  7,
  12,
  9,
  11,
  6,
  11,
  4,
  6,
  8,
  12,
  12,
  12,
  10,
  12,
  10,
  2,
  2,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  11,
  2,
  9,
  9,
  11,
  11,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  5,
  3,
  5,
  5,
  5,
  12,
  5,
  12,
  5,
  15,
  3,
  3,
  13,
  3,
  11,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  0,
  6,
  6,
  14,
  1,
  4,
  14,
  4,
  9,
  9,
  11,
  12,
  11,
  9,
  6,
  11,
  9,
  6,
  6,
  6,
  11,
  11,
  2,
  11,
  12,
  3,
  3,
  12,
  12,
  9,
  9,
  12,
  12,
  13,
  11,
  6,
  6,
  12,
  3,
  12,
  11,
  6,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  11,
  12,
  11,
  12,
  11,
  12,
  11,
  12,
  11,
  12,
  15,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  5,
  6,
  11,
  12,
  12,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  5,
  15,
  15,
  3,
  3,
  3,
  3,
  7,
  1,
  7,
  1,
  1,
  1,
  7,
  3,
  12,
  11,
  6,
  6,
  11,
  14,
  6,
  11,
  8,
  12,
  10,
  12,
  10,
  10,
  10,
  2,
  2,
  6,
  6,
  6,
  6,
  6,
  2,
  2,
  11,
  2,
  9,
  11,
  11,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  6,
  5,
  5,
  3,
  5,
  5,
  5,
  12,
  12,
  12,
  5,
  15,
  3,
  3,
  13,
  12,
  0,
  6,
  0,
  6,
  0,
  6,
  0,
  6,
  6,
  11,
  14,
  1,
  14,
  4,
  4,
  14,
  14,
  4,
  11,
  12,
  11,
  11,
  11,
  11,
  6,
  6,
  11,
  6,
  11,
  11,
  11,
  11,
  12,
  3,
  3,
  12,
  2,
  12,
  12,
  12,
  3,
  11,
  11,
  6,
  11,
  3,
  5,
  12,
  11,
  6,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  11,
  11,
  11,
  12,
  11,
  12,
  11,
  12,
  11,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  7,
  11,
  6,
  11,
  11,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  11,
  12,
  10,
  15,
  15,
  15,
  3,
  3,
  7,
  7,
  7,
  1,
  1,
  1,
  7,
  7,
  7,
  12,
  11,
  6,
  6,
  11,
  14,
  6,
  6,
  12,
  8,
  12,
  10,
  12,
  10,
  10,
  10,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  11,
  2,
  2,
  9,
  11,
  14,
  11,
  0,
  6,
  0,
  0,
  0,
  0,
  6,
  5,
  3,
  5,
  5,
  5,
  5,
  5,
  12,
  12,
  12,
  3,
  3,
  13,
  3,
  15,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  0,
  6,
  6,
  10,
  1,
  4,
  4,
  10,
  14,
  4,
  4,
  12,
  11,
  9,
  11,
  2,
  11,
  11,
  6,
  6,
  11,
  11,
  2,
  2,
  11,
  11,
  12,
  13,
  3,
  12,
  9,
  12,
  12,
  13,
  12,
  6,
  6,
  6,
  5,
  13,
  11,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  11,
  6,
  11,
  11,
  11,
  11,
  12,
  11,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  7,
  6,
  11,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  11,
  6,
  6,
  6,
  12,
  5,
  5,
  15,
  15,
  3,
  3,
  3,
  7,
  1,
  1,
  1,
  1,
  1,
  7,
  3,
  12,
  11,
  6,
  6,
  11,
  14,
  4,
  6,
  8,
  12,
  12,
  12,
  10,
  10,
  10,
  10,
  10,
  2,
  2,
  2,
  2,
  2,
  11,
  11,
  2,
  2,
  9,
  11,
  11,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  11,
  3,
  5,
  3,
  5,
  5,
  5,
  12,
  5,
  12,
  5,
  15,
  3,
  3,
  13,
  12,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  6,
  6,
  11,
  14,
  1,
  14,
  4,
  10,
  14,
  4,
  4,
  11,
  12,
  11,
  11,
  2,
  2,
  2,
  6,
  6,
  6,
  11,
  11,
  10,
  11,
  11,
  12,
  12,
  13,
  3,
  12,
  12,
  12,
  13,
  12,
  11,
  6,
  6,
  12,
  3,
  12,
  5,
  12,
  5,
  5,
  12,
  5,
  5,
  5,
  13,
  12,
  11,
  6,
  11,
  6,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  11,
  6,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  11,
  6,
  6,
  11,
  12,
  5,
  5,
  15,
  15,
  3,
  3,
  3,
  7,
  1,
  1,
  1,
  7,
  3,
  12,
  11,
  11,
  6,
  6,
  9,
  4,
  14,
  6,
  11,
  11,
  12,
  10,
  12,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  11,
  2,
  2,
  9,
  11,
  11,
  14,
  11,
  0,
  11,
  0,
  0,
  0,
  0,
  12,
  5,
  3,
  5,
  3,
  5,
  5,
  5,
  12,
  12,
  12,
  15,
  13,
  3,
  13,
  15,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  9,
  9,
  9,
  9,
  9,
  4,
  4,
  4,
  9,
  11,
  9,
  11,
  2,
  4,
  2,
  11,
  6,
  6,
  6,
  11,
  10,
  10,
  11,
  11,
  12,
  3,
  13,
  3,
  12,
  12,
  13,
  5,
  12,
  11,
  6,
  12,
  13,
  12,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  13,
  13,
  12,
  11,
  6,
  11,
  6,
  11,
  6,
  11,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  6,
  6,
  12,
  15,
  15,
  12,
  15,
  12,
  15,
  12,
  12,
  12,
  6,
  6,
  11,
  12,
  5,
  5,
  15,
  15,
  15,
  3,
  3,
  7,
  1,
  7,
  3,
  12,
  11,
  11,
  6,
  6,
  9,
  6,
  9,
  14,
  6,
  6,
  11,
  8,
  12,
  10,
  10,
  10,
  15,
  15,
  15,
  10,
  10,
  10,
  12,
  11,
  2,
  9,
  9,
  11,
  14,
  11,
  6,
  0,
  6,
  0,
  0,
  0,
  6,
  12,
  3,
  5,
  3,
  5,
  5,
  5,
  5,
  5,
  12,
  12,
  15,
  3,
  13,
  13,
  12,
  6,
  6,
  6,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  2,
  2,
  2,
  9,
  9,
  4,
  11,
  11,
  11,
  4,
  4,
  2,
  4,
  2,
  11,
  6,
  6,
  6,
  2,
  10,
  10,
  11,
  12,
  12,
  3,
  13,
  13,
  3,
  13,
  5,
  5,
  12,
  11,
  12,
  13,
  12,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  13,
  1,
  13,
  12,
  11,
  6,
  11,
  6,
  11,
  6,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  6,
  6,
  12,
  15,
  15,
  15,
  12,
  15,
  12,
  15,
  12,
  12,
  6,
  11,
  12,
  5,
  5,
  15,
  15,
  3,
  3,
  3,
  7,
  1,
  1,
  1,
  7,
  3,
  12,
  11,
  11,
  6,
  6,
  6,
  11,
  4,
  4,
  6,
  6,
  11,
  12,
  12,
  10,
  15,
  1,
  1,
  1,
  15,
  10,
  10,
  11,
  11,
  2,
  9,
  9,
  11,
  14,
  14,
  11,
  0,
  11,
  0,
  0,
  0,
  11,
  5,
  5,
  3,
  5,
  3,
  5,
  5,
  5,
  12,
  5,
  12,
  15,
  13,
  13,
  1,
  15,
  6,
  6,
  9,
  9,
  9,
  2,
  9,
  2,
  2,
  2,
  9,
  2,
  9,
  2,
  2,
  2,
  9,
  9,
  4,
  11,
  9,
  4,
  4,
  4,
  2,
  4,
  11,
  11,
  6,
  6,
  6,
  12,
  7,
  10,
  11,
  12,
  12,
  3,
  13,
  13,
  13,
  12,
  12,
  5,
  5,
  12,
  13,
  12,
  5,
  5,
  5,
  5,
  5,
  12,
  5,
  5,
  13,
  1,
  1,
  13,
  12,
  6,
  6,
  11,
  6,
  11,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  6,
  6,
  12,
  15,
  15,
  15,
  15,
  12,
  15,
  12,
  15,
  12,
  6,
  12,
  5,
  5,
  15,
  15,
  3,
  3,
  3,
  7,
  7,
  1,
  1,
  1,
  1,
  7,
  3,
  12,
  11,
  6,
  9,
  6,
  9,
  11,
  14,
  6,
  6,
  11,
  8,
  10,
  10,
  10,
  15,
  1,
  15,
  10,
  10,
  12,
  11,
  2,
  2,
  9,
  9,
  11,
  14,
  11,
  6,
  0,
  6,
  0,
  0,
  0,
  12,
  5,
  3,
  3,
  3,
  5,
  3,
  5,
  5,
  5,
  12,
  11,
  15,
  15,
  1,
  1,
  13,
  12,
  6,
  6,
  9,
  9,
  9,
  2,
  9,
  2,
  9,
  2,
  9,
  2,
  2,
  2,
  4,
  2,
  9,
  4,
  11,
  11,
  4,
  8,
  4,
  4,
  4,
  2,
  11,
  11,
  6,
  6,
  10,
  7,
  10,
  11,
  11,
  12,
  5,
  3,
  13,
  13,
  12,
  12,
  12,
  5,
  5,
  13,
  5,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  6,
  6,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  12,
  5,
  15,
  5,
  15,
  3,
  3,
  7,
  3,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  15,
  11,
  6,
  6,
  6,
  11,
  6,
  4,
  6,
  6,
  6,
  11,
  12,
  10,
  10,
  10,
  10,
  10,
  10,
  12,
  11,
  11,
  2,
  9,
  9,
  9,
  14,
  14,
  14,
  0,
  0,
  11,
  0,
  0,
  0,
  12,
  3,
  3,
  3,
  3,
  3,
  5,
  3,
  5,
  12,
  5,
  12,
  12,
  15,
  13,
  1,
  13,
  12,
  8,
  6,
  6,
  9,
  9,
  9,
  2,
  9,
  2,
  9,
  2,
  2,
  2,
  4,
  10,
  4,
  9,
  9,
  4,
  11,
  4,
  4,
  8,
  4,
  4,
  4,
  2,
  11,
  11,
  6,
  6,
  7,
  7,
  10,
  11,
  11,
  12,
  3,
  3,
  13,
  12,
  11,
  9,
  12,
  5,
  3,
  3,
  11,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  6,
  6,
  9,
  9,
  9,
  9,
  9,
  11,
  9,
  11,
  11,
  11,
  6,
  12,
  5,
  5,
  15,
  3,
  3,
  3,
  3,
  7,
  7,
  1,
  1,
  1,
  1,
  7,
  3,
  15,
  12,
  6,
  9,
  6,
  9,
  11,
  6,
  4,
  6,
  6,
  6,
  11,
  8,
  12,
  10,
  10,
  10,
  12,
  11,
  11,
  2,
  2,
  9,
  6,
  9,
  12,
  14,
  11,
  0,
  0,
  6,
  0,
  0,
  6,
  12,
  3,
  3,
  3,
  3,
  3,
  3,
  5,
  3,
  5,
  12,
  11,
  12,
  12,
  15,
  13,
  13,
  13,
  10,
  6,
  6,
  6,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  2,
  2,
  4,
  1,
  4,
  2,
  9,
  4,
  4,
  4,
  8,
  4,
  8,
  4,
  4,
  4,
  2,
  11,
  11,
  6,
  12,
  7,
  10,
  4,
  11,
  12,
  5,
  3,
  3,
  12,
  9,
  6,
  9,
  12,
  5,
  3,
  12,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  1,
  6,
  6,
  9,
  9,
  9,
  9,
  11,
  9,
  11,
  11,
  9,
  11,
  6,
  12,
  5,
  15,
  5,
  15,
  3,
  3,
  7,
  3,
  7,
  1,
  1,
  1,
  1,
  7,
  7,
  3,
  12,
  6,
  11,
  9,
  6,
  9,
  11,
  6,
  9,
  6,
  6,
  6,
  6,
  11,
  11,
  11,
  11,
  6,
  6,
  11,
  2,
  2,
  9,
  9,
  9,
  14,
  14,
  14,
  0,
  0,
  11,
  0,
  0,
  11,
  11,
  5,
  3,
  3,
  3,
  3,
  5,
  3,
  5,
  5,
  5,
  12,
  11,
  12,
  10,
  15,
  13,
  13,
  15,
  8,
  6,
  6,
  6,
  9,
  6,
  9,
  6,
  6,
  9,
  2,
  2,
  4,
  10,
  4,
  2,
  9,
  4,
  4,
  11,
  4,
  8,
  4,
  8,
  4,
  4,
  9,
  9,
  9,
  6,
  6,
  12,
  10,
  10,
  11,
  11,
  5,
  3,
  3,
  5,
  9,
  6,
  6,
  9,
  12,
  3,
  5,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  7,
  6,
  6,
  9,
  9,
  9,
  11,
  9,
  11,
  11,
  11,
  11,
  11,
  11,
  12,
  5,
  5,
  15,
  5,
  15,
  3,
  3,
  7,
  7,
  7,
  1,
  1,
  7,
  7,
  3,
  7,
  15,
  6,
  2,
  6,
  9,
  11,
  9,
  11,
  6,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  11,
  2,
  2,
  9,
  9,
  6,
  11,
  12,
  14,
  6,
  0,
  0,
  11,
  0,
  6,
  11,
  5,
  12,
  5,
  3,
  3,
  3,
  3,
  5,
  3,
  5,
  12,
  11,
  12,
  11,
  12,
  10,
  15,
  15,
  1,
  15,
  8,
  6,
  6,
  6,
  6,
  6,
  0,
  6,
  6,
  9,
  2,
  2,
  4,
  2,
  2,
  6,
  4,
  4,
  4,
  8,
  8,
  8,
  8,
  8,
  9,
  9,
  9,
  9,
  9,
  6,
  6,
  4,
  10,
  4,
  11,
  5,
  5,
  5,
  5,
  12,
  9,
  6,
  6,
  9,
  5,
  12,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  7,
  6,
  9,
  9,
  9,
  11,
  9,
  11,
  11,
  9,
  11,
  11,
  11,
  6,
  11,
  5,
  15,
  5,
  15,
  3,
  3,
  3,
  3,
  7,
  7,
  7,
  7,
  7,
  7,
  7,
  3,
  15,
  6,
  11,
  9,
  6,
  9,
  11,
  2,
  11,
  11,
  11,
  11,
  6,
  6,
  6,
  6,
  11,
  11,
  2,
  2,
  2,
  9,
  6,
  9,
  12,
  14,
  14,
  0,
  0,
  6,
  11,
  0,
  11,
  12,
  3,
  5,
  12,
  5,
  5,
  5,
  3,
  3,
  5,
  3,
  5,
  14,
  11,
  12,
  12,
  12,
  10,
  15,
  15,
  13,
  15,
  15,
  12,
  12,
  12,
  4,
  6,
  6,
  0,
  6,
  9,
  2,
  2,
  2,
  2,
  9,
  4,
  4,
  4,
  4,
  8,
  8,
  12,
  9,
  9,
  9,
  2,
  2,
  8,
  8,
  6,
  6,
  4,
  4,
  5,
  12,
  12,
  12,
  5,
  5,
  12,
  9,
  6,
  6,
  5,
  5,
  12,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  5,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  6,
  12,
  5,
  15,
  5,
  15,
  3,
  3,
  7,
  3,
  7,
  7,
  7,
  7,
  7,
  3,
  7,
  3,
  6,
  2,
  11,
  6,
  11,
  2,
  11,
  2,
  2,
  11,
  11,
  11,
  11,
  11,
  11,
  11,
  2,
  2,
  8,
  2,
  9,
  6,
  11,
  12,
  12,
  14,
  0,
  0,
  11,
  12,
  0,
  12,
  5,
  3,
  3,
  8,
  12,
  10,
  10,
  5,
  3,
  3,
  5,
  12,
  14,
  12,
  11,
  12,
  11,
  12,
  10,
  10,
  15,
  1,
  1,
  15,
  10,
  12,
  12,
  4,
  6,
  6,
  0,
  6,
  9,
  2,
  2,
  9,
  6,
  4,
  4,
  2,
  9,
  8,
  8,
  8,
  9,
  9,
  4,
  4,
  2,
  2,
  5,
  6,
  6,
  6,
  4,
  5,
  2,
  2,
  2,
  12,
  5,
  3,
  12,
  9,
  6,
  12,
  5,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  5,
  6,
  11,
  12,
  12,
  11,
  4,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  12,
  11,
  5,
  5,
  15,
  3,
  3,
  3,
  3,
  7,
  3,
  7,
  7,
  7,
  3,
  7,
  3,
  3,
  6,
  2,
  2,
  11,
  9,
  11,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  8,
  8,
  2,
  9,
  9,
  12,
  12,
  14,
  11,
  0,
  0,
  11,
  12,
  6,
  12,
  3,
  3,
  5,
  10,
  11,
  12,
  10,
  10,
  5,
  3,
  3,
  5,
  14,
  14,
  12,
  11,
  12,
  12,
  12,
  10,
  10,
  15,
  15,
  15,
  15,
  10,
  12,
  12,
  4,
  6,
  6,
  0,
  6,
  9,
  9,
  6,
  4,
  4,
  2,
  10,
  2,
  9,
  8,
  12,
  9,
  4,
  4,
  10,
  2,
  2,
  8,
  5,
  6,
  6,
  5,
  12,
  6,
  11,
  6,
  6,
  12,
  5,
  1,
  12,
  9,
  12,
  5,
  12,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  11,
  6,
  12,
  4,
  4,
  11,
  6,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  12,
  11,
  5,
  5,
  15,
  3,
  3,
  3,
  3,
  7,
  3,
  7,
  3,
  7,
  3,
  7,
  3,
  6,
  2,
  2,
  2,
  11,
  2,
  2,
  8,
  8,
  2,
  2,
  2,
  2,
  2,
  2,
  8,
  8,
  8,
  2,
  2,
  6,
  11,
  12,
  15,
  12,
  6,
  0,
  6,
  12,
  11,
  11,
  5,
  3,
  5,
  10,
  10,
  12,
  6,
  14,
  10,
  10,
  5,
  5,
  3,
  14,
  14,
  4,
  12,
  4,
  12,
  12,
  10,
  10,
  10,
  10,
  10,
  10,
  12,
  12,
  4,
  9,
  6,
  6,
  6,
  0,
  6,
  6,
  0,
  4,
  4,
  2,
  1,
  2,
  9,
  8,
  8,
  9,
  4,
  4,
  1,
  2,
  2,
  9,
  1,
  6,
  11,
  12,
  6,
  11,
  8,
  11,
  6,
  6,
  12,
  3,
  1,
  12,
  12,
  5,
  12,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  6,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  6,
  11,
  12,
  12,
  11,
  4,
  11,
  6,
  0,
  0,
  6,
  11,
  11,
  12,
  5,
  15,
  12,
  5,
  5,
  15,
  3,
  3,
  3,
  3,
  7,
  3,
  7,
  3,
  7,
  7,
  12,
  6,
  9,
  2,
  2,
  2,
  2,
  10,
  10,
  10,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  8,
  2,
  9,
  9,
  12,
  15,
  12,
  14,
  0,
  0,
  11,
  5,
  11,
  12,
  3,
  3,
  10,
  10,
  12,
  8,
  6,
  6,
  14,
  10,
  10,
  5,
  5,
  10,
  14,
  14,
  4,
  12,
  4,
  12,
  12,
  10,
  10,
  10,
  10,
  12,
  12,
  4,
  9,
  4,
  9,
  6,
  6,
  6,
  0,
  0,
  4,
  4,
  4,
  2,
  10,
  2,
  9,
  8,
  8,
  9,
  4,
  4,
  10,
  2,
  2,
  9,
  13,
  11,
  2,
  6,
  6,
  11,
  12,
  8,
  11,
  6,
  6,
  3,
  1,
  3,
  12,
  5,
  5,
  11,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  6,
  11,
  6,
  11,
  6,
  6,
  6,
  6,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  12,
  11,
  11,
  12,
  12,
  4,
  4,
  11,
  11,
  6,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  5,
  15,
  12,
  5,
  3,
  3,
  3,
  3,
  3,
  3,
  7,
  3,
  7,
  7,
  3,
  11,
  6,
  6,
  2,
  2,
  2,
  8,
  10,
  10,
  7,
  1,
  1,
  1,
  7,
  10,
  10,
  8,
  8,
  2,
  2,
  6,
  11,
  12,
  15,
  14,
  11,
  0,
  6,
  11,
  5,
  6,
  5,
  3,
  5,
  10,
  12,
  8,
  6,
  6,
  0,
  6,
  14,
  10,
  10,
  3,
  5,
  10,
  14,
  14,
  4,
  12,
  4,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  9,
  4,
  9,
  8,
  8,
  4,
  6,
  6,
  9,
  9,
  4,
  14,
  4,
  2,
  9,
  8,
  8,
  2,
  9,
  2,
  4,
  4,
  2,
  2,
  9,
  5,
  12,
  6,
  6,
  6,
  11,
  8,
  12,
  11,
  6,
  6,
  12,
  1,
  1,
  12,
  3,
  12,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  6,
  11,
  6,
  11,
  6,
  11,
  6,
  11,
  6,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  15,
  15,
  12,
  11,
  12,
  12,
  12,
  12,
  11,
  4,
  11,
  11,
  6,
  6,
  11,
  11,
  11,
  11,
  12,
  15,
  5,
  15,
  12,
  5,
  3,
  3,
  15,
  3,
  7,
  1,
  1,
  1,
  5,
  12,
  12,
  12,
  6,
  9,
  2,
  2,
  8,
  8,
  10,
  10,
  7,
  7,
  7,
  10,
  10,
  8,
  8,
  2,
  2,
  9,
  9,
  12,
  15,
  12,
  11,
  6,
  0,
  11,
  12,
  12,
  11,
  3,
  3,
  10,
  12,
  8,
  6,
  6,
  6,
  0,
  6,
  6,
  14,
  10,
  12,
  3,
  10,
  10,
  14,
  12,
  4,
  12,
  12,
  15,
  1,
  15,
  12,
  4,
  9,
  4,
  9,
  4,
  8,
  12,
  8,
  4,
  4,
  9,
  4,
  9,
  4,
  14,
  14,
  14,
  8,
  2,
  2,
  9,
  2,
  2,
  2,
  2,
  2,
  9,
  8,
  5,
  12,
  6,
  6,
  11,
  12,
  8,
  8,
  11,
  6,
  11,
  3,
  1,
  3,
  3,
  5,
  11,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  11,
  11,
  6,
  11,
  6,
  11,
  6,
  11,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  12,
  12,
  11,
  12,
  12,
  4,
  12,
  4,
  11,
  11,
  11,
  11,
  6,
  11,
  11,
  12,
  11,
  11,
  5,
  15,
  5,
  15,
  12,
  5,
  3,
  7,
  7,
  1,
  1,
  7,
  5,
  12,
  5,
  3,
  12,
  6,
  6,
  9,
  2,
  2,
  8,
  8,
  8,
  8,
  10,
  10,
  8,
  8,
  2,
  2,
  2,
  2,
  6,
  11,
  15,
  15,
  14,
  6,
  0,
  0,
  11,
  5,
  11,
  12,
  3,
  5,
  10,
  8,
  6,
  6,
  6,
  0,
  0,
  6,
  6,
  6,
  14,
  10,
  3,
  3,
  10,
  10,
  14,
  12,
  4,
  9,
  9,
  9,
  9,
  9,
  9,
  4,
  9,
  4,
  8,
  12,
  10,
  12,
  8,
  4,
  4,
  9,
  4,
  14,
  4,
  14,
  8,
  8,
  8,
  9,
  6,
  9,
  2,
  2,
  2,
  9,
  9,
  8,
  5,
  5,
  5,
  6,
  11,
  8,
  12,
  8,
  11,
  6,
  6,
  5,
  1,
  3,
  13,
  12,
  11,
  6,
  11,
  6,
  6,
  6,
  11,
  11,
  4,
  11,
  11,
  6,
  11,
  6,
  11,
  6,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  14,
  12,
  12,
  12,
  4,
  12,
  11,
  4,
  11,
  11,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  11,
  5,
  15,
  5,
  15,
  12,
  12,
  5,
  15,
  15,
  5,
  12,
  12,
  5,
  3,
  5,
  12,
  12,
  6,
  6,
  9,
  2,
  2,
  2,
  8,
  8,
  8,
  8,
  2,
  2,
  2,
  2,
  2,
  9,
  9,
  12,
  3,
  15,
  6,
  6,
  0,
  6,
  12,
  12,
  6,
  5,
  3,
  5,
  8,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  10,
  5,
  3,
  5,
  5,
  5,
  4,
  9,
  4,
  4,
  4,
  4,
  4,
  4,
  9,
  4,
  4,
  8,
  10,
  10,
  10,
  12,
  4,
  4,
  4,
  9,
  4,
  14,
  14,
  14,
  8,
  2,
  2,
  6,
  9,
  2,
  2,
  9,
  9,
  9,
  8,
  5,
  13,
  5,
  12,
  11,
  12,
  8,
  12,
  8,
  11,
  6,
  12,
  3,
  1,
  3,
  5,
  8,
  11,
  6,
  6,
  6,
  11,
  8,
  8,
  2,
  4,
  11,
  11,
  11,
  11,
  6,
  11,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  11,
  4,
  11,
  11,
  6,
  11,
  11,
  12,
  11,
  12,
  5,
  12,
  5,
  15,
  5,
  15,
  5,
  12,
  12,
  12,
  12,
  5,
  3,
  3,
  3,
  12,
  5,
  12,
  12,
  6,
  6,
  9,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  2,
  9,
  11,
  11,
  12,
  15,
  15,
  6,
  0,
  6,
  11,
  12,
  11,
  11,
  3,
  10,
  12,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  10,
  10,
  5,
  5,
  3,
  12,
  9,
  4,
  4,
  4,
  14,
  14,
  14,
  4,
  4,
  9,
  4,
  8,
  10,
  10,
  10,
  10,
  8,
  4,
  4,
  4,
  4,
  4,
  14,
  8,
  8,
  8,
  9,
  6,
  6,
  9,
  9,
  9,
  9,
  6,
  12,
  15,
  13,
  13,
  15,
  11,
  12,
  12,
  8,
  8,
  11,
  6,
  11,
  5,
  1,
  13,
  5,
  12,
  11,
  11,
  6,
  6,
  8,
  12,
  5,
  12,
  2,
  4,
  11,
  4,
  11,
  11,
  6,
  12,
  12,
  12,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  11,
  11,
  6,
  11,
  6,
  11,
  11,
  12,
  5,
  12,
  5,
  12,
  5,
  15,
  5,
  15,
  3,
  3,
  3,
  15,
  15,
  3,
  7,
  5,
  12,
  3,
  15,
  12,
  12,
  6,
  6,
  9,
  9,
  2,
  2,
  2,
  2,
  2,
  2,
  9,
  9,
  11,
  11,
  12,
  3,
  15,
  14,
  6,
  6,
  6,
  12,
  5,
  6,
  12,
  5,
  10,
  8,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  14,
  10,
  3,
  1,
  12,
  4,
  14,
  4,
  4,
  4,
  4,
  14,
  14,
  14,
  14,
  4,
  4,
  8,
  12,
  10,
  10,
  10,
  10,
  8,
  4,
  9,
  4,
  14,
  4,
  14,
  8,
  2,
  2,
  6,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  15,
  15,
  13,
  13,
  12,
  12,
  12,
  12,
  8,
  8,
  9,
  6,
  12,
  1,
  3,
  3,
  8,
  11,
  6,
  11,
  6,
  8,
  10,
  12,
  5,
  2,
  4,
  4,
  11,
  4,
  11,
  11,
  12,
  12,
  12,
  12,
  14,
  12,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  11,
  4,
  11,
  11,
  6,
  11,
  6,
  11,
  11,
  12,
  5,
  15,
  5,
  12,
  5,
  15,
  5,
  15,
  3,
  3,
  15,
  3,
  1,
  3,
  12,
  5,
  15,
  3,
  15,
  12,
  3,
  12,
  6,
  6,
  6,
  6,
  11,
  6,
  6,
  11,
  6,
  11,
  11,
  3,
  3,
  3,
  14,
  6,
  11,
  6,
  6,
  5,
  12,
  11,
  3,
  10,
  12,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  12,
  12,
  4,
  4,
  12,
  10,
  14,
  4,
  4,
  4,
  4,
  14,
  15,
  14,
  14,
  4,
  4,
  8,
  12,
  10,
  10,
  12,
  8,
  8,
  4,
  4,
  4,
  14,
  8,
  8,
  8,
  9,
  6,
  6,
  6,
  12,
  15,
  15,
  15,
  15,
  15,
  15,
  13,
  13,
  5,
  8,
  12,
  8,
  12,
  8,
  11,
  6,
  12,
  13,
  13,
  3,
  12,
  11,
  11,
  6,
  6,
  8,
  1,
  12,
  5,
  2,
  4,
  4,
  4,
  4,
  4,
  11,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  11,
  11,
  11,
  11,
  6,
  11,
  11,
  12,
  11,
  12,
  5,
  15,
  5,
  12,
  5,
  15,
  3,
  3,
  3,
  3,
  1,
  3,
  5,
  12,
  15,
  3,
  15,
  3,
  12,
  3,
  13,
  3,
  15,
  10,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  3,
  3,
  3,
  15,
  14,
  11,
  12,
  6,
  6,
  3,
  11,
  12,
  15,
  10,
  6,
  6,
  6,
  0,
  0,
  0,
  6,
  6,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  4,
  12,
  10,
  10,
  10,
  14,
  4,
  4,
  4,
  4,
  14,
  14,
  15,
  14,
  14,
  4,
  8,
  8,
  10,
  10,
  10,
  12,
  4,
  8,
  4,
  4,
  4,
  8,
  8,
  2,
  2,
  6,
  6,
  12,
  7,
  1,
  1,
  7,
  1,
  7,
  15,
  12,
  13,
  13,
  12,
  12,
  12,
  8,
  8,
  11,
  6,
  11,
  3,
  13,
  13,
  5,
  11,
  11,
  11,
  6,
  8,
  10,
  12,
  12,
  2,
  4,
  4,
  4,
  4,
  4,
  4,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  4,
  4,
  11,
  11,
  6,
  11,
  6,
  11,
  11,
  12,
  5,
  12,
  5,
  15,
  5,
  12,
  5,
  15,
  3,
  15,
  7,
  3,
  5,
  12,
  5,
  3,
  15,
  3,
  15,
  15,
  12,
  3,
  13,
  1,
  1,
  1,
  1,
  1,
  13,
  13,
  13,
  13,
  13,
  13,
  3,
  12,
  6,
  12,
  6,
  6,
  12,
  5,
  6,
  5,
  15,
  12,
  6,
  6,
  6,
  0,
  0,
  6,
  9,
  9,
  9,
  4,
  4,
  4,
  4,
  8,
  4,
  10,
  10,
  5,
  5,
  10,
  10,
  14,
  4,
  4,
  4,
  4,
  14,
  14,
  15,
  14,
  4,
  4,
  8,
  12,
  10,
  12,
  10,
  8,
  4,
  4,
  4,
  8,
  8,
  8,
  2,
  11,
  11,
  11,
  6,
  7,
  1,
  1,
  1,
  7,
  7,
  7,
  15,
  12,
  5,
  13,
  8,
  12,
  12,
  8,
  11,
  6,
  11,
  5,
  13,
  13,
  13,
  12,
  11,
  6,
  11,
  2,
  12,
  12,
  8,
  2,
  4,
  4,
  4,
  4,
  4,
  4,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  14,
  12,
  12,
  12,
  14,
  12,
  12,
  12,
  4,
  12,
  4,
  12,
  11,
  4,
  11,
  11,
  6,
  11,
  6,
  11,
  11,
  12,
  5,
  15,
  5,
  15,
  5,
  12,
  5,
  15,
  15,
  15,
  5,
  12,
  5,
  15,
  15,
  3,
  15,
  3,
  15,
  15,
  12,
  3,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  13,
  13,
  13,
  15,
  14,
  12,
  12,
  6,
  6,
  3,
  12,
  12,
  3,
  10,
  8,
  6,
  6,
  0,
  0,
  0,
  9,
  9,
  9,
  4,
  4,
  8,
  4,
  4,
  4,
  8,
  8,
  10,
  5,
  5,
  10,
  5,
  14,
  14,
  4,
  4,
  4,
  14,
  15,
  14,
  15,
  14,
  4,
  8,
  8,
  12,
  10,
  12,
  8,
  8,
  4,
  4,
  4,
  8,
  4,
  11,
  11,
  12,
  11,
  11,
  12,
  7,
  1,
  7,
  1,
  7,
  15,
  15,
  12,
  12,
  5,
  12,
  12,
  8,
  12,
  8,
  9,
  6,
  12,
  13,
  13,
  13,
  5,
  11,
  11,
  6,
  11,
  2,
  2,
  2,
  11,
  11,
  4,
  4,
  4,
  4,
  4,
  14,
  12,
  12,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  4,
  4,
  4,
  11,
  4,
  11,
  11,
  6,
  11,
  11,
  12,
  11,
  12,
  5,
  15,
  15,
  15,
  5,
  12,
  5,
  5,
  12,
  12,
  5,
  3,
  15,
  5,
  15,
  3,
  15,
  3,
  15,
  15,
  12,
  3,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  13,
  3,
  12,
  14,
  15,
  6,
  6,
  12,
  3,
  11,
  15,
  15,
  10,
  9,
  6,
  6,
  0,
  6,
  9,
  6,
  9,
  4,
  4,
  8,
  4,
  8,
  4,
  4,
  4,
  8,
  10,
  10,
  5,
  5,
  10,
  14,
  4,
  14,
  4,
  4,
  4,
  14,
  15,
  15,
  14,
  4,
  4,
  8,
  10,
  12,
  10,
  8,
  4,
  4,
  4,
  8,
  12,
  8,
  11,
  11,
  12,
  12,
  11,
  6,
  7,
  7,
  1,
  7,
  7,
  7,
  15,
  15,
  4,
  12,
  5,
  8,
  12,
  8,
  8,
  11,
  6,
  11,
  13,
  13,
  13,
  13,
  8,
  11,
  11,
  6,
  11,
  11,
  11,
  11,
  4,
  11,
  4,
  4,
  4,
  4,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  4,
  12,
  12,
  4,
  12,
  4,
  4,
  11,
  11,
  6,
  11,
  6,
  11,
  11,
  12,
  11,
  12,
  5,
  5,
  3,
  15,
  5,
  12,
  12,
  5,
  15,
  3,
  3,
  3,
  15,
  12,
  15,
  3,
  15,
  3,
  3,
  3,
  12,
  3,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  3,
  12,
  12,
  15,
  12,
  6,
  6,
  3,
  5,
  11,
  3,
  5,
  12,
  6,
  6,
  0,
  6,
  9,
  9,
  9,
  6,
  4,
  8,
  4,
  8,
  4,
  8,
  4,
  4,
  4,
  8,
  10,
  15,
  5,
  5,
  10,
  14,
  4,
  4,
  4,
  4,
  14,
  15,
  15,
  15,
  14,
  4,
  4,
  12,
  10,
  8,
  4,
  8,
  4,
  8,
  4,
  8,
  4,
  11,
  12,
  15,
  12,
  12,
  11,
  12,
  1,
  7,
  1,
  7,
  15,
  7,
  12,
  4,
  12,
  5,
  12,
  8,
  12,
  8,
  11,
  6,
  11,
  13,
  13,
  13,
  13,
  12,
  11,
  11,
  11,
  6,
  6,
  6,
  11,
  11,
  4,
  4,
  4,
  4,
  4,
  14,
  12,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  14,
  12,
  14,
  4,
  12,
  4,
  12,
  4,
  4,
  4,
  11,
  4,
  11,
  11,
  6,
  11,
  6,
  11,
  11,
  11,
  11,
  11,
  12,
  5,
  3,
  3,
  5,
  12,
  12,
  15,
  15,
  3,
  3,
  3,
  15,
  12,
  15,
  3,
  3,
  3,
  3,
  15,
  6,
  6,
  12,
  3,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  6,
  6,
  12,
  3,
  12,
  12,
  3,
  10,
  8,
  6,
  6,
  6,
  9,
  9,
  4,
  8,
  6,
  9,
  4,
  4,
  4,
  8,
  4,
  8,
  4,
  8,
  10,
  10,
  15,
  5,
  10,
  10,
  14,
  14,
  4,
  4,
  4,
  4,
  14,
  15,
  3,
  15,
  4,
  4,
  12,
  8,
  4,
  8,
  4,
  4,
  4,
  8,
  12,
  8,
  11,
  12,
  15,
  15,
  12,
  11,
  6,
  7,
  1,
  7,
  7,
  7,
  15,
  15,
  4,
  4,
  12,
  5,
  11,
  8,
  12,
  8,
  9,
  11,
  13,
  13,
  13,
  13,
  12,
  8,
  11,
  6,
  11,
  6,
  6,
  6,
  11,
  11,
  4,
  11,
  4,
  4,
  12,
  14,
  12,
  12,
  14,
  12,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  4,
  12,
  12,
  4,
  4,
  11,
  4,
  11,
  4,
  11,
  11,
  6,
  11,
  6,
  11,
  11,
  11,
  11,
  11,
  12,
  5,
  3,
  3,
  5,
  12,
  5,
  15,
  3,
  3,
  3,
  3,
  15,
  12,
  15,
  3,
  13,
  3,
  3,
  12,
  6,
  0,
  6,
  6,
  12,
  15,
  15,
  15,
  12,
  6,
  6,
  6,
  12,
  3,
  5,
  11,
  5,
  3,
  5,
  12,
  6,
  6,
  9,
  9,
  4,
  8,
  4,
  9,
  6,
  9,
  4,
  8,
  4,
  8,
  4,
  8,
  4,
  8,
  10,
  5,
  15,
  5,
  10,
  14,
  4,
  14,
  4,
  4,
  4,
  14,
  15,
  1,
  3,
  4,
  4,
  8,
  4,
  8,
  12,
  8,
  4,
  8,
  12,
  8,
  4,
  11,
  12,
  13,
  15,
  12,
  11,
  11,
  12,
  7,
  7,
  7,
  15,
  7,
  15,
  12,
  4,
  12,
  5,
  9,
  12,
  8,
  8,
  6,
  11,
  12,
  13,
  13,
  13,
  5,
  12,
  11,
  11,
  6,
  11,
  6,
  11,
  6,
  11,
  11,
  4,
  11,
  4,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  4,
  4,
  4,
  11,
  4,
  11,
  11,
  11,
  11,
  6,
  11,
  6,
  11,
  11,
  12,
  5,
  11,
  12,
  5,
  3,
  3,
  5,
  12,
  15,
  15,
  3,
  3,
  3,
  15,
  12,
  5,
  3,
  3,
  13,
  3,
  3,
  12,
  6,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  3,
  3,
  12,
  12,
  3,
  15,
  12,
  8,
  6,
  6,
  9,
  4,
  4,
  4,
  8,
  9,
  0,
  4,
  4,
  12,
  8,
  8,
  8,
  4,
  8,
  4,
  10,
  5,
  15,
  15,
  5,
  10,
  14,
  4,
  4,
  4,
  4,
  4,
  12,
  1,
  3,
  12,
  4,
  4,
  8,
  12,
  12,
  12,
  8,
  4,
  8,
  12,
  4,
  11,
  12,
  1,
  13,
  15,
  12,
  11,
  6,
  7,
  7,
  7,
  7,
  15,
  15,
  12,
  12,
  12,
  5,
  6,
  8,
  12,
  8,
  11,
  6,
  11,
  1,
  13,
  13,
  13,
  5,
  8,
  11,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  11,
  4,
  11,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  4,
  12,
  4,
  11,
  4,
  11,
  11,
  11,
  6,
  6,
  11,
  6,
  11,
  6,
  11,
  12,
  5,
  5,
  11,
  12,
  5,
  3,
  3,
  5,
  5,
  15,
  3,
  3,
  3,
  3,
  15,
  12,
  15,
  13,
  3,
  13,
  3,
  3,
  12,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  12,
  12,
  3,
  5,
  3,
  5,
  3,
  15,
  5,
  11,
  6,
  9,
  9,
  4,
  4,
  4,
  4,
  9,
  6,
  9,
  4,
  8,
  8,
  12,
  8,
  8,
  4,
  8,
  10,
  10,
  15,
  13,
  10,
  14,
  4,
  14,
  4,
  4,
  4,
  12,
  4,
  15,
  15,
  12,
  4,
  8,
  4,
  8,
  12,
  8,
  4,
  8,
  12,
  5,
  4,
  11,
  12,
  1,
  13,
  15,
  12,
  11,
  11,
  12,
  7,
  7,
  15,
  7,
  15,
  15,
  4,
  12,
  12,
  12,
  11,
  8,
  8,
  11,
  6,
  11,
  13,
  13,
  3,
  13,
  3,
  12,
  8,
  11,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  11,
  4,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  4,
  12,
  4,
  4,
  11,
  4,
  11,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  11,
  12,
  11,
  5,
  5,
  5,
  11,
  12,
  5,
  3,
  3,
  12,
  15,
  15,
  3,
  3,
  3,
  15,
  5,
  15,
  3,
  13,
  3,
  13,
  3,
  3,
  12,
  6,
  0,
  0,
  0,
  0,
  6,
  12,
  3,
  3,
  5,
  3,
  15,
  3,
  3,
  5,
  12,
  9,
  6,
  9,
  4,
  4,
  4,
  9,
  9,
  9,
  0,
  4,
  4,
  4,
  12,
  8,
  8,
  8,
  8,
  4,
  4,
  10,
  5,
  15,
  5,
  10,
  14,
  4,
  4,
  4,
  4,
  4,
  12,
  15,
  12,
  4,
  12,
  8,
  4,
  4,
  8,
  4,
  8,
  4,
  8,
  15,
  4,
  11,
  12,
  1,
  1,
  13,
  12,
  12,
  11,
  6,
  15,
  7,
  7,
  15,
  7,
  15,
  12,
  4,
  12,
  5,
  9,
  12,
  8,
  11,
  6,
  11,
  1,
  3,
  13,
  3,
  13,
  12,
  8,
  11,
  6,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  11,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  4,
  11,
  4,
  11,
  4,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  11,
  12,
  12,
  5,
  15,
  5,
  11,
  12,
  3,
  3,
  5,
  5,
  15,
  3,
  3,
  3,
  3,
  15,
  5,
  3,
  13,
  13,
  13,
  13,
  3,
  3,
  3,
  6,
  6,
  6,
  6,
  12,
  3,
  3,
  13,
  3,
  3,
  15,
  3,
  15,
  15,
  10,
  6,
  9,
  4,
  4,
  4,
  9,
  9,
  9,
  9,
  6,
  9,
  4,
  8,
  8,
  12,
  8,
  8,
  4,
  8,
  10,
  10,
  10,
  13,
  15,
  14,
  4,
  14,
  4,
  4,
  4,
  12,
  4,
  12,
  4,
  12,
  15,
  8,
  4,
  4,
  4,
  8,
  4,
  8,
  12,
  15,
  4,
  11,
  12,
  1,
  1,
  13,
  15,
  12,
  11,
  11,
  12,
  15,
  15,
  7,
  15,
  15,
  12,
  12,
  12,
  5,
  6,
  11,
  11,
  11,
  6,
  11,
  13,
  13,
  3,
  5,
  13,
  5,
  12,
  8,
  11,
  6,
  11,
  6,
  6,
  6,
  11,
  6,
  11,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  4,
  12,
  4,
  4,
  11,
  4,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  12,
  5,
  15,
  5,
  11,
  5,
  3,
  3,
  5,
  15,
  15,
  3,
  3,
  3,
  15,
  15,
  5,
  13,
  13,
  13,
  13,
  13,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  13,
  3,
  3,
  15,
  3,
  3,
  15,
  5,
  12,
  6,
  9,
  4,
  4,
  6,
  6,
  6,
  9,
  9,
  0,
  9,
  9,
  4,
  8,
  8,
  12,
  8,
  8,
  4,
  8,
  10,
  10,
  1,
  13,
  10,
  14,
  4,
  14,
  4,
  12,
  4,
  12,
  4,
  12,
  15,
  3,
  14,
  4,
  4,
  4,
  4,
  8,
  4,
  8,
  3,
  4,
  11,
  12,
  13,
  1,
  1,
  15,
  12,
  11,
  11,
  6,
  15,
  7,
  15,
  7,
  15,
  12,
  4,
  4,
  12,
  9,
  9,
  11,
  9,
  6,
  9,
  1,
  3,
  3,
  12,
  3,
  13,
  5,
  8,
  8,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  6,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  4,
  12,
  4,
  4,
  11,
  4,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  5,
  12,
  5,
  15,
  12,
  12,
  3,
  3,
  5,
  5,
  15,
  15,
  3,
  13,
  3,
  15,
  15,
  3,
  13,
  1,
  1,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  15,
  12,
  3,
  3,
  15,
  15,
  10,
  9,
  4,
  4,
  6,
  0,
  0,
  0,
  6,
  9,
  0,
  6,
  9,
  4,
  8,
  12,
  8,
  12,
  8,
  8,
  4,
  14,
  10,
  1,
  1,
  10,
  14,
  14,
  4,
  14,
  4,
  14,
  4,
  4,
  12,
  15,
  3,
  3,
  14,
  4,
  4,
  4,
  4,
  8,
  12,
  3,
  4,
  11,
  12,
  13,
  1,
  13,
  13,
  15,
  12,
  11,
  11,
  15,
  15,
  7,
  15,
  15,
  15,
  12,
  4,
  12,
  12,
  6,
  9,
  11,
  6,
  11,
  1,
  13,
  3,
  12,
  5,
  3,
  3,
  12,
  8,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  14,
  12,
  14,
  12,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  4,
  4,
  11,
  4,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  12,
  5,
  12,
  5,
  5,
  11,
  5,
  3,
  3,
  12,
  15,
  15,
  3,
  3,
  13,
  3,
  15,
  15,
  3,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  13,
  13,
  13,
  3,
  12,
  15,
  13,
  15,
  15,
  10,
  12,
  6,
  4,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  9,
  9,
  4,
  8,
  12,
  8,
  12,
  8,
  8,
  10,
  14,
  1,
  1,
  10,
  14,
  4,
  14,
  4,
  14,
  4,
  14,
  4,
  12,
  15,
  1,
  3,
  14,
  14,
  4,
  9,
  4,
  4,
  4,
  3,
  6,
  11,
  12,
  15,
  13,
  1,
  13,
  15,
  15,
  11,
  11,
  12,
  15,
  15,
  7,
  15,
  15,
  4,
  4,
  12,
  12,
  6,
  9,
  9,
  6,
  9,
  1,
  13,
  3,
  12,
  12,
  5,
  13,
  12,
  12,
  8,
  6,
  11,
  6,
  6,
  6,
  6,
  6,
  12,
  14,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  4,
  12,
  4,
  4,
  11,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  5,
  5,
  5,
  5,
  12,
  3,
  3,
  5,
  5,
  15,
  15,
  3,
  3,
  13,
  3,
  15,
  15,
  13,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  13,
  3,
  15,
  12,
  3,
  3,
  15,
  5,
  12,
  8,
  9,
  4,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  4,
  12,
  8,
  12,
  8,
  8,
  4,
  14,
  10,
  13,
  13,
  10,
  14,
  14,
  4,
  14,
  4,
  14,
  4,
  14,
  4,
  12,
  1,
  1,
  15,
  14,
  14,
  6,
  6,
  9,
  4,
  4,
  6,
  9,
  12,
  15,
  13,
  13,
  13,
  15,
  12,
  12,
  11,
  6,
  15,
  7,
  15,
  15,
  15,
  12,
  4,
  4,
  12,
  11,
  9,
  11,
  6,
  11,
  1,
  13,
  5,
  12,
  12,
  12,
  3,
  5,
  12,
  8,
  11,
  6,
  11,
  6,
  6,
  6,
  11,
  14,
  12,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  14,
  12,
  4,
  4,
  11,
  4,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  0,
  0,
  6,
  11,
  12,
  12,
  5,
  15,
  5,
  5,
  3,
  3,
  15,
  12,
  15,
  15,
  3,
  13,
  3,
  13,
  3,
  15,
  15,
  3,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  3,
  3,
  15,
  12,
  15,
  3,
  13,
  5,
  10,
  8,
  8,
  4,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  4,
  4,
  4,
  10,
  10,
  12,
  12,
  12,
  8,
  8,
  10,
  14,
  10,
  13,
  10,
  14,
  12,
  14,
  4,
  14,
  4,
  14,
  4,
  14,
  4,
  15,
  1,
  15,
  15,
  14,
  6,
  9,
  9,
  9,
  9,
  9,
  9,
  11,
  12,
  13,
  1,
  13,
  13,
  15,
  12,
  11,
  11,
  15,
  15,
  7,
  15,
  15,
  12,
  12,
  4,
  12,
  12,
  9,
  9,
  6,
  9,
  1,
  13,
  3,
  5,
  12,
  12,
  5,
  3,
  12,
  12,
  8,
  11,
  6,
  11,
  6,
  11,
  6,
  12,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  14,
  12,
  4,
  4,
  11,
  4,
  11,
  11,
  11,
  0,
  0,
  0,
  0,
  0,
  6,
  12,
  12,
  12,
  6,
  0,
  0,
  6,
  6,
  11,
  12,
  12,
  5,
  15,
  12,
  5,
  3,
  3,
  12,
  5,
  15,
  15,
  3,
  13,
  3,
  3,
  3,
  15,
  15,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  15,
  12,
  15,
  3,
  13,
  3,
  10,
  12,
  9,
  8,
  4,
  0,
  0,
  0,
  0,
  6,
  4,
  4,
  8,
  4,
  10,
  8,
  10,
  10,
  10,
  10,
  10,
  14,
  14,
  4,
  14,
  10,
  14,
  10,
  10,
  14,
  14,
  14,
  14,
  12,
  14,
  4,
  14,
  4,
  12,
  14,
  1,
  1,
  15,
  15,
  9,
  9,
  8,
  8,
  8,
  9,
  9,
  11,
  12,
  13,
  13,
  13,
  15,
  15,
  15,
  12,
  11,
  12,
  15,
  15,
  7,
  15,
  12,
  4,
  4,
  4,
  12,
  6,
  11,
  6,
  11,
  1,
  1,
  5,
  3,
  12,
  12,
  12,
  3,
  5,
  12,
  12,
  11,
  11,
  6,
  11,
  6,
  11,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  14,
  12,
  4,
  12,
  4,
  4,
  11,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  12,
  15,
  3,
  15,
  12,
  6,
  0,
  0,
  6,
  12,
  12,
  5,
  5,
  5,
  5,
  12,
  3,
  3,
  5,
  12,
  15,
  15,
  3,
  3,
  13,
  3,
  3,
  15,
  12,
  12,
  12,
  15,
  15,
  3,
  15,
  15,
  12,
  12,
  15,
  3,
  13,
  3,
  13,
  12,
  10,
  8,
  11,
  4,
  0,
  0,
  6,
  4,
  4,
  4,
  10,
  4,
  10,
  8,
  10,
  15,
  7,
  7,
  15,
  10,
  10,
  10,
  14,
  4,
  14,
  10,
  14,
  10,
  14,
  12,
  14,
  12,
  12,
  12,
  14,
  4,
  14,
  4,
  14,
  15,
  1,
  1,
  15,
  9,
  8,
  12,
  12,
  12,
  8,
  9,
  9,
  11,
  15,
  13,
  15,
  13,
  15,
  12,
  15,
  11,
  6,
  15,
  15,
  15,
  15,
  12,
  12,
  4,
  4,
  12,
  6,
  9,
  6,
  6,
  1,
  1,
  12,
  5,
  5,
  12,
  12,
  5,
  3,
  12,
  12,
  8,
  11,
  11,
  6,
  11,
  6,
  12,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  12,
  4,
  4,
  11,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  15,
  3,
  3,
  15,
  15,
  11,
  0,
  0,
  6,
  11,
  12,
  12,
  5,
  15,
  5,
  5,
  3,
  3,
  15,
  12,
  5,
  15,
  3,
  13,
  3,
  13,
  3,
  3,
  15,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  3,
  13,
  3,
  13,
  3,
  10,
  12,
  9,
  9,
  6,
  0,
  6,
  4,
  4,
  4,
  10,
  4,
  10,
  8,
  10,
  15,
  7,
  1,
  1,
  7,
  15,
  10,
  10,
  14,
  14,
  4,
  14,
  10,
  14,
  15,
  1,
  15,
  14,
  15,
  14,
  15,
  14,
  4,
  14,
  4,
  14,
  1,
  15,
  15,
  8,
  12,
  12,
  5,
  5,
  12,
  12,
  9,
  11,
  12,
  15,
  13,
  15,
  13,
  15,
  12,
  11,
  11,
  12,
  15,
  7,
  15,
  15,
  4,
  4,
  4,
  12,
  6,
  6,
  6,
  6,
  13,
  3,
  5,
  12,
  5,
  12,
  12,
  12,
  5,
  5,
  12,
  12,
  8,
  11,
  11,
  6,
  11,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  4,
  4,
  12,
  4,
  11,
  11,
  11,
  0,
  0,
  0,
  0,
  0,
  12,
  3,
  7,
  15,
  7,
  15,
  12,
  0,
  0,
  6,
  6,
  12,
  5,
  5,
  5,
  15,
  12,
  5,
  3,
  3,
  5,
  12,
  15,
  15,
  3,
  13,
  13,
  13,
  3,
  3,
  15,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  3,
  13,
  3,
  13,
  3,
  12,
  12,
  12,
  9,
  6,
  6,
  0,
  6,
  4,
  4,
  8,
  4,
  10,
  4,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  14,
  10,
  14,
  14,
  10,
  12,
  8,
  8,
  4,
  4,
  8,
  11,
  8,
  11,
  11,
  11,
  4,
  4,
  4,
  15,
  1,
  15,
  8,
  12,
  5,
  5,
  5,
  5,
  12,
  12,
  9,
  11,
  12,
  15,
  13,
  15,
  15,
  15,
  12,
  11,
  6,
  15,
  15,
  15,
  15,
  12,
  4,
  4,
  12,
  6,
  6,
  6,
  6,
  12,
  13,
  3,
  12,
  12,
  5,
  12,
  8,
  12,
  5,
  12,
  12,
  12,
  8,
  11,
  11,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  4,
  4,
  11,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  12,
  1,
  15,
  12,
  15,
  7,
  12,
  6,
  0,
  0,
  6,
  11,
  12,
  5,
  15,
  5,
  5,
  12,
  3,
  3,
  15,
  12,
  5,
  15,
  3,
  3,
  13,
  13,
  13,
  3,
  3,
  3,
  3,
  15,
  15,
  15,
  15,
  3,
  13,
  3,
  13,
  13,
  3,
  12,
  10,
  12,
  6,
  6,
  9,
  6,
  9,
  4,
  8,
  4,
  10,
  4,
  10,
  8,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  14,
  10,
  12,
  8,
  8,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  12,
  4,
  4,
  11,
  4,
  4,
  14,
  15,
  15,
  8,
  12,
  5,
  5,
  15,
  15,
  5,
  12,
  8,
  11,
  12,
  12,
  15,
  13,
  15,
  12,
  12,
  11,
  11,
  12,
  15,
  15,
  15,
  12,
  12,
  4,
  4,
  0,
  6,
  0,
  6,
  4,
  12,
  13,
  12,
  12,
  5,
  12,
  8,
  8,
  5,
  5,
  12,
  12,
  12,
  11,
  11,
  11,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  14,
  12,
  4,
  4,
  11,
  4,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  5,
  3,
  12,
  6,
  12,
  1,
  12,
  6,
  0,
  0,
  6,
  12,
  5,
  5,
  5,
  15,
  5,
  5,
  5,
  3,
  3,
  12,
  12,
  15,
  15,
  13,
  3,
  13,
  13,
  13,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  13,
  3,
  13,
  13,
  3,
  12,
  10,
  12,
  8,
  6,
  9,
  4,
  0,
  4,
  4,
  4,
  10,
  4,
  10,
  8,
  10,
  8,
  10,
  8,
  10,
  8,
  10,
  8,
  10,
  10,
  10,
  12,
  8,
  12,
  12,
  10,
  14,
  12,
  10,
  14,
  14,
  14,
  14,
  12,
  14,
  4,
  11,
  4,
  4,
  1,
  15,
  8,
  12,
  5,
  5,
  15,
  3,
  15,
  12,
  8,
  9,
  11,
  12,
  12,
  15,
  15,
  15,
  12,
  11,
  11,
  6,
  12,
  15,
  15,
  12,
  4,
  4,
  6,
  0,
  0,
  0,
  6,
  4,
  12,
  5,
  5,
  12,
  12,
  12,
  12,
  11,
  12,
  5,
  12,
  12,
  12,
  8,
  11,
  11,
  12,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  4,
  11,
  11,
  11,
  0,
  0,
  0,
  0,
  0,
  6,
  3,
  3,
  6,
  0,
  6,
  7,
  15,
  6,
  0,
  0,
  6,
  11,
  12,
  5,
  15,
  5,
  5,
  12,
  3,
  3,
  3,
  5,
  12,
  5,
  15,
  3,
  13,
  13,
  1,
  13,
  13,
  13,
  3,
  3,
  3,
  3,
  13,
  3,
  13,
  13,
  13,
  3,
  12,
  15,
  10,
  12,
  6,
  9,
  9,
  6,
  4,
  4,
  8,
  4,
  10,
  4,
  10,
  8,
  10,
  8,
  10,
  8,
  10,
  8,
  10,
  8,
  10,
  10,
  8,
  12,
  14,
  14,
  14,
  14,
  10,
  12,
  10,
  14,
  10,
  14,
  14,
  14,
  14,
  4,
  11,
  4,
  15,
  4,
  8,
  12,
  5,
  15,
  3,
  3,
  3,
  5,
  8,
  9,
  11,
  11,
  15,
  12,
  15,
  12,
  12,
  12,
  11,
  11,
  12,
  12,
  15,
  12,
  12,
  4,
  6,
  0,
  0,
  0,
  6,
  4,
  8,
  12,
  3,
  12,
  12,
  5,
  12,
  11,
  8,
  5,
  5,
  12,
  12,
  12,
  8,
  11,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  4,
  4,
  11,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  1,
  12,
  0,
  6,
  12,
  1,
  15,
  6,
  0,
  0,
  6,
  12,
  5,
  5,
  5,
  15,
  5,
  12,
  5,
  3,
  13,
  15,
  12,
  12,
  15,
  15,
  3,
  13,
  13,
  1,
  1,
  1,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  3,
  10,
  10,
  12,
  8,
  6,
  9,
  4,
  0,
  4,
  4,
  4,
  8,
  4,
  10,
  8,
  4,
  9,
  9,
  9,
  9,
  9,
  9,
  8,
  10,
  8,
  8,
  12,
  10,
  14,
  14,
  14,
  10,
  14,
  10,
  12,
  10,
  14,
  10,
  14,
  10,
  14,
  14,
  4,
  11,
  14,
  11,
  8,
  12,
  5,
  15,
  1,
  3,
  15,
  15,
  12,
  8,
  9,
  11,
  12,
  15,
  12,
  15,
  12,
  12,
  12,
  11,
  6,
  12,
  12,
  12,
  4,
  4,
  0,
  0,
  0,
  0,
  6,
  4,
  11,
  12,
  5,
  12,
  8,
  12,
  12,
  8,
  11,
  12,
  5,
  12,
  12,
  12,
  8,
  8,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  4,
  4,
  4,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  7,
  11,
  0,
  11,
  15,
  1,
  15,
  6,
  0,
  0,
  6,
  11,
  12,
  5,
  15,
  5,
  5,
  12,
  5,
  3,
  3,
  3,
  12,
  12,
  5,
  15,
  15,
  3,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  1,
  13,
  13,
  13,
  3,
  12,
  10,
  15,
  10,
  9,
  6,
  9,
  10,
  6,
  4,
  4,
  8,
  4,
  8,
  4,
  9,
  9,
  6,
  6,
  6,
  6,
  6,
  6,
  9,
  8,
  10,
  8,
  10,
  14,
  10,
  14,
  14,
  14,
  10,
  12,
  10,
  14,
  10,
  14,
  10,
  14,
  14,
  4,
  14,
  4,
  11,
  11,
  8,
  12,
  5,
  15,
  1,
  3,
  3,
  15,
  5,
  8,
  9,
  9,
  11,
  12,
  15,
  12,
  12,
  12,
  11,
  11,
  6,
  6,
  12,
  4,
  4,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  11,
  12,
  5,
  5,
  12,
  8,
  5,
  12,
  11,
  8,
  12,
  5,
  12,
  12,
  12,
  8,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  4,
  11,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  1,
  11,
  0,
  11,
  15,
  1,
  12,
  6,
  0,
  0,
  6,
  12,
  5,
  5,
  5,
  15,
  5,
  5,
  12,
  3,
  13,
  3,
  5,
  12,
  12,
  15,
  15,
  15,
  3,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  1,
  13,
  13,
  12,
  5,
  5,
  15,
  10,
  9,
  0,
  9,
  3,
  6,
  4,
  4,
  4,
  9,
  4,
  9,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  8,
  9,
  10,
  10,
  14,
  14,
  14,
  10,
  14,
  10,
  10,
  10,
  10,
  10,
  14,
  10,
  14,
  14,
  4,
  4,
  4,
  11,
  9,
  8,
  5,
  5,
  3,
  3,
  15,
  3,
  5,
  12,
  8,
  9,
  11,
  11,
  12,
  12,
  12,
  12,
  12,
  11,
  6,
  6,
  6,
  4,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  11,
  11,
  5,
  5,
  12,
  11,
  5,
  12,
  8,
  11,
  8,
  5,
  12,
  12,
  12,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  4,
  4,
  4,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  1,
  11,
  0,
  11,
  12,
  15,
  12,
  6,
  0,
  0,
  6,
  11,
  12,
  5,
  15,
  15,
  5,
  5,
  5,
  3,
  3,
  13,
  15,
  12,
  12,
  5,
  15,
  15,
  3,
  3,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  13,
  3,
  3,
  10,
  15,
  3,
  10,
  9,
  6,
  9,
  10,
  9,
  4,
  4,
  4,
  4,
  9,
  4,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  10,
  14,
  10,
  14,
  10,
  14,
  10,
  15,
  15,
  15,
  15,
  10,
  10,
  14,
  10,
  14,
  14,
  4,
  6,
  4,
  9,
  8,
  5,
  5,
  15,
  3,
  3,
  15,
  15,
  12,
  8,
  9,
  11,
  12,
  11,
  12,
  12,
  12,
  11,
  12,
  6,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  2,
  4,
  11,
  12,
  5,
  5,
  11,
  12,
  5,
  12,
  11,
  11,
  12,
  5,
  12,
  12,
  12,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  12,
  11,
  11,
  11,
  6,
  0,
  0,
  6,
  0,
  0,
  6,
  1,
  11,
  0,
  11,
  11,
  12,
  11,
  6,
  0,
  0,
  11,
  12,
  5,
  15,
  5,
  15,
  15,
  5,
  12,
  15,
  3,
  3,
  3,
  5,
  12,
  12,
  5,
  15,
  15,
  3,
  3,
  13,
  13,
  1,
  1,
  13,
  13,
  13,
  3,
  3,
  5,
  5,
  15,
  3,
  12,
  9,
  0,
  9,
  4,
  4,
  6,
  4,
  4,
  9,
  4,
  9,
  9,
  9,
  6,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  0,
  8,
  10,
  14,
  10,
  14,
  10,
  15,
  15,
  13,
  1,
  13,
  13,
  15,
  10,
  14,
  10,
  4,
  14,
  4,
  6,
  9,
  8,
  12,
  5,
  15,
  3,
  15,
  3,
  15,
  5,
  12,
  8,
  9,
  11,
  12,
  11,
  12,
  11,
  12,
  11,
  6,
  0,
  6,
  0,
  6,
  6,
  0,
  0,
  0,
  6,
  6,
  9,
  4,
  11,
  12,
  5,
  5,
  8,
  8,
  5,
  12,
  8,
  11,
  8,
  5,
  12,
  12,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  4,
  12,
  4,
  11,
  6,
  6,
  0,
  0,
  6,
  0,
  0,
  6,
  7,
  11,
  0,
  0,
  6,
  11,
  6,
  0,
  0,
  6,
  11,
  12,
  5,
  5,
  15,
  15,
  5,
  5,
  12,
  3,
  3,
  13,
  3,
  15,
  5,
  12,
  12,
  5,
  15,
  15,
  3,
  3,
  13,
  13,
  13,
  13,
  13,
  3,
  3,
  5,
  10,
  15,
  3,
  3,
  10,
  9,
  6,
  9,
  9,
  4,
  9,
  6,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  6,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  8,
  8,
  10,
  14,
  10,
  15,
  13,
  13,
  1,
  1,
  1,
  13,
  15,
  15,
  10,
  14,
  14,
  4,
  4,
  4,
  6,
  9,
  8,
  12,
  5,
  15,
  3,
  15,
  15,
  12,
  12,
  12,
  9,
  11,
  11,
  12,
  11,
  12,
  11,
  12,
  6,
  0,
  0,
  6,
  6,
  6,
  6,
  0,
  6,
  6,
  6,
  6,
  4,
  6,
  8,
  12,
  5,
  12,
  11,
  12,
  12,
  12,
  11,
  11,
  12,
  5,
  12,
  12,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  12,
  4,
  4,
  11,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  1,
  11,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  6,
  12,
  12,
  5,
  15,
  5,
  15,
  15,
  5,
  12,
  15,
  3,
  3,
  13,
  3,
  15,
  5,
  12,
  10,
  5,
  15,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  5,
  5,
  15,
  3,
  3,
  12,
  9,
  0,
  6,
  4,
  9,
  9,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  8,
  8,
  11,
  11,
  0,
  0,
  0,
  6,
  0,
  9,
  8,
  12,
  10,
  15,
  13,
  13,
  1,
  1,
  1,
  13,
  15,
  15,
  15,
  10,
  10,
  14,
  14,
  4,
  6,
  6,
  9,
  8,
  12,
  5,
  15,
  15,
  3,
  15,
  5,
  12,
  8,
  9,
  9,
  11,
  11,
  12,
  11,
  12,
  11,
  6,
  0,
  6,
  6,
  6,
  6,
  6,
  0,
  6,
  9,
  6,
  6,
  4,
  6,
  8,
  12,
  5,
  12,
  11,
  8,
  5,
  12,
  8,
  6,
  11,
  12,
  5,
  12,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  4,
  4,
  4,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  7,
  12,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  12,
  5,
  15,
  5,
  15,
  15,
  5,
  5,
  12,
  3,
  3,
  13,
  3,
  3,
  3,
  15,
  11,
  12,
  10,
  5,
  5,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  5,
  10,
  15,
  3,
  3,
  5,
  12,
  9,
  6,
  0,
  9,
  9,
  4,
  9,
  9,
  6,
  0,
  0,
  0,
  0,
  11,
  12,
  8,
  8,
  11,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  12,
  12,
  10,
  15,
  13,
  13,
  15,
  14,
  12,
  12,
  12,
  14,
  14,
  14,
  14,
  4,
  4,
  4,
  6,
  9,
  9,
  8,
  5,
  5,
  3,
  15,
  15,
  5,
  5,
  12,
  8,
  9,
  11,
  11,
  11,
  12,
  11,
  11,
  6,
  0,
  6,
  6,
  11,
  6,
  6,
  6,
  6,
  4,
  9,
  6,
  9,
  4,
  11,
  12,
  5,
  12,
  8,
  11,
  12,
  12,
  12,
  11,
  6,
  11,
  12,
  5,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  11,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  7,
  3,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  5,
  5,
  15,
  5,
  15,
  15,
  5,
  12,
  15,
  3,
  3,
  13,
  3,
  3,
  15,
  12,
  11,
  12,
  10,
  5,
  5,
  5,
  3,
  3,
  3,
  5,
  10,
  10,
  5,
  15,
  3,
  3,
  5,
  10,
  9,
  9,
  0,
  6,
  4,
  4,
  4,
  9,
  9,
  9,
  9,
  6,
  0,
  12,
  15,
  12,
  8,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  8,
  12,
  10,
  10,
  15,
  10,
  14,
  14,
  4,
  4,
  4,
  4,
  14,
  14,
  14,
  14,
  4,
  6,
  6,
  6,
  9,
  8,
  12,
  5,
  15,
  15,
  15,
  5,
  12,
  12,
  12,
  9,
  9,
  11,
  11,
  11,
  11,
  11,
  6,
  0,
  6,
  4,
  11,
  11,
  6,
  6,
  6,
  4,
  4,
  6,
  6,
  4,
  6,
  14,
  5,
  12,
  12,
  11,
  8,
  12,
  12,
  8,
  11,
  6,
  11,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  4,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  3,
  1,
  12,
  6,
  6,
  0,
  0,
  6,
  11,
  12,
  5,
  5,
  15,
  5,
  15,
  15,
  5,
  5,
  5,
  3,
  3,
  13,
  3,
  13,
  3,
  15,
  11,
  12,
  10,
  12,
  10,
  5,
  5,
  5,
  5,
  5,
  10,
  10,
  5,
  15,
  15,
  3,
  3,
  10,
  10,
  9,
  9,
  6,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  9,
  11,
  15,
  15,
  12,
  8,
  0,
  6,
  14,
  6,
  6,
  0,
  0,
  0,
  4,
  12,
  12,
  10,
  10,
  14,
  4,
  4,
  12,
  12,
  12,
  12,
  4,
  4,
  14,
  4,
  4,
  4,
  6,
  6,
  9,
  8,
  8,
  5,
  15,
  15,
  15,
  5,
  5,
  12,
  8,
  9,
  9,
  6,
  11,
  11,
  11,
  11,
  6,
  0,
  6,
  12,
  4,
  11,
  11,
  6,
  6,
  12,
  4,
  6,
  6,
  4,
  6,
  14,
  5,
  5,
  12,
  11,
  11,
  8,
  12,
  12,
  11,
  11,
  6,
  11,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  11,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  12,
  3,
  7,
  12,
  6,
  6,
  6,
  11,
  12,
  5,
  5,
  15,
  5,
  15,
  15,
  15,
  15,
  5,
  12,
  15,
  3,
  3,
  13,
  3,
  13,
  5,
  12,
  5,
  5,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  10,
  5,
  15,
  15,
  3,
  3,
  3,
  10,
  12,
  11,
  11,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  12,
  15,
  5,
  12,
  6,
  0,
  6,
  14,
  14,
  6,
  6,
  0,
  0,
  6,
  8,
  12,
  12,
  8,
  4,
  4,
  12,
  1,
  1,
  1,
  13,
  12,
  6,
  4,
  4,
  4,
  6,
  6,
  0,
  6,
  9,
  8,
  12,
  5,
  15,
  5,
  5,
  12,
  12,
  12,
  8,
  9,
  6,
  6,
  11,
  11,
  11,
  6,
  0,
  6,
  4,
  12,
  11,
  11,
  6,
  6,
  4,
  12,
  4,
  6,
  6,
  6,
  14,
  12,
  5,
  12,
  8,
  11,
  11,
  8,
  12,
  8,
  11,
  11,
  11,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  3,
  3,
  3,
  12,
  12,
  12,
  12,
  15,
  3,
  15,
  3,
  15,
  3,
  15,
  15,
  5,
  5,
  5,
  3,
  3,
  13,
  3,
  13,
  3,
  12,
  3,
  3,
  3,
  3,
  15,
  5,
  5,
  5,
  5,
  5,
  5,
  15,
  15,
  3,
  3,
  3,
  5,
  10,
  12,
  11,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  15,
  15,
  12,
  12,
  0,
  0,
  6,
  14,
  15,
  14,
  6,
  6,
  0,
  0,
  4,
  8,
  8,
  4,
  4,
  3,
  1,
  1,
  1,
  13,
  1,
  13,
  3,
  6,
  4,
  4,
  4,
  0,
  0,
  6,
  9,
  8,
  8,
  12,
  5,
  5,
  12,
  5,
  12,
  8,
  8,
  9,
  6,
  6,
  6,
  11,
  6,
  6,
  0,
  6,
  12,
  4,
  4,
  11,
  6,
  6,
  12,
  4,
  4,
  6,
  4,
  6,
  14,
  8,
  5,
  5,
  12,
  11,
  11,
  11,
  8,
  12,
  8,
  11,
  11,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  4,
  11,
  11,
  11,
  6,
  0,
  0,
  6,
  0,
  0,
  0,
  11,
  12,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  15,
  3,
  15,
  5,
  5,
  3,
  15,
  15,
  5,
  12,
  15,
  3,
  3,
  13,
  3,
  15,
  3,
  13,
  3,
  3,
  3,
  3,
  3,
  15,
  15,
  15,
  15,
  15,
  15,
  3,
  3,
  3,
  3,
  5,
  10,
  12,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  12,
  15,
  5,
  12,
  11,
  0,
  0,
  0,
  14,
  14,
  15,
  14,
  6,
  0,
  0,
  6,
  4,
  4,
  6,
  15,
  13,
  1,
  1,
  1,
  1,
  13,
  13,
  3,
  12,
  6,
  4,
  6,
  0,
  0,
  0,
  9,
  9,
  8,
  12,
  12,
  12,
  5,
  12,
  12,
  12,
  8,
  9,
  9,
  6,
  6,
  6,
  6,
  6,
  0,
  6,
  4,
  12,
  4,
  11,
  11,
  6,
  4,
  12,
  4,
  6,
  6,
  6,
  14,
  8,
  12,
  5,
  12,
  11,
  11,
  11,
  11,
  8,
  12,
  11,
  11,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  4,
  4,
  4,
  11,
  6,
  6,
  0,
  0,
  6,
  6,
  0,
  0,
  6,
  12,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  15,
  5,
  12,
  12,
  5,
  3,
  15,
  12,
  5,
  3,
  3,
  13,
  13,
  13,
  15,
  13,
  3,
  13,
  3,
  13,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  13,
  3,
  5,
  10,
  12,
  11,
  6,
  11,
  6,
  9,
  9,
  6,
  6,
  0,
  0,
  0,
  11,
  15,
  15,
  12,
  12,
  6,
  0,
  0,
  0,
  8,
  15,
  14,
  15,
  14,
  6,
  0,
  0,
  6,
  6,
  12,
  3,
  13,
  1,
  1,
  1,
  13,
  1,
  13,
  13,
  3,
  12,
  6,
  6,
  0,
  0,
  0,
  6,
  9,
  9,
  8,
  12,
  12,
  12,
  12,
  12,
  8,
  8,
  8,
  9,
  6,
  6,
  6,
  6,
  0,
  0,
  6,
  12,
  4,
  12,
  4,
  11,
  6,
  6,
  12,
  4,
  4,
  6,
  6,
  8,
  14,
  8,
  5,
  12,
  8,
  11,
  6,
  11,
  11,
  8,
  8,
  11,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  14,
  12,
  12,
  4,
  4,
  4,
  4,
  11,
  6,
  6,
  0,
  6,
  0,
  0,
  0,
  6,
  11,
  12,
  3,
  3,
  3,
  15,
  12,
  15,
  15,
  5,
  12,
  11,
  11,
  12,
  15,
  3,
  5,
  5,
  3,
  13,
  3,
  13,
  3,
  3,
  3,
  13,
  3,
  13,
  3,
  13,
  3,
  13,
  3,
  3,
  10,
  3,
  10,
  12,
  12,
  12,
  12,
  11,
  11,
  6,
  0,
  6,
  12,
  6,
  0,
  0,
  6,
  6,
  0,
  0,
  12,
  15,
  5,
  12,
  11,
  0,
  0,
  6,
  0,
  14,
  14,
  15,
  14,
  15,
  14,
  0,
  0,
  6,
  6,
  12,
  3,
  13,
  1,
  1,
  1,
  1,
  13,
  1,
  13,
  3,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  9,
  9,
  8,
  8,
  12,
  12,
  12,
  8,
  12,
  8,
  9,
  9,
  6,
  6,
  6,
  6,
  0,
  0,
  6,
  4,
  12,
  4,
  4,
  11,
  6,
  6,
  12,
  12,
  4,
  6,
  6,
  4,
  14,
  8,
  12,
  5,
  12,
  11,
  11,
  6,
  11,
  11,
  12,
  11,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  12,
  14,
  12,
  14,
  12,
  12,
  4,
  4,
  11,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  12,
  3,
  3,
  3,
  12,
  15,
  12,
  3,
  12,
  11,
  5,
  11,
  5,
  15,
  15,
  12,
  3,
  13,
  3,
  13,
  3,
  15,
  3,
  13,
  13,
  13,
  3,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  5,
  5,
  10,
  10,
  10,
  12,
  12,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  15,
  15,
  12,
  12,
  6,
  0,
  6,
  11,
  6,
  8,
  14,
  14,
  15,
  15,
  15,
  14,
  0,
  0,
  6,
  12,
  3,
  13,
  1,
  1,
  1,
  1,
  1,
  13,
  13,
  13,
  3,
  12,
  12,
  6,
  0,
  0,
  0,
  6,
  9,
  9,
  12,
  8,
  12,
  8,
  12,
  8,
  8,
  8,
  9,
  9,
  6,
  6,
  0,
  0,
  0,
  6,
  12,
  4,
  12,
  4,
  11,
  11,
  6,
  12,
  12,
  4,
  6,
  6,
  11,
  14,
  14,
  8,
  5,
  5,
  8,
  11,
  11,
  6,
  11,
  8,
  8,
  14,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  14,
  12,
  14,
  12,
  4,
  4,
  12,
  4,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  3,
  3,
  12,
  3,
  12,
  3,
  11,
  5,
  15,
  5,
  5,
  15,
  5,
  5,
  3,
  3,
  13,
  3,
  13,
  3,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  13,
  13,
  13,
  13,
  1,
  1,
  1,
  13,
  5,
  10,
  10,
  12,
  9,
  9,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  12,
  15,
  5,
  12,
  11,
  0,
  6,
  11,
  14,
  11,
  9,
  14,
  15,
  15,
  13,
  15,
  15,
  0,
  0,
  6,
  12,
  3,
  13,
  13,
  1,
  1,
  1,
  13,
  1,
  13,
  13,
  3,
  15,
  12,
  6,
  6,
  0,
  0,
  0,
  9,
  9,
  8,
  12,
  8,
  12,
  8,
  12,
  8,
  9,
  8,
  9,
  6,
  0,
  0,
  0,
  0,
  6,
  4,
  12,
  4,
  12,
  4,
  11,
  6,
  12,
  12,
  4,
  11,
  6,
  6,
  12,
  14,
  8,
  12,
  5,
  12,
  8,
  6,
  11,
  6,
  11,
  8,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  14,
  12,
  12,
  12,
  4,
  4,
  11,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  12,
  12,
  3,
  12,
  15,
  12,
  3,
  12,
  3,
  15,
  15,
  15,
  15,
  12,
  5,
  15,
  3,
  3,
  3,
  3,
  15,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  1,
  13,
  3,
  10,
  10,
  12,
  9,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  15,
  15,
  12,
  12,
  6,
  0,
  11,
  14,
  8,
  14,
  8,
  8,
  14,
  13,
  15,
  13,
  15,
  6,
  0,
  6,
  12,
  3,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  13,
  3,
  15,
  12,
  9,
  6,
  0,
  0,
  6,
  9,
  9,
  8,
  12,
  8,
  12,
  8,
  8,
  8,
  9,
  9,
  6,
  0,
  0,
  0,
  0,
  6,
  12,
  12,
  12,
  4,
  4,
  11,
  6,
  6,
  12,
  4,
  11,
  6,
  6,
  12,
  12,
  14,
  8,
  5,
  5,
  12,
  11,
  6,
  11,
  11,
  11,
  14,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  12,
  4,
  4,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  3,
  15,
  12,
  3,
  3,
  11,
  5,
  15,
  15,
  15,
  5,
  5,
  15,
  3,
  15,
  3,
  15,
  3,
  12,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  3,
  13,
  3,
  13,
  1,
  1,
  1,
  1,
  1,
  5,
  10,
  10,
  12,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  12,
  15,
  15,
  12,
  11,
  0,
  6,
  11,
  14,
  14,
  8,
  14,
  8,
  14,
  15,
  13,
  13,
  13,
  14,
  6,
  6,
  12,
  3,
  13,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  13,
  3,
  3,
  15,
  12,
  9,
  9,
  6,
  0,
  0,
  9,
  9,
  9,
  8,
  8,
  8,
  8,
  8,
  9,
  8,
  9,
  6,
  0,
  0,
  0,
  0,
  6,
  4,
  12,
  12,
  12,
  4,
  11,
  11,
  6,
  12,
  12,
  11,
  11,
  6,
  14,
  14,
  14,
  11,
  12,
  5,
  12,
  8,
  11,
  6,
  6,
  11,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  12,
  12,
  12,
  4,
  4,
  11,
  6,
  11,
  6,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  3,
  3,
  3,
  3,
  12,
  5,
  5,
  15,
  5,
  12,
  15,
  3,
  15,
  3,
  15,
  3,
  15,
  15,
  3,
  13,
  1,
  13,
  1,
  13,
  3,
  13,
  1,
  1,
  13,
  13,
  1,
  1,
  1,
  13,
  10,
  10,
  10,
  9,
  9,
  6,
  0,
  0,
  0,
  6,
  0,
  0,
  11,
  15,
  1,
  15,
  12,
  6,
  0,
  11,
  14,
  3,
  14,
  14,
  8,
  14,
  8,
  13,
  13,
  1,
  13,
  15,
  14,
  6,
  12,
  15,
  3,
  13,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  13,
  3,
  3,
  15,
  12,
  9,
  9,
  0,
  0,
  6,
  9,
  8,
  9,
  8,
  9,
  8,
  9,
  8,
  9,
  9,
  6,
  0,
  0,
  6,
  0,
  0,
  4,
  12,
  12,
  4,
  12,
  11,
  11,
  6,
  4,
  12,
  4,
  11,
  6,
  12,
  14,
  14,
  8,
  8,
  12,
  5,
  12,
  11,
  11,
  6,
  6,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  4,
  12,
  4,
  4,
  11,
  8,
  6,
  6,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  3,
  3,
  3,
  5,
  11,
  5,
  5,
  12,
  5,
  15,
  15,
  3,
  15,
  3,
  15,
  3,
  12,
  3,
  3,
  13,
  1,
  13,
  3,
  13,
  1,
  13,
  1,
  1,
  3,
  13,
  1,
  13,
  3,
  10,
  10,
  12,
  9,
  6,
  0,
  0,
  0,
  0,
  4,
  6,
  0,
  12,
  15,
  15,
  5,
  11,
  0,
  0,
  11,
  14,
  1,
  14,
  14,
  14,
  8,
  8,
  15,
  13,
  13,
  1,
  15,
  14,
  6,
  12,
  15,
  3,
  3,
  13,
  13,
  1,
  13,
  1,
  13,
  13,
  3,
  3,
  3,
  15,
  12,
  9,
  9,
  6,
  0,
  0,
  9,
  9,
  8,
  9,
  8,
  9,
  8,
  9,
  8,
  9,
  6,
  0,
  6,
  4,
  6,
  0,
  6,
  12,
  12,
  12,
  4,
  4,
  11,
  6,
  6,
  4,
  11,
  11,
  6,
  8,
  14,
  14,
  14,
  11,
  8,
  5,
  5,
  8,
  11,
  11,
  6,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  12,
  4,
  4,
  4,
  4,
  8,
  6,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  3,
  3,
  3,
  5,
  12,
  12,
  5,
  15,
  15,
  15,
  15,
  15,
  15,
  3,
  15,
  15,
  15,
  3,
  3,
  3,
  3,
  13,
  1,
  13,
  1,
  13,
  1,
  13,
  3,
  13,
  13,
  5,
  10,
  12,
  12,
  9,
  6,
  0,
  0,
  0,
  0,
  4,
  8,
  0,
  15,
  15,
  5,
  12,
  6,
  0,
  0,
  14,
  14,
  3,
  14,
  14,
  8,
  14,
  8,
  14,
  13,
  1,
  1,
  15,
  14,
  6,
  12,
  15,
  3,
  13,
  3,
  13,
  13,
  1,
  13,
  1,
  13,
  13,
  3,
  3,
  15,
  15,
  12,
  9,
  9,
  0,
  0,
  6,
  9,
  9,
  8,
  9,
  8,
  9,
  8,
  9,
  9,
  9,
  0,
  4,
  6,
  11,
  0,
  6,
  12,
  12,
  12,
  12,
  4,
  11,
  6,
  6,
  4,
  11,
  11,
  6,
  4,
  14,
  14,
  14,
  8,
  11,
  12,
  5,
  12,
  8,
  11,
  11,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  14,
  12,
  12,
  12,
  4,
  12,
  11,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  12,
  5,
  3,
  3,
  15,
  3,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  15,
  12,
  3,
  15,
  3,
  5,
  13,
  1,
  13,
  13,
  13,
  1,
  13,
  1,
  3,
  1,
  3,
  5,
  10,
  12,
  9,
  6,
  0,
  0,
  0,
  6,
  0,
  4,
  8,
  0,
  15,
  5,
  12,
  11,
  0,
  0,
  0,
  8,
  14,
  10,
  14,
  14,
  14,
  8,
  9,
  8,
  15,
  1,
  1,
  15,
  14,
  6,
  6,
  12,
  15,
  3,
  13,
  3,
  13,
  13,
  13,
  13,
  13,
  3,
  3,
  3,
  3,
  15,
  12,
  9,
  9,
  6,
  0,
  0,
  6,
  9,
  9,
  8,
  9,
  8,
  9,
  8,
  9,
  6,
  0,
  6,
  4,
  11,
  6,
  6,
  4,
  12,
  12,
  12,
  12,
  11,
  11,
  6,
  6,
  4,
  11,
  11,
  11,
  12,
  14,
  14,
  14,
  11,
  8,
  12,
  5,
  12,
  8,
  11,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  4,
  4,
  8,
  8,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  12,
  12,
  5,
  5,
  15,
  5,
  15,
  5,
  15,
  5,
  15,
  15,
  15,
  11,
  15,
  15,
  5,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  13,
  5,
  13,
  5,
  5,
  10,
  12,
  9,
  6,
  0,
  0,
  0,
  9,
  0,
  4,
  4,
  0,
  5,
  12,
  11,
  0,
  0,
  0,
  0,
  14,
  14,
  14,
  10,
  14,
  14,
  14,
  8,
  9,
  14,
  15,
  15,
  14,
  14,
  6,
  0,
  12,
  15,
  3,
  3,
  13,
  3,
  13,
  3,
  13,
  3,
  13,
  3,
  3,
  15,
  15,
  15,
  12,
  8,
  9,
  0,
  0,
  0,
  6,
  9,
  9,
  8,
  9,
  8,
  9,
  9,
  9,
  0,
  4,
  10,
  11,
  11,
  6,
  4,
  12,
  12,
  12,
  4,
  11,
  11,
  6,
  6,
  6,
  11,
  11,
  6,
  12,
  14,
  14,
  14,
  8,
  11,
  12,
  5,
  5,
  12,
  8,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  4,
  11,
  12,
  8,
  6,
  0,
  0,
  0,
  0,
  6,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  15,
  15,
  15,
  12,
  5,
  12,
  12,
  13,
  3,
  3,
  3,
  3,
  13,
  3,
  13,
  3,
  5,
  3,
  5,
  10,
  12,
  9,
  6,
  0,
  0,
  0,
  6,
  9,
  0,
  4,
  8,
  0,
  12,
  11,
  0,
  0,
  0,
  0,
  0,
  8,
  14,
  14,
  3,
  14,
  14,
  14,
  9,
  6,
  6,
  14,
  14,
  14,
  6,
  6,
  0,
  6,
  12,
  15,
  3,
  3,
  13,
  3,
  13,
  3,
  13,
  3,
  3,
  3,
  3,
  15,
  15,
  15,
  8,
  8,
  6,
  0,
  0,
  6,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  6,
  0,
  6,
  4,
  4,
  11,
  6,
  6,
  12,
  12,
  12,
  12,
  11,
  11,
  6,
  6,
  6,
  6,
  11,
  6,
  12,
  12,
  14,
  14,
  14,
  11,
  8,
  12,
  5,
  5,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  12,
  12,
  4,
  8,
  12,
  6,
  6,
  0,
  0,
  6,
  9,
  12,
  9,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  11,
  11,
  11,
  11,
  11,
  12,
  12,
  12,
  15,
  15,
  11,
  12,
  11,
  13,
  3,
  5,
  15,
  15,
  3,
  3,
  3,
  3,
  3,
  12,
  5,
  10,
  5,
  12,
  9,
  6,
  0,
  0,
  0,
  9,
  9,
  0,
  6,
  4,
  0,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  14,
  8,
  14,
  10,
  14,
  14,
  14,
  8,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  0,
  0,
  12,
  15,
  15,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  15,
  3,
  15,
  12,
  12,
  8,
  6,
  0,
  0,
  0,
  6,
  9,
  9,
  9,
  9,
  9,
  9,
  9,
  0,
  4,
  10,
  4,
  11,
  11,
  6,
  4,
  12,
  12,
  4,
  4,
  11,
  6,
  6,
  9,
  6,
  6,
  6,
  12,
  14,
  12,
  14,
  14,
  8,
  11,
  8,
  12,
  5,
  5,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  4,
  12,
  4,
  12,
  11,
  12,
  8,
  8,
  6,
  0,
  0,
  6,
  9,
  11,
  9,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  11,
  12,
  12,
  15,
  11,
  11,
  5,
  5,
  5,
  5,
  10,
  5,
  5,
  5,
  5,
  5,
  5,
  5,
  3,
  5,
  10,
  11,
  11,
  0,
  0,
  0,
  0,
  9,
  9,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  8,
  14,
  8,
  14,
  14,
  14,
  14,
  9,
  0,
  0,
  6,
  6,
  6,
  6,
  0,
  0,
  0,
  4,
  12,
  15,
  15,
  3,
  3,
  3,
  3,
  3,
  3,
  3,
  15,
  3,
  15,
  15,
  15,
  12,
  8,
  8,
  0,
  0,
  0,
  6,
  6,
  9,
  9,
  9,
  9,
  9,
  6,
  0,
  6,
  4,
  10,
  4,
  11,
  6,
  4,
  12,
  4,
  12,
  4,
  11,
  6,
  6,
  10,
  2,
  6,
  6,
  12,
  12,
  14,
  14,
  14,
  8,
  11,
  11,
  8,
  12,
  5,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  12,
  4,
  12,
  12,
  4,
  8,
  12,
  8,
  6,
  0,
  0,
  6,
  6,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  11,
  12,
  6,
  11,
  12,
  12,
  10,
  10,
  5,
  10,
  5,
  10,
  5,
  10,
  5,
  12,
  5,
  10,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  9,
  8,
  14,
  14,
  14,
  14,
  14,
  8,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  6,
  12,
  12,
  15,
  15,
  3,
  15,
  3,
  15,
  3,
  15,
  3,
  15,
  3,
  15,
  15,
  15,
  12,
  8,
  6,
  0,
  0,
  0,
  6,
  6,
  9,
  9,
  9,
  9,
  9,
  0,
  4,
  10,
  4,
  4,
  11,
  11,
  6,
  4,
  12,
  4,
  4,
  11,
  11,
  6,
  10,
  10,
  2,
  6,
  8,
  14,
  12,
  14,
  14,
  14,
  11,
  6,
  11,
  8,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  4,
  12,
  12,
  6,
  0,
  0,
  6,
  9,
  6,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  0,
  6,
  11,
  12,
  12,
  10,
  10,
  5,
  10,
  5,
  10,
  5,
  10,
  12,
  3,
  12,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  12,
  4,
  0,
  0,
  0,
  6,
  14,
  8,
  14,
  14,
  14,
  14,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  4,
  12,
  12,
  15,
  15,
  3,
  15,
  3,
  15,
  3,
  15,
  3,
  15,
  15,
  15,
  12,
  12,
  12,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  9,
  9,
  6,
  0,
  6,
  10,
  10,
  4,
  11,
  11,
  6,
  4,
  4,
  4,
  4,
  4,
  11,
  6,
  15,
  10,
  10,
  2,
  4,
  12,
  12,
  12,
  14,
  14,
  8,
  6,
  6,
  11,
  8,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  12,
  12,
  12,
  4,
  4,
  8,
  3,
  8,
  6,
  6,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  11,
  11,
  12,
  10,
  10,
  5,
  10,
  5,
  10,
  5,
  11,
  5,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  1,
  12,
  6,
  0,
  0,
  11,
  8,
  14,
  8,
  14,
  14,
  14,
  8,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  4,
  4,
  12,
  12,
  15,
  15,
  15,
  15,
  12,
  12,
  3,
  15,
  15,
  15,
  12,
  15,
  12,
  12,
  4,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  0,
  4,
  10,
  4,
  10,
  4,
  11,
  6,
  6,
  4,
  4,
  4,
  4,
  11,
  6,
  10,
  10,
  4,
  2,
  11,
  12,
  12,
  12,
  14,
  14,
  8,
  11,
  6,
  6,
  11,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  12,
  4,
  4,
  4,
  4,
  12,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  9,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  10,
  10,
  10,
  10,
  10,
  10,
  12,
  5,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  0,
  0,
  6,
  1,
  12,
  4,
  0,
  0,
  6,
  11,
  8,
  14,
  8,
  14,
  14,
  9,
  11,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  4,
  4,
  12,
  12,
  15,
  15,
  12,
  4,
  12,
  12,
  15,
  15,
  15,
  15,
  12,
  15,
  12,
  6,
  8,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  6,
  10,
  10,
  4,
  4,
  11,
  11,
  6,
  11,
  4,
  4,
  4,
  11,
  6,
  15,
  10,
  10,
  2,
  6,
  12,
  12,
  12,
  14,
  14,
  14,
  11,
  6,
  6,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  4,
  12,
  12,
  4,
  4,
  4,
  3,
  12,
  8,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  12,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  10,
  10,
  10,
  10,
  10,
  11,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  4,
  6,
  6,
  0,
  6,
  12,
  12,
  14,
  0,
  0,
  0,
  11,
  11,
  8,
  14,
  8,
  14,
  8,
  11,
  0,
  0,
  0,
  11,
  11,
  11,
  11,
  6,
  0,
  0,
  6,
  4,
  4,
  12,
  12,
  15,
  4,
  15,
  15,
  12,
  12,
  15,
  15,
  12,
  15,
  12,
  12,
  4,
  8,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  10,
  4,
  10,
  4,
  4,
  11,
  6,
  6,
  11,
  11,
  11,
  11,
  6,
  10,
  15,
  10,
  4,
  6,
  12,
  12,
  4,
  4,
  14,
  14,
  8,
  11,
  6,
  6,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  12,
  4,
  12,
  12,
  4,
  12,
  3,
  12,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  11,
  8,
  11,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  12,
  10,
  10,
  12,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  1,
  4,
  6,
  0,
  0,
  4,
  12,
  14,
  6,
  0,
  0,
  6,
  11,
  11,
  11,
  11,
  8,
  9,
  11,
  0,
  0,
  0,
  11,
  12,
  12,
  12,
  11,
  6,
  0,
  0,
  6,
  4,
  4,
  12,
  12,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  4,
  12,
  8,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  10,
  4,
  4,
  11,
  11,
  6,
  0,
  6,
  6,
  11,
  11,
  6,
  15,
  10,
  10,
  2,
  6,
  12,
  12,
  4,
  4,
  4,
  14,
  12,
  8,
  11,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  4,
  12,
  12,
  8,
  3,
  3,
  8,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  9,
  12,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  11,
  0,
  0,
  0,
  0,
  0,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  11,
  11,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  1,
  1,
  4,
  6,
  0,
  6,
  4,
  14,
  14,
  6,
  0,
  0,
  11,
  6,
  0,
  6,
  11,
  8,
  11,
  0,
  0,
  0,
  11,
  12,
  1,
  1,
  12,
  11,
  6,
  0,
  0,
  4,
  4,
  4,
  12,
  4,
  12,
  12,
  12,
  12,
  12,
  15,
  12,
  15,
  12,
  4,
  12,
  12,
  4,
  4,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  6,
  6,
  4,
  10,
  4,
  4,
  11,
  6,
  6,
  0,
  0,
  6,
  11,
  6,
  15,
  15,
  10,
  4,
  6,
  12,
  12,
  4,
  11,
  4,
  4,
  14,
  8,
  8,
  11,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  4,
  12,
  8,
  3,
  3,
  12,
  8,
  6,
  6,
  0,
  0,
  0,
  6,
  6,
  9,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  8,
  0,
  6,
  8,
  8,
  0,
  8,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  6,
  6,
  4,
  1,
  12,
  4,
  0,
  0,
  6,
  4,
  4,
  4,
  6,
  0,
  6,
  0,
  6,
  0,
  0,
  6,
  11,
  6,
  0,
  0,
  11,
  12,
  1,
  1,
  12,
  12,
  11,
  0,
  0,
  0,
  4,
  4,
  4,
  4,
  15,
  12,
  12,
  12,
  15,
  12,
  15,
  12,
  12,
  4,
  12,
  12,
  12,
  4,
  6,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  4,
  4,
  11,
  4,
  6,
  6,
  6,
  0,
  0,
  6,
  6,
  15,
  15,
  10,
  2,
  6,
  8,
  12,
  4,
  6,
  11,
  4,
  14,
  12,
  8,
  8,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  4,
  4,
  4,
  12,
  4,
  5,
  1,
  3,
  12,
  8,
  6,
  6,
  0,
  0,
  6,
  9,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  8,
  0,
  8,
  0,
  0,
  0,
  11,
  0,
  8,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  8,
  6,
  0,
  0,
  6,
  6,
  4,
  12,
  4,
  0,
  0,
  0,
  6,
  4,
  4,
  4,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  6,
  6,
  0,
  0,
  6,
  12,
  15,
  1,
  1,
  12,
  12,
  11,
  0,
  0,
  6,
  4,
  4,
  4,
  12,
  12,
  4,
  12,
  12,
  12,
  12,
  12,
  4,
  12,
  12,
  12,
  4,
  4,
  6,
  0,
  0,
  6,
  12,
  12,
  4,
  6,
  6,
  6,
  4,
  4,
  4,
  4,
  11,
  6,
  6,
  9,
  6,
  0,
  0,
  6,
  15,
  15,
  10,
  4,
  6,
  11,
  12,
  4,
  6,
  6,
  11,
  14,
  14,
  8,
  8,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  4,
  12,
  12,
  4,
  12,
  3,
  3,
  5,
  8,
  11,
  6,
  0,
  0,
  0,
  6,
  9,
  0,
  0,
  0,
  0,
  6,
  6,
  8,
  0,
  8,
  0,
  6,
  11,
  6,
  0,
  8,
  8,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  8,
  12,
  6,
  0,
  0,
  6,
  6,
  4,
  4,
  6,
  0,
  0,
  0,
  6,
  4,
  4,
  0,
  0,
  0,
  6,
  6,
  11,
  6,
  0,
  6,
  0,
  0,
  6,
  11,
  12,
  15,
  1,
  15,
  12,
  11,
  11,
  0,
  0,
  4,
  4,
  4,
  4,
  12,
  12,
  4,
  12,
  4,
  12,
  4,
  12,
  12,
  12,
  4,
  12,
  4,
  4,
  0,
  0,
  6,
  12,
  12,
  12,
  4,
  6,
  6,
  6,
  4,
  4,
  11,
  4,
  11,
  6,
  12,
  9,
  6,
  0,
  6,
  15,
  10,
  10,
  4,
  6,
  11,
  12,
  4,
  6,
  6,
  6,
  4,
  14,
  12,
  8,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  14,
  12,
  12,
  12,
  12,
  4,
  4,
  8,
  5,
  3,
  5,
  8,
  11,
  6,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  0,
  11,
  0,
  11,
  0,
  0,
  0,
  0,
  0,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  8,
  12,
  12,
  0,
  0,
  0,
  6,
  6,
  4,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  6,
  11,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  11,
  12,
  12,
  15,
  15,
  12,
  12,
  11,
  11,
  0,
  0,
  4,
  4,
  4,
  4,
  4,
  4,
  4,
  12,
  4,
  12,
  12,
  12,
  4,
  12,
  4,
  12,
  4,
  6,
  0,
  6,
  12,
  3,
  12,
  4,
  6,
  6,
  6,
  4,
  4,
  4,
  11,
  11,
  6,
  8,
  12,
  9,
  6,
  6,
  10,
  15,
  10,
  4,
  6,
  6,
  12,
  4,
  2,
  6,
  6,
  11,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  12,
  4,
  12,
  5,
  12,
  8,
  12,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  6,
  6,
  8,
  0,
  6,
  0,
  8,
  8,
  8,
  8,
  6,
  0,
  8,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  6,
  0,
  6,
  12,
  15,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  12,
  15,
  12,
  11,
  6,
  0,
  0,
  0,
  11,
  11,
  12,
  12,
  15,
  15,
  12,
  12,
  11,
  11,
  0,
  6,
  4,
  4,
  4,
  4,
  12,
  4,
  6,
  12,
  4,
  12,
  4,
  12,
  4,
  12,
  4,
  4,
  6,
  0,
  6,
  3,
  7,
  3,
  4,
  4,
  6,
  0,
  6,
  4,
  11,
  4,
  11,
  6,
  8,
  8,
  12,
  9,
  6,
  10,
  10,
  8,
  4,
  6,
  6,
  8,
  4,
  4,
  2,
  6,
  6,
  4,
  4,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  4,
  8,
  12,
  8,
  12,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  6,
  6,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  4,
  1,
  4,
  0,
  0,
  12,
  15,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  15,
  12,
  11,
  6,
  0,
  0,
  11,
  11,
  12,
  12,
  12,
  15,
  12,
  12,
  12,
  11,
  0,
  0,
  6,
  6,
  4,
  4,
  4,
  6,
  4,
  4,
  12,
  4,
  12,
  4,
  12,
  4,
  12,
  4,
  6,
  0,
  6,
  3,
  7,
  12,
  12,
  4,
  6,
  6,
  0,
  6,
  4,
  11,
  11,
  6,
  8,
  8,
  8,
  4,
  9,
  10,
  10,
  8,
  4,
  11,
  6,
  4,
  12,
  4,
  2,
  6,
  6,
  6,
  11,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  12,
  12,
  12,
  4,
  12,
  4,
  4,
  8,
  8,
  12,
  8,
  8,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  4,
  0,
  6,
  6,
  11,
  0,
  11,
  8,
  8,
  8,
  8,
  8,
  8,
  11,
  6,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  4,
  12,
  4,
  0,
  0,
  6,
  12,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  6,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  6,
  12,
  15,
  12,
  15,
  12,
  11,
  0,
  0,
  6,
  11,
  11,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  11,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  4,
  4,
  4,
  4,
  4,
  4,
  4,
  4,
  4,
  4,
  6,
  0,
  0,
  12,
  3,
  3,
  12,
  4,
  4,
  6,
  6,
  0,
  6,
  11,
  6,
  6,
  10,
  8,
  8,
  4,
  4,
  8,
  8,
  4,
  4,
  11,
  6,
  11,
  12,
  4,
  4,
  6,
  6,
  6,
  11,
  4,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  12,
  14,
  12,
  12,
  12,
  4,
  4,
  12,
  4,
  8,
  8,
  8,
  12,
  8,
  11,
  6,
  6,
  0,
  0,
  0,
  6,
  4,
  0,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  6,
  0,
  0,
  4,
  4,
  2,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  6,
  8,
  11,
  11,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  15,
  1,
  15,
  12,
  6,
  0,
  0,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  12,
  12,
  12,
  11,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  4,
  4,
  4,
  4,
  4,
  4,
  4,
  4,
  6,
  0,
  0,
  6,
  3,
  12,
  12,
  4,
  4,
  6,
  6,
  6,
  0,
  6,
  6,
  6,
  10,
  8,
  8,
  4,
  4,
  8,
  8,
  8,
  4,
  4,
  6,
  11,
  4,
  12,
  4,
  2,
  6,
  6,
  6,
  11,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  4,
  14,
  12,
  12,
  4,
  4,
  11,
  11,
  8,
  8,
  8,
  12,
  14,
  11,
  6,
  0,
  0,
  0,
  6,
  4,
  0,
  6,
  6,
  11,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  11,
  12,
  11,
  0,
  0,
  0,
  0,
  6,
  4,
  12,
  4,
  6,
  0,
  6,
  2,
  2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  6,
  0,
  0,
  6,
  11,
  8,
  11,
  6,
  0,
  0,
  0,
  6,
  12,
  11,
  12,
  1,
  15,
  15,
  11,
  6,
  0,
  6,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  12,
  11,
  12,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  4,
  4,
  4,
  4,
  6,
  6,
  0,
  6,
  6,
  12,
  3,
  12,
  14,
  4,
  4,
  11,
  6,
  6,
  0,
  6,
  6,
  15,
  8,
  8,
  4,
  4,
  8,
  8,
  4,
  4,
  4,
  11,
  6,
  4,
  12,
  8,
  4,
  6,
  6,
  6,
  11,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  12,
  4,
  4,
  11,
  11,
  8,
  8,
  12,
  12,
  11,
  6,
  6,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  6,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  9,
  0,
  6,
  12,
  15,
  15,
  6,
  0,
  0,
  0,
  6,
  12,
  12,
  12,
  4,
  6,
  0,
  6,
  2,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  12,
  11,
  6,
  0,
  6,
  8,
  8,
  8,
  11,
  6,
  0,
  0,
  6,
  11,
  12,
  15,
  15,
  15,
  15,
  15,
  11,
  0,
  6,
  6,
  11,
  11,
  11,
  12,
  12,
  12,
  12,
  12,
  11,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  0,
  0,
  6,
  0,
  12,
  12,
  12,
  14,
  4,
  4,
  11,
  6,
  6,
  6,
  0,
  6,
  15,
  8,
  8,
  4,
  4,
  4,
  8,
  8,
  4,
  4,
  4,
  6,
  11,
  12,
  12,
  4,
  2,
  6,
  6,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  12,
  12,
  4,
  4,
  6,
  4,
  11,
  11,
  11,
  12,
  12,
  12,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  0,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  9,
  4,
  0,
  6,
  12,
  12,
  15,
  6,
  0,
  0,
  0,
  6,
  12,
  5,
  12,
  4,
  6,
  0,
  0,
  6,
  0,
  0,
  6,
  4,
  0,
  0,
  0,
  6,
  12,
  12,
  11,
  0,
  0,
  11,
  4,
  12,
  8,
  11,
  6,
  0,
  0,
  6,
  11,
  12,
  15,
  1,
  15,
  12,
  15,
  6,
  0,
  6,
  6,
  6,
  11,
  11,
  12,
  12,
  12,
  12,
  12,
  11,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  6,
  12,
  14,
  12,
  14,
  4,
  4,
  6,
  6,
  9,
  6,
  6,
  15,
  8,
  8,
  4,
  4,
  4,
  4,
  2,
  4,
  2,
  4,
  6,
  11,
  12,
  12,
  4,
  4,
  2,
  6,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  14,
  14,
  12,
  14,
  4,
  12,
  12,
  4,
  4,
  6,
  4,
  11,
  2,
  14,
  12,
  14,
  14,
  8,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  6,
  11,
  11,
  6,
  0,
  0,
  0,
  9,
  12,
  4,
  0,
  6,
  12,
  15,
  15,
  6,
  0,
  0,
  0,
  6,
  5,
  5,
  5,
  4,
  6,
  0,
  0,
  0,
  0,
  0,
  2,
  4,
  2,
  0,
  0,
  6,
  12,
  12,
  11,
  0,
  0,
  11,
  8,
  1,
  12,
  8,
  11,
  0,
  0,
  6,
  11,
  11,
  12,
  15,
  15,
  15,
  12,
  11,
  6,
  0,
  6,
  6,
  6,
  6,
  11,
  12,
  11,
  12,
  11,
  12,
  0,
  6,
  12,
  4,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  6,
  14,
  12,
  14,
  14,
  4,
  4,
  6,
  6,
  4,
  9,
  6,
  10,
  8,
  4,
  8,
  9,
  4,
  9,
  4,
  2,
  4,
  2,
  11,
  11,
  4,
  12,
  8,
  4,
  2,
  6,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  12,
  12,
  4,
  4,
  6,
  4,
  11,
  2,
  14,
  14,
  14,
  14,
  4,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  11,
  11,
  6,
  0,
  0,
  12,
  4,
  9,
  0,
  6,
  12,
  12,
  15,
  6,
  0,
  0,
  0,
  6,
  5,
  1,
  5,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  2,
  4,
  2,
  0,
  0,
  6,
  12,
  5,
  12,
  0,
  0,
  11,
  4,
  8,
  8,
  4,
  4,
  6,
  0,
  0,
  6,
  12,
  15,
  12,
  15,
  15,
  15,
  12,
  11,
  0,
  0,
  6,
  6,
  6,
  6,
  11,
  12,
  11,
  12,
  11,
  0,
  6,
  12,
  12,
  12,
  6,
  0,
  0,
  0,
  0,
  15,
  15,
  15,
  15,
  15,
  15,
  11,
  11,
  6,
  0,
  12,
  14,
  12,
  14,
  14,
  4,
  11,
  6,
  10,
  8,
  6,
  15,
  8,
  8,
  4,
  4,
  9,
  4,
  9,
  2,
  2,
  4,
  2,
  6,
  11,
  12,
  12,
  4,
  4,
  2,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  12,
  4,
  12,
  4,
  6,
  4,
  4,
  11,
  11,
  2,
  8,
  8,
  8,
  8,
  4,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  11,
  4,
  6,
  0,
  0,
  12,
  9,
  9,
  0,
  6,
  12,
  15,
  15,
  6,
  0,
  0,
  0,
  0,
  6,
  5,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  0,
  6,
  12,
  5,
  12,
  6,
  0,
  6,
  11,
  4,
  4,
  4,
  12,
  11,
  0,
  0,
  6,
  11,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  6,
  0,
  0,
  6,
  6,
  6,
  11,
  11,
  12,
  11,
  12,
  0,
  6,
  12,
  7,
  15,
  12,
  0,
  0,
  0,
  0,
  15,
  7,
  7,
  7,
  15,
  15,
  12,
  11,
  6,
  0,
  14,
  12,
  14,
  14,
  14,
  4,
  11,
  6,
  15,
  10,
  10,
  10,
  8,
  4,
  8,
  4,
  4,
  9,
  9,
  9,
  2,
  2,
  4,
  6,
  11,
  8,
  12,
  4,
  4,
  2,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  4,
  4,
  4,
  12,
  4,
  6,
  6,
  4,
  11,
  4,
  4,
  4,
  8,
  14,
  8,
  4,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  4,
  6,
  0,
  0,
  4,
  9,
  6,
  0,
  6,
  11,
  12,
  15,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  2,
  2,
  0,
  0,
  0,
  11,
  5,
  12,
  11,
  0,
  0,
  11,
  11,
  11,
  8,
  8,
  8,
  6,
  0,
  0,
  6,
  11,
  12,
  15,
  12,
  15,
  12,
  15,
  11,
  0,
  0,
  0,
  6,
  6,
  6,
  11,
  11,
  12,
  11,
  0,
  6,
  12,
  7,
  7,
  12,
  4,
  0,
  0,
  0,
  15,
  7,
  7,
  7,
  7,
  15,
  12,
  11,
  11,
  0,
  6,
  14,
  12,
  14,
  14,
  4,
  11,
  6,
  1,
  10,
  10,
  10,
  4,
  8,
  4,
  4,
  9,
  4,
  9,
  9,
  9,
  4,
  2,
  11,
  6,
  11,
  12,
  12,
  4,
  4,
  11,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  12,
  4,
  12,
  4,
  12,
  4,
  4,
  6,
  6,
  4,
  2,
  4,
  2,
  4,
  8,
  12,
  12,
  4,
  6,
  6,
  0,
  0,
  0,
  6,
  11,
  11,
  4,
  6,
  0,
  0,
  4,
  9,
  9,
  0,
  6,
  12,
  11,
  12,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  2,
  4,
  4,
  6,
  0,
  0,
  11,
  12,
  5,
  11,
  0,
  0,
  11,
  11,
  4,
  4,
  8,
  4,
  11,
  0,
  0,
  6,
  6,
  11,
  12,
  15,
  12,
  15,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  11,
  11,
  0,
  6,
  12,
  7,
  7,
  12,
  12,
  0,
  0,
  0,
  15,
  15,
  7,
  7,
  7,
  15,
  15,
  11,
  11,
  6,
  0,
  12,
  14,
  14,
  4,
  4,
  11,
  6,
  1,
  10,
  10,
  10,
  8,
  4,
  8,
  4,
  4,
  9,
  9,
  9,
  9,
  2,
  4,
  2,
  6,
  11,
  11,
  12,
  4,
  11,
  4,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  4,
  4,
  4,
  12,
  4,
  6,
  4,
  6,
  6,
  4,
  2,
  4,
  2,
  12,
  12,
  8,
  8,
  8,
  4,
  6,
  0,
  0,
  0,
  6,
  11,
  4,
  6,
  0,
  0,
  2,
  9,
  6,
  0,
  6,
  11,
  12,
  15,
  6,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  4,
  6,
  0,
  2,
  4,
  2,
  4,
  0,
  0,
  6,
  12,
  13,
  12,
  6,
  0,
  6,
  11,
  11,
  4,
  4,
  8,
  4,
  6,
  0,
  0,
  6,
  6,
  11,
  12,
  15,
  12,
  11,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  6,
  12,
  7,
  7,
  15,
  12,
  0,
  0,
  6,
  12,
  15,
  7,
  7,
  7,
  15,
  12,
  12,
  11,
  6,
  0,
  14,
  14,
  4,
  14,
  4,
  11,
  6,
  15,
  10,
  10,
  8,
  4,
  8,
  4,
  8,
  4,
  4,
  9,
  9,
  6,
  9,
  2,
  4,
  11,
  6,
  11,
  4,
  12,
  4,
  11,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  4,
  12,
  4,
  4,
  4,
  6,
  4,
  6,
  6,
  4,
  2,
  4,
  12,
  8,
  4,
  8,
  8,
  8,
  4,
  6,
  0,
  0,
  0,
  6,
  11,
  6,
  0,
  0,
  4,
  9,
  9,
  0,
  6,
  12,
  11,
  12,
  6,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  2,
  4,
  4,
  0,
  2,
  2,
  4,
  2,
  0,
  0,
  6,
  12,
  13,
  13,
  11,
  0,
  0,
  6,
  11,
  11,
  4,
  4,
  4,
  11,
  6,
  0,
  0,
  6,
  11,
  11,
  12,
  11,
  12,
  11,
  11,
  6,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  12,
  7,
  7,
  7,
  12,
  4,
  0,
  6,
  12,
  15,
  15,
  7,
  15,
  15,
  15,
  12,
  11,
  6,
  0,
  4,
  4,
  14,
  4,
  4,
  11,
  9,
  10,
  10,
  8,
  4,
  8,
  4,
  4,
  4,
  4,
  9,
  4,
  9,
  6,
  9,
  9,
  2,
  2,
  6,
  11,
  11,
  12,
  12,
  4,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  4,
  12,
  4,
  4,
  6,
  4,
  6,
  6,
  4,
  2,
  12,
  4,
  8,
  4,
  8,
  8,
  8,
  4,
  6,
  6,
  0,
  0,
  6,
  6,
  0,
  0,
  2,
  9,
  6,
  0,
  6,
  11,
  12,
  11,
  6,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  2,
  4,
  0,
  2,
  4,
  2,
  4,
  0,
  0,
  0,
  11,
  5,
  1,
  11,
  6,
  0,
  0,
  11,
  11,
  11,
  4,
  11,
  11,
  11,
  0,
  0,
  6,
  6,
  11,
  11,
  12,
  11,
  12,
  11,
  6,
  0,
  0,
  6,
  6,
  6,
  6,
  0,
  0,
  0,
  6,
  12,
  15,
  7,
  7,
  15,
  12,
  6,
  6,
  12,
  15,
  15,
  15,
  15,
  15,
  12,
  12,
  11,
  6,
  0,
  6,
  4,
  4,
  14,
  4,
  11,
  9,
  8,
  10,
  10,
  8,
  4,
  4,
  4,
  4,
  2,
  4,
  9,
  9,
  6,
  6,
  9,
  2,
  2,
  11,
  11,
  11,
  11,
  12,
  4,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  4,
  12,
  4,
  4,
  6,
  4,
  6,
  6,
  4,
  12,
  8,
  4,
  8,
  8,
  8,
  4,
  8,
  4,
  6,
  6,
  0,
  0,
  6,
  0,
  0,
  9,
  9,
  6,
  0,
  6,
  6,
  11,
  12,
  6,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  2,
  4,
  2,
  6,
  6,
  2,
  4,
  2,
  6,
  0,
  0,
  11,
  5,
  13,
  12,
  11,
  0,
  0,
  6,
  11,
  11,
  11,
  11,
  11,
  6,
  0,
  0,
  0,
  6,
  6,
  11,
  11,
  12,
  11,
  11,
  6,
  6,
  0,
  6,
  6,
  9,
  9,
  6,
  0,
  0,
  6,
  12,
  15,
  7,
  7,
  15,
  15,
  11,
  6,
  6,
  12,
  15,
  15,
  15,
  15,
  15,
  12,
  11,
  6,
  0,
  0,
  6,
  4,
  4,
  11,
  11,
  9,
  8,
  10,
  8,
  4,
  8,
  4,
  4,
  2,
  4,
  2,
  4,
  9,
  9,
  6,
  9,
  9,
  2,
  2,
  6,
  11,
  11,
  11,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  12,
  12,
  4,
  4,
  4,
  4,
  6,
  6,
  6,
  4,
  8,
  4,
  4,
  4,
  8,
  12,
  8,
  8,
  8,
  4,
  6,
  6,
  0,
  0,
  0,
  0,
  2,
  9,
  6,
  0,
  6,
  11,
  12,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  2,
  2,
  4,
  0,
  2,
  6,
  2,
  2,
  2,
  0,
  0,
  6,
  12,
  5,
  12,
  11,
  0,
  0,
  0,
  6,
  11,
  11,
  11,
  6,
  11,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  11,
  11,
  6,
  6,
  6,
  0,
  6,
  9,
  9,
  9,
  9,
  6,
  6,
  6,
  12,
  12,
  15,
  7,
  15,
  12,
  12,
  11,
  6,
  15,
  12,
  15,
  15,
  15,
  12,
  12,
  11,
  6,
  0,
  0,
  6,
  2,
  11,
  11,
  11,
  9,
  9,
  10,
  10,
  8,
  4,
  8,
  4,
  4,
  2,
  4,
  2,
  9,
  9,
  6,
  6,
  9,
  9,
  2,
  2,
  2,
  11,
  11,
  4,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  12,
  14,
  4,
  14,
  12,
  12,
  12,
  4,
  4,
  4,
  4,
  6,
  6,
  6,
  8,
  4,
  4,
  4,
  4,
  8,
  12,
  8,
  12,
  8,
  4,
  6,
  0,
  0,
  0,
  0,
  9,
  9,
  6,
  0,
  6,
  6,
  11,
  12,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  4,
  2,
  6,
  6,
  2,
  2,
  2,
  2,
  0,
  0,
  6,
  12,
  5,
  5,
  12,
  6,
  0,
  0,
  0,
  6,
  11,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  0,
  6,
  9,
  2,
  2,
  8,
  12,
  6,
  6,
  6,
  15,
  15,
  15,
  15,
  15,
  12,
  11,
  6,
  12,
  15,
  12,
  15,
  12,
  15,
  12,
  11,
  6,
  0,
  6,
  0,
  6,
  2,
  11,
  11,
  9,
  6,
  10,
  10,
  10,
  8,
  4,
  4,
  2,
  4,
  2,
  4,
  2,
  9,
  9,
  6,
  6,
  9,
  9,
  2,
  2,
  2,
  11,
  11,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  14,
  12,
  4,
  12,
  4,
  4,
  6,
  6,
  6,
  6,
  12,
  4,
  4,
  6,
  4,
  4,
  12,
  8,
  12,
  12,
  8,
  4,
  0,
  0,
  0,
  0,
  2,
  9,
  6,
  0,
  0,
  11,
  6,
  11,
  6,
  0,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  2,
  2,
  4,
  0,
  2,
  6,
  2,
  2,
  2,
  0,
  0,
  0,
  11,
  12,
  5,
  12,
  11,
  0,
  0,
  0,
  0,
  6,
  11,
  6,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  0,
  0,
  6,
  9,
  9,
  2,
  8,
  10,
  12,
  9,
  6,
  12,
  15,
  7,
  15,
  12,
  12,
  12,
  6,
  4,
  12,
  15,
  12,
  15,
  12,
  12,
  11,
  6,
  0,
  9,
  6,
  0,
  6,
  9,
  9,
  9,
  6,
  10,
  10,
  8,
  4,
  8,
  4,
  4,
  2,
  4,
  2,
  4,
  2,
  9,
  9,
  6,
  6,
  9,
  9,
  2,
  2,
  2,
  11,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  4,
  4,
  12,
  4,
  6,
  6,
  6,
  8,
  4,
  6,
  4,
  6,
  4,
  8,
  8,
  12,
  7,
  12,
  6,
  6,
  6,
  0,
  0,
  9,
  9,
  6,
  0,
  0,
  6,
  11,
  11,
  6,
  0,
  6,
  11,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  2,
  2,
  6,
  6,
  2,
  6,
  2,
  2,
  0,
  0,
  0,
  11,
  12,
  5,
  12,
  11,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  6,
  9,
  2,
  2,
  8,
  10,
  10,
  12,
  6,
  15,
  12,
  15,
  15,
  15,
  12,
  11,
  6,
  6,
  12,
  12,
  15,
  12,
  15,
  11,
  11,
  6,
  0,
  8,
  8,
  6,
  0,
  6,
  9,
  9,
  6,
  10,
  10,
  10,
  8,
  4,
  8,
  4,
  4,
  2,
  4,
  2,
  4,
  2,
  9,
  9,
  6,
  6,
  9,
  9,
  2,
  2,
  2,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  12,
  14,
  14,
  14,
  4,
  12,
  12,
  4,
  4,
  4,
  4,
  6,
  4,
  4,
  4,
  4,
  6,
  6,
  6,
  4,
  8,
  12,
  12,
  12,
  4,
  6,
  6,
  6,
  0,
  6,
  9,
  6,
  0,
  0,
  6,
  6,
  11,
  6,
  0,
  6,
  14,
  8,
  6,
  6,
  0,
  0,
  0,
  0,
  2,
  6,
  2,
  6,
  6,
  6,
  2,
  2,
  2,
  0,
  0,
  0,
  6,
  11,
  12,
  5,
  12,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  9,
  2,
  8,
  10,
  10,
  12,
  6,
  12,
  15,
  12,
  15,
  12,
  12,
  12,
  6,
  6,
  4,
  12,
  12,
  15,
  12,
  11,
  6,
  6,
  8,
  12,
  8,
  9,
  6,
  0,
  6,
  9,
  6,
  10,
  10,
  8,
  10,
  8,
  4,
  4,
  4,
  4,
  2,
  4,
  2,
  4,
  2,
  9,
  9,
  6,
  6,
  9,
  9,
  9,
  2,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  12,
  14,
  12,
  14,
  12,
  4,
  12,
  4,
  4,
  4,
  4,
  4,
  8,
  4,
  6,
  6,
  6,
  6,
  6,
  8,
  8,
  12,
  8,
  6,
  6,
  4,
  4,
  6,
  0,
  6,
  6,
  0,
  0,
  6,
  11,
  6,
  6,
  0,
  6,
  3,
  12,
  8,
  6,
  6,
  0,
  0,
  0,
  6,
  2,
  2,
  2,
  6,
  2,
  6,
  2,
  2,
  6,
  0,
  0,
  0,
  11,
  12,
  5,
  12,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  2,
  2,
  8,
  8,
  10,
  12,
  9,
  6,
  12,
  15,
  12,
  15,
  12,
  11,
  11,
  6,
  6,
  4,
  12,
  12,
  11,
  11,
  6,
  6,
  12,
  12,
  8,
  6,
  9,
  6,
  6,
  6,
  6,
  8,
  10,
  10,
  8,
  8,
  8,
  4,
  4,
  4,
  4,
  2,
  4,
  2,
  4,
  2,
  9,
  9,
  6,
  6,
  9,
  9,
  9,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  14,
  12,
  12,
  12,
  4,
  12,
  4,
  12,
  4,
  8,
  4,
  4,
  6,
  6,
  6,
  6,
  4,
  8,
  8,
  8,
  8,
  6,
  4,
  8,
  8,
  8,
  6,
  0,
  6,
  0,
  0,
  0,
  6,
  11,
  6,
  0,
  6,
  14,
  14,
  12,
  12,
  8,
  6,
  0,
  0,
  0,
  6,
  2,
  6,
  6,
  6,
  2,
  6,
  2,
  6,
  0,
  0,
  0,
  11,
  11,
  12,
  12,
  11,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  0,
  6,
  6,
  12,
  6,
  6,
  0,
  0,
  0,
  6,
  9,
  9,
  2,
  2,
  8,
  8,
  12,
  9,
  6,
  12,
  12,
  15,
  12,
  12,
  12,
  11,
  6,
  6,
  4,
  11,
  12,
  11,
  11,
  6,
  8,
  15,
  12,
  8,
  9,
  6,
  9,
  6,
  6,
  6,
  9,
  10,
  10,
  10,
  8,
  4,
  8,
  4,
  4,
  2,
  4,
  2,
  2,
  2,
  4,
  2,
  9,
  9,
  6,
  6,
  6,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  12,
  12,
  4,
  4,
  12,
  4,
  12,
  4,
  6,
  6,
  6,
  6,
  6,
  6,
  8,
  8,
  4,
  8,
  4,
  8,
  12,
  12,
  8,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  6,
  0,
  6,
  11,
  14,
  14,
  8,
  6,
  0,
  0,
  0,
  0,
  2,
  6,
  2,
  6,
  2,
  6,
  2,
  2,
  6,
  0,
  0,
  0,
  6,
  11,
  12,
  12,
  12,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  6,
  0,
  0,
  6,
  12,
  15,
  12,
  6,
  6,
  0,
  0,
  6,
  6,
  9,
  9,
  2,
  2,
  8,
  8,
  2,
  9,
  6,
  12,
  12,
  12,
  12,
  11,
  12,
  11,
  6,
  6,
  11,
  11,
  11,
  6,
  6,
  12,
  15,
  12,
  8,
  2,
  9,
  6,
  9,
  6,
  6,
  6,
  8,
  10,
  10,
  10,
  8,
  4,
  8,
  4,
  4,
  2,
  4,
  2,
  2,
  2,
  4,
  2,
  9,
  9,
  6,
  6,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  14,
  12,
  4,
  12,
  4,
  12,
  4,
  4,
  4,
  8,
  4,
  4,
  6,
  6,
  6,
  6,
  6,
  8,
  4,
  6,
  4,
  6,
  4,
  10,
  10,
  12,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  0,
  0,
  6,
  11,
  11,
  8,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  2,
  6,
  6,
  6,
  2,
  6,
  2,
  6,
  0,
  0,
  0,
  0,
  11,
  11,
  12,
  12,
  11,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  12,
  11,
  6,
  0,
  6,
  12,
  12,
  15,
  12,
  6,
  6,
  0,
  0,
  6,
  9,
  9,
  9,
  2,
  2,
  8,
  2,
  9,
  6,
  11,
  12,
  12,
  11,
  12,
  11,
  11,
  6,
  6,
  6,
  11,
  6,
  6,
  6,
  1,
  12,
  12,
  2,
  9,
  2,
  9,
  6,
  9,
  6,
  6,
  8,
  10,
  10,
  8,
  8,
  8,
  4,
  8,
  4,
  4,
  2,
  2,
  9,
  2,
  2,
  2,
  2,
  9,
  9,
  9,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  12,
  14,
  14,
  14,
  12,
  12,
  12,
  4,
  12,
  4,
  4,
  12,
  4,
  6,
  6,
  6,
  6,
  6,
  6,
  8,
  6,
  6,
  6,
  6,
  8,
  10,
  7,
  10,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  11,
  11,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  2,
  6,
  6,
  6,
  2,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  11,
  11,
  12,
  12,
  6,
  0,
  0,
  0,
  6,
  11,
  12,
  5,
  12,
  11,
  0,
  6,
  12,
  15,
  12,
  15,
  6,
  6,
  0,
  0,
  6,
  6,
  9,
  2,
  9,
  2,
  2,
  2,
  9,
  6,
  6,
  11,
  11,
  12,
  11,
  12,
  11,
  6,
  6,
  6,
  6,
  6,
  6,
  8,
  15,
  12,
  8,
  4,
  2,
  9,
  2,
  9,
  6,
  9,
  6,
  9,
  8,
  10,
  10,
  8,
  8,
  8,
  4,
  8,
  4,
  4,
  2,
  2,
  9,
  2,
  2,
  2,
  2,
  9,
  9,
  9,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  12,
  14,
  14,
  12,
  14,
  14,
  12,
  12,
  12,
  4,
  12,
  4,
  12,
  4,
  4,
  6,
  6,
  6,
  6,
  6,
  4,
  4,
  6,
  6,
  6,
  4,
  8,
  7,
  10,
  6,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  11,
  9,
  11,
  6,
  0,
  0,
  6,
  0,
  0,
  0,
  2,
  6,
  6,
  6,
  2,
  6,
  2,
  6,
  0,
  6,
  0,
  0,
  0,
  6,
  11,
  12,
  12,
  11,
  0,
  0,
  6,
  11,
  12,
  15,
  15,
  12,
  11,
  0,
  6,
  12,
  12,
  15,
  12,
  12,
  6,
  6,
  0,
  6,
  6,
  9,
  9,
  2,
  9,
  2,
  9,
  2,
  9,
  6,
  6,
  11,
  11,
  12,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  8,
  15,
  12,
  12,
  4,
  2,
  4,
  2,
  9,
  2,
  9,
  6,
  9,
  6,
  8,
  8,
  10,
  8,
  8,
  8,
  8,
  4,
  8,
  4,
  4,
  2,
  2,
  9,
  9,
  2,
  2,
  2,
  9,
  9,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  12,
  4,
  12,
  4,
  12,
  4,
  12,
  12,
  4,
  6,
  4,
  6,
  6,
  6,
  4,
  8,
  6,
  6,
  6,
  0,
  4,
  4,
  10,
  8,
  6,
  8,
  4,
  4,
  6,
  0,
  0,
  0,
  0,
  0,
  6,
  9,
  11,
  9,
  6,
  0,
  0,
  12,
  0,
  0,
  0,
  6,
  2,
  6,
  6,
  6,
  2,
  6,
  6,
  0,
  4,
  6,
  0,
  0,
  0,
  11,
  11,
  12,
  11,
  6,
  0,
  11,
  12,
  15,
  1,
  12,
  11,
  6,
  0,
  6,
  12,
  15,
  15,
  15,
  12,
  6,
  6,
  0,
  0,
  6,
  6,
  9,
  9,
  2,
  9,
  2,
  9,
  2,
  9,
  6,
  6,
  11,
  11,
  11,
  6,
  6,
  6,
  6,
  6,
  11,
  12,
  12,
  12,
  8,
  2,
  4,
  2,
  4,
  2,
  9,
  9,
  9,
  6,
  9,
  6,
  8,
  8,
  10,
  8,
  8,
  8,
  8,
  4,
  8,
  4,
  2,
  2,
  2,
  9,
  9,
  2,
  2,
  2,
  9,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  4,
  12,
  4,
  12,
  12,
  4,
  6,
  4,
  6,
  4,
  8,
  4,
  6,
  6,
  0,
  0,
  4,
  4,
  8,
  8,
  4,
  8,
  8,
  4,
  4,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  9,
  11,
  6,
  0,
  0,
  5,
  6,
  0,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  0,
  4,
  12,
  6,
  0,
  0,
  6,
  11,
  11,
  11,
  6,
  0,
  11,
  12,
  3,
  12,
  11,
  6,
  6,
  0,
  6,
  12,
  15,
  15,
  15,
  15,
  12,
  6,
  6,
  0,
  6,
  6,
  9,
  9,
  9,
  2,
  9,
  2,
  9,
  9,
  9,
  6,
  6,
  6,
  6,
  6,
  6,
  0,
  6,
  11,
  12,
  12,
  12,
  8,
  4,
  4,
  2,
  4,
  2,
  4,
  2,
  9,
  6,
  9,
  6,
  9,
  6,
  8,
  8,
  10,
  8,
  8,
  8,
  8,
  4,
  4,
  4,
  2,
  2,
  2,
  9,
  9,
  9,
  9,
  9,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  14,
  4,
  12,
  4,
  12,
  4,
  12,
  12,
  4,
  4,
  4,
  6,
  4,
  6,
  4,
  6,
  6,
  6,
  0,
  0,
  4,
  4,
  4,
  6,
  8,
  8,
  12,
  8,
  4,
  4,
  6,
  0,
  0,
  0,
  6,
  6,
  11,
  9,
  6,
  0,
  0,
  5,
  12,
  6,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  0,
  4,
  12,
  12,
  6,
  0,
  0,
  6,
  11,
  11,
  6,
  0,
  11,
  11,
  12,
  11,
  6,
  6,
  6,
  0,
  6,
  12,
  15,
  15,
  15,
  15,
  15,
  6,
  6,
  6,
  0,
  6,
  6,
  9,
  9,
  9,
  2,
  9,
  2,
  9,
  9,
  0,
  0,
  6,
  6,
  6,
  0,
  6,
  11,
  12,
  12,
  12,
  8,
  2,
  4,
  4,
  4,
  2,
  4,
  2,
  4,
  2,
  9,
  9,
  9,
  6,
  9,
  6,
  8,
  8,
  10,
  8,
  8,
  8,
  8,
  4,
  4,
  4,
  2,
  2,
  2,
  9,
  6,
  6,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  12,
  14,
  12,
  14,
  12,
  4,
  12,
  12,
  12,
  4,
  4,
  4,
  4,
  6,
  4,
  8,
  4,
  6,
  6,
  0,
  0,
  6,
  4,
  8,
  4,
  8,
  8,
  12,
  12,
  8,
  4,
  4,
  6,
  0,
  0,
  6,
  6,
  9,
  11,
  6,
  0,
  0,
  12,
  5,
  12,
  6,
  0,
  0,
  6,
  6,
  6,
  6,
  6,
  0,
  0,
  4,
  8,
  12,
  12,
  6,
  0,
  0,
  6,
  11,
  6,
  0,
  6,
  11,
  11,
  6,
  6,
  6,
  6,
  0,
  6,
  12,
  15,
  15,
  13,
  15,
  15,
  12,
  6,
  6,
  6,
  0,
  6,
  6,
  9,
  9,
  9,
  2,
  9,
  9,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  6,
  12,
  12,
  8,
  8,
  2,
  4,
  4,
  4,
  4,
  2,
  4,
  2,
  4,
  2,
  9,
  2,
  9,
  6,
  9,
  6,
  8,
  8,
  10,
  8,
  8,
  8,
  8,
  4,
  4,
  4,
  2,
  2,
  9,
  9,
  6,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  12,
  12,
  4,
  12,
  12,
  12,
  4,
  4,
  6,
  4,
  8,
  4,
  6,
  4,
  6,
  6,
  0,
  0,
  6,
  4,
  6,
  4,
  8,
  12,
  10,
  12,
  8,
  4,
  4,
  6,
  0,
  0,
  6,
  6,
  9,
  6,
  0,
  0,
  4,
  12,
  4,
  4,
  6,
  0,
  0,
  6,
  6,
  6,
  6,
  0,
  0,
  4,
  8,
  8,
  12,
  4,
  0,
  0,
  0,
  6,
  6,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  0,
  6,
  12,
  15,
  13,
  13,
  13,
  15,
  15,
  12,
  6,
  6,
  6,
  0,
  6,
  6,
  9,
  9,
  9,
  9,
  9,
  6,
  6,
  0,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  8,
  12,
  8,
  2,
  2,
  4,
  4,
  4,
  4,
  2,
  4,
  2,
  9,
  2,
  9,
  2,
  9,
  6,
  9,
  6,
  8,
  8,
  10,
  10,
  8,
  8,
  8,
  4,
  4,
  4,
  2,
  2,
  9,
  9,
  6,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  14,
  12,
  14,
  14,
  14,
  12,
  4,
  12,
  4,
  12,
  12,
  12,
  4,
  12,
  4,
  4,
  8,
  4,
  6,
  4,
  6,
  4,
  6,
  6,
  0,
  0,
  6,
  6,
  8,
  8,
  12,
  10,
  10,
  10,
  8,
  4,
  4,
  6,
  0,
  0,
  6,
  6,
  6,
  0,
  0,
  6,
  4,
  4,
  6,
  6,
  6,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  6,
  4,
  8,
  8,
  4,
  0,
  0,
  0,
  0,
  6,
  0,
  6,
  6,
  6,
  6,
  6,
  6,
  6,
  0,
  6,
  12,
  15,
  13,
  13,
  13,
  15,
  15,
  12,
  12,
  6,
  6,
  6,
  0,
  6,
  6,
  9,
  9,
  9,
  6,
  6,
  6,
  0,
  0,
  0,
  6,
  6,
  11,
  12,
  12,
  12,
  8,
  8,
  2,
  11,
  2,
  4,
  4,
  2,
  4,
  2,
  4,
  2,
  9,
  2,
  9,
  6,
  9,
  6,
  9,
  6,
  8,
  8,
  10,
  10,
  8,
  8,
  8,
  4,
  4,
  4,
  4,
  2,
  9,
  9
];
